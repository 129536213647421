import React, { Component } from "react";
import "./widget.scss";
import { withRouter } from 'react-router-dom';
import MediumPriority from '../images/medium.png'
// import { wc } from '../utils/webComponent';
import AzureMap from '../components/azureMaps';
import HighPriority from '../images/high.png'
import AzureMapControls from "./azureMapControls";
import { user } from '../services/Crypto';
import img_pumpstatus from '../images/pumpstatus.png';
import CryptoJS from 'crypto-js'
const _ = require('lodash');

class CustomerLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: true,
      list: "standard",
      map: "alternative",
      count:0,
      defaultRole: window.sessionStorage.getItem('customerType') === null ? "Agro" : window.sessionStorage.getItem('customerType'),
      selectedRole: (window.sessionStorage.getItem('customerType') === "Agro" || window.sessionStorage.getItem('customerType') === null) ? false : true
    }
  }
  setCustID = (deviceType,customerId, siteName,typeofuser,index) => {
    // window.document.getElementById(customerId).scrollIntoView({block:"center"});    
    setTimeout(() => {
      if(window.document.getElementById(customerId)){
        window.document.getElementById(customerId).scrollIntoView({block:"center"});
      }  
    }, 1000);
    let uiType = "0";
    //on site change, set community/individual
    if(siteName.includes("All Sites")){      
      siteName="All Sites"
      if(document.getElementById("configPage") !== null){
      document.getElementById("configPage").style.display = "none";
      }
    }else{
      if(document.getElementById("configPage") !== null){
      document.getElementById("configPage").style.display = "flex";
      }
      uiType = typeofuser;      
    }
    if(user.user_type == "Admin"){
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: uiType, gatewayType: deviceType}), process.env.REACT_APP_SECRET_KEY).toString();
      sessionStorage.setItem("user", encrypted);
    }   
    this.props.onClick(customerId, siteName)

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'Site_Click',
      'SiteName': siteName});
  }
  navigateToDetailsPage = () => {
    let { history } = this.props;
    history.push({
      pathname: '/LocalMap',
      widgetType: "MapView"
    });
  }
  componentDidMount(){
    let cid=sessionStorage.getItem("customerId")
    setTimeout(() => {
      if(window.document.getElementById(cid)){
        window.document.getElementById(cid).scrollIntoView({block:"center"});
      }  
    }, 1000);
  
  }
  load = (customerData) => {
    return customerData.map((d, index) => {
      if (d.location) {
        var addr = d.address;
        const iconColor= d.isGateWayActive===0 || d.customerId===0 ?'standard':'#24B43F';
        const title_gateway= d.isGateWayActive===1 && d.customerId!==0 ?'Site is active': d.customerId!==0 ?'Site is inactive':'';
        return (
          <se-list-item deviceType={d.deviceType} id={d.customerId}  key={index} selected={parseInt(d.customerId) === parseInt(sessionStorage.getItem("customerId")) ? true : false} item={d.location} value={d.customerId} onClick={() => { this.setCustID(d.deviceType, d.customerId, d.location, d.typeofUser,index) }} description={addr} >
          {d.customerId !== 0 && (<div style={{ color: d.ReqMotorStatus > 0 ? "#3DCD58" : (d.ReqMotorStatus === -1 ? "#505257" : "red") }}
            title={d.ReqMotorStatus > 0 ? "Pump On" : d.ReqMotorStatus === -1 ? "Status not available" : "Pump Off"}>●</div>)}
          <se-icon class=""
        onClick={() => this.navigateToDetailsPage()}
        size="small" title={title_gateway} style={{color:iconColor}}>map_location</se-icon>
        </se-list-item>
        )
      }
    })
  }
  onSelectList = () => {
    this.setState({
      list: "standard",
      map: "alternative",
      select: true,
    });
  }
  onRoleChange = (e) => {
    let customerType = null;
    if (e.target.selected === true) {
      this.setState({
        defaultRole: "SWP",
        selectedRole: true
      });
      customerType = "SWP"
    } else {
      this.setState({
        defaultRole: "Agro",
        selectedRole: false
      });
      customerType = "Agro"
    }
    this.onSelectList()
    this.props.onChange(customerType)

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'Agro/SWP_ToggleButton',
    'customerType':customerType});
  }
  onSelectMap = (e) => {
    this.setState({
      list: "alternative",
      map: "standard",
      select: false,
    });
  }

  
  render() {
    sessionStorage.setItem("customerType", this.state.defaultRole)
    const { customerList, totalCustomers } = this.props;
    var mapKey = "EjxE0q0P0oaDwLytJ7BcKHyEwyKW6xCv7pHEPQ5SYvBF8aEy9PrnJQQJ99AIAC5RqLJs0DShAAAgAZMPCbuT";
    var customerData = [];
    var TotalSite = 0;
    var LocationObject = [];
    var TotalCountObject = [];
    if (customerList) {
      var customerDetails = [{ "Customers": "", "address": "", "city": "", "customerId": 0, "installedDate": "", "latitude": "", "location": "All Sites", "longitude": "", "state": "" }]
      for (let i = 0; i < customerList.length; i++) {
        customerDetails.push(customerList[i])
      }
      customerData = customerDetails;
      customerDetails.map(ele => {
        if (ele.longitude && ele.latitude) {
          // if(parseInt(sessionStorage.getItem("customerId")) === 0){
          // LocationObject.push([Number(ele.longitude),Number(ele.latitude),ele.customerId])
          // }else if(parseInt(sessionStorage.getItem("customerId")) === parseInt(ele.customerId)){
          LocationObject.push({ "type": "Feature", "geometry": { "type": "Point", "coordinates": [Number(ele.longitude), Number(ele.latitude)] }, "properties": { "id": ele.customerId, "location": ele.location, "EntityType": ele.pump_status } })
          // }
        }
      })
      customerDetails.map(ele => {
        if (ele.longitude && ele.latitude) {
          TotalCountObject.push(ele)
        }
      })
      TotalSite = TotalCountObject.length;
      
    } else {
      customerDetails = []
    }
    
    return (
      // <se-container style={{ height: "50vh" }}>
        <se-block option="fill" class = "locationlist-height-mobile" style={{ border: "1px solid rgb(226 226 226)", overflow:"hidden" }}>
          <div className="rows" style={{ height: "50px" }}>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 pr-0 my-auto">
              <span className="font-normal font-weight-600 NotificationHeaderColor">Customer Locations
                {/* {(sessionStorage.getItem('UserType') !== "Agro" && sessionStorage.getItem('UserType') !== "SWP") ? <se-checkbox selected={this.state.selectedRole} onClick={(e) => { this.onRoleChange(e) }} option="switch"></se-checkbox>
                  : null} */}
              </span>
            </div>
            {/* <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pt-2 pl-0 color-standard text-right"> */}

              {/* <se-button size="small" icon="list" color={this.state.list} class="" onClick={this.onSelectList.bind(this)} icon-only></se-button> */}
              {/* <se-button size="small" icon="map_location" color={this.state.map} onClick={this.onSelectMap.bind(this)} icon-only></se-button> */}
              {/* <se-button size="small" icon="map_location" color={this.state.map} onClick={() => this.navigateToDetailsPage()} icon-only></se-button> */}
            {/* </div> */}
          </div>
          {this.state.select ?
            <se-list >
              <se-list-item class="text"  key='-1' item={"No. of Sites: "+TotalSite}/>
              {this.load(customerData)}

            </se-list> : <se-block-content option="fill" padding="none" margin="none" display="grid" style={{ height: "140px", overflow: "hidden", padding: "0px !important" }}>

              <AzureMap id="" mapKey={mapKey} LocationObject={LocationObject} latitude={13.0392} longitude={77.7586} style={{ height: "120px" }} onClick={this.setCustID.bind(this)} />

            </se-block-content>}
          {/* <se-block-footer margin="none" padding="none" class="grey-backgroundOverview" >
            <div slot="start" class="rows profile_fontsize" >
              <div class="col-10 font-normal font-weight-600 pr-0">No. of Sites: {TotalSite}</div>
              <div class="font-normal font-weight-600"></div>
            </div>
          </se-block-footer> */}
        </se-block>
      // </se-container>

    );
  }
}

export default withRouter(CustomerLocations);