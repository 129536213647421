import { createSlice } from "@reduxjs/toolkit";

const initialState = {userid: 0,user_type: ""}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: { 
        addUser: (state,action) => {
            state.userid = action.payload.userid;
            state.user_type = action.payload.user_type;
        }
    },
});

export default userSlice.reducer
export const { addUser } = userSlice.actions