import React from "react";
import { withRouter } from 'react-router-dom';
import "./widget.scss";
import _ from 'lodash';
import { Bar } from 'react-chartjs-2'
import ToolTipInfo from "./tooltipComponent";
const Dashboardwidgetbarchart = (props) => {
  const [utilityDetails, setUtilityDetails] = React.useState({})
  let styleProp = { position: "absolute", top: "0px" }
  React.useEffect(() => {
    if (props.capacityUtilization) {
      setUtilityDetails(props.capacityUtilization)
    }
  }, [props.capacityUtilization])
  const renderValue = _.map(utilityDetails.widgetData, (widgetData, index) => {
    return <div key={index} class="col-xs-5 col-sm-5 col-md-5 col-lg-5 pt-0" style={{ marginTop: '-10px' }}>
      <div class="row align-items-center">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-3">
          <se-icon size="large" color="standard" style={{ fontSize: "42px" }}>{widgetData.propIcon}</se-icon>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 LineheightOver" style={{ paddingLeft: "calc(1rem + 0.8vw)" }}>
          <span class="text-align font-size-12 textColor">{widgetData.propName}</span>
        </div>
      </div>
      <div class="row align-items-center pt-4 pl-0">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <span style={{ position: "relative" }} class="font-size-lg pt-2">{widgetData.propVal} <span class="font-size-12 font-weight-600"
          >
            {parseFloat(widgetData.propVal) > 100 ?
              <ToolTipInfo styleProp={styleProp} />
              :
              widgetData.units}
          </span></span>
        </div>
      </div>
    </div>
  })
  // const renderProgressBar = _.map(utilityDetails.widgetDetails, (widgetDetail) => {
  //   return _.map(widgetDetail.graphData, (graphData,index) => {
  //     return <div key={index} class="row align-items-center progress-group-header">
  //       <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 px-0 pl-2">
  //         <div class="font-size-10">{graphData.propName}</div>
  //         <div class="progress progressBar">
  //           <div class="progress-bar"
  //             role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ 'width': parseFloat(graphData.propValue)+'%', backgroundColor: graphData.propColor }} />
  //         </div>
  //       </div>
  //       <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 px-0 pl-2 barPadding">
  //         {graphData.propValue} %
  //       </div>
  //     </div>
  //   })

  // })
  const style = {

    // Adding media querry..
    '@media only screen and (min-width: 1201px) and (max-width: 1280px) ': {
      paddingLeft: '0 !important',
    },
  };
  const renderSWPValue = () => {
    if (utilityDetails.widgetData !== undefined) {
      return <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 pt-0 widgettext-left" style={{ marginTop: '-10px' }}>
        <div class="row align-items-center">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-3 style">
            <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{utilityDetails.widgetData[0].propIcon}</se-icon>
          </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 LineheightOver" style={{ paddingLeft: "calc(1rem + 0.8vw)" }}>
            <span class="text-align font-size-12 textColor">{utilityDetails.widgetData[0].propName}</span>
          </div>
          
        </div>
       
        {
          parseInt(sessionStorage.getItem("customerId")) > 0 ?
            <div>
              <div class="row capacitywidget-padding-mobile" style={{paddingTop:"20%"}}><div class="col-md-2"></div><div class="text-align font-size-12 textColor">* Last 3 months</div></div>
              {/* <div class="row align-items-center pl-0 pt-2">

                <div class="col-xs-6 col-sm-6 col-md-5 col-lg-4 pl-2 pr-0">
                  <span class="font-size-18 pt-2">{utilityDetails.widgetData[1].propName}</span>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 pl-0 pr-0">
                  <span style={{ position: "relative" }} class="cu_high_low">{parseFloat(utilityDetails.widgetData[1].propVal) >= 100 ? 100 : utilityDetails.widgetData[1].propVal} <span class="font-size-16">{utilityDetails.widgetData[1].units}</span>
                  </span>
                </div>
              </div>
              <div class="row align-items-center pl-0 pt-2">
                <div class="col-xs-6 col-sm-6 col-md-5 col-lg-4 pl-2 pr-0">
                  <span class="font-size-18 pt-2">{utilityDetails.widgetData[2].propName}</span>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 pl-0 pr-0">
                  <span style={{ position: "relative" }} class="cu_high_low">{parseFloat(utilityDetails.widgetData[2].propVal) >= 100 ? 100 : utilityDetails.widgetData[2].propVal} <span class="font-size-16">{utilityDetails.widgetData[2].units}</span>
                    <se-icon size="large" color="standard" class="cloudIcon">{utilityDetails.widgetData[2].propIcon}</se-icon>
                  </span>

                </div>
              </div> */}
            </div>
            :<div class="row capacitywidget-padding-mobile" style={{paddingTop:"5%"}}><div class="col-md-2"></div><div class="text-align font-size-12 textColor">* Last 6 months</div></div> // : <div class="row col-md-12"><div class="col-md-7"></div><div class="text-align font-size-12 textColor">(%)</div></div>
        }

      </div>
    }

  }

  const renderGraph = () => {
    var dataValue = {

      labels: [],
      datasets: [
        {
          label: null,
          backgroundColor: [],
          borderColor: [],
          hoverBackgroundColor: [],
          hoverBorderColor: [],
          data: []
        }]
    }

    let options = {
      tooltips: {
        intersect: false,
        mode: 'nearest',
        callbacks: {
          label: function (tooltipItem, data) {
            return data['datasets'][0]['data'][tooltipItem['index']] + " ";
          }
        }
      },
      scales: {
        xAxes: [{
          barPercentage: 0.4,
          maxBarThickness: 12,
          gridLines: {
            display: false,
          },
          ticks: {
            fontSize: 8,
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
            labelString: null,
            padding: 0,
            fontSize: 10,
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            maxTicksLimit: 7,
            padding: 3,
          }
        }],
      },
      legend: {
        display: false,
      }
    }
    _.map(utilityDetails.widgetDetails, widgetDetail => {
      options.scales.yAxes[0].scaleLabel.labelString = ""
      _.map(widgetDetail.graphData, graphData => {
        dataValue.labels.push(graphData.propLable)
        dataValue.datasets[0].label = "no of sites"
        dataValue.datasets[0].backgroundColor.push(graphData.propColor)
        dataValue.datasets[0].borderColor.push(graphData.propColor)
        dataValue.datasets[0].hoverBackgroundColor.push(graphData.propColor)
        dataValue.datasets[0].hoverBorderColor.push(graphData.propColor)
        dataValue.datasets[0].data.push(graphData.propValue[0])
      })
    })
    return <Bar data={dataValue} options={options} width="175" height="105" />
  }
  const renderSwpGraph = () => {
    var dataValue = {

      labels: [],
      datasets: [
        {
          label: null,
          backgroundColor: [],
          borderColor: [],
          hoverBackgroundColor: [],
          hoverBorderColor: [],
          data: []
        }]
    }

    let options = {
      tooltips: {
        intersect: false,
        mode: 'nearest',
        callbacks: {
          title: function(tooltipItems, data) {
            return '';
          },
          label: function (tooltipItem, data) {
            return "No of Sites: "+data['datasets'][0]['data'][tooltipItem['index']];
          }
        }
      },
      scales: {
        xAxes: [{
          barPercentage: 0.4,
          maxBarThickness: 12,
          gridLines: {
            display: false,
          },
          ticks: {
            fontSize: 8,
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
            labelString: null,
            padding: 0,
            fontSize: 10,
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            maxTicksLimit: 7,
            padding: 3,
          }
        }],
      },
      legend: {
        display: false,
      }
    }
    _.map(utilityDetails.widgetDetails, widgetDetail => {
      options.scales.yAxes[0].scaleLabel.labelString = ""
      _.map(widgetDetail.graphData, graphData => {
        dataValue.labels.push(graphData.propLable)
        dataValue.datasets[0].label = "no of sites"
        dataValue.datasets[0].backgroundColor.push(graphData.propColor)
        dataValue.datasets[0].borderColor.push(graphData.propColor)
        dataValue.datasets[0].hoverBackgroundColor.push(graphData.propColor)
        dataValue.datasets[0].hoverBorderColor.push(graphData.propColor)
        dataValue.datasets[0].data.push(graphData.propValue[0])
      })
    })
    return <Bar data={dataValue} options={options} width="175" height="105" />
  }
  const renderSwpIndGraph = () => {
    var dataValue = {

      labels: [],
      datasets: [
        {
          label: null,
          backgroundColor: [],
          borderColor: [],
          hoverBackgroundColor: [],
          hoverBorderColor: [],
          data: []
        }]
    }

    let options = {
      tooltips: {
        intersect: false,
        mode: 'nearest',
        callbacks: {
          title: function(tooltipItems, data) {
            return '';
          }
        }
      },
      scales: {
        xAxes: [{
          barPercentage: 0.4,
          maxBarThickness: 12,
          gridLines: {
            display: false,
          },
          ticks: {
            fontSize: 8,
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
            labelString: null,
            padding: 0,
            fontSize: 10,
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            maxTicksLimit: 7,
            padding: 3,
          }
        }],
      },
      legend: {
        display: false,
      }
    }
    
    _.map(utilityDetails.widgetDetails, widgetDetail => {
      options.scales.yAxes[0].scaleLabel.labelString = ""
      dataValue.labels=utilityDetails.periods
      
      for(let i=0;i<widgetDetail.graphData.length;i++){
        let datasets={}
        datasets.label = widgetDetail.graphData[i].propName +"(%)"
        datasets.data = widgetDetail.graphData[i].propValue
        datasets.backgroundColor = widgetDetail.graphData[i].propColor
        datasets.borderColor = widgetDetail.graphData[i].propColor
        dataValue.datasets.push(datasets);
      }
        
      // _.map(widgetDetail.graphData, graphData => {
      //   dataValue.datasets[0].label = ""
      //   dataValue.datasets[0].backgroundColor.push(graphData.propColor)
      //   dataValue.datasets[0].borderColor.push(graphData.propColor)
      //   dataValue.datasets[0].hoverBackgroundColor.push(graphData.propColor)
      //   dataValue.datasets[0].hoverBorderColor.push(graphData.propColor)
      //   dataValue.datasets[0].data.push(graphData.propValue[0])
      // })
    })
    dataValue.datasets=dataValue.datasets.filter((item) => item.label !== null);
    
    return <Bar data={dataValue} options={options} width="175" height="105" />
  }
  const navigatetoDetailsPage = () => {
    let { history } = props;
    history.push({
      pathname: '/capacityUtilizationDetails',
      widgetType: "Capacity Utilization"
    });
  }

  return <se-block margin="none" class="font-icon overflow-hidden tabHeight">
    <div class="border-bottom-overview bordergreenColor">
      <div class="rows align-items-center">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8"><span class="font-size-16">{utilityDetails.widgetType}<se-icon class="pointer valign-super margin-left-expand-capacity hideinDesktop showinMobile-expand" option="" size="nano" color="" onClick={navigatetoDetailsPage}>presentation_mode</se-icon>
          </span></div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 textColor font-size-12 my-auto"></div>
        <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 my-auto">
          <div class="row">
            <div class="col-md-12 text-right">
              <se-icon class="pointer valign-super" option="" size="nano" color="" onClick={navigatetoDetailsPage}>presentation_mode</se-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <se-block-content class="capacityblockheight border-bottom-overview overflow-hidden divAlign">
      <div class="row align-items-center cublockheight">
        {sessionStorage.getItem("customerType") === "Agro" ?
          renderValue
          :
          renderSWPValue()
        }
        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 pl-0 border-left" style={{ paddingTop: '1%' }}>
          {/* {renderProgressBar} */}
          { sessionStorage.getItem("customerType") === "SWP" && parseInt(sessionStorage.getItem("customerId")) > 0 
          ?renderSwpIndGraph() : sessionStorage.getItem("customerType") === "SWP" ? renderSwpGraph() : renderGraph()}
        </div>
      </div>
    </se-block-content>
  </se-block>
}
export default withRouter(Dashboardwidgetbarchart);