
const _ = require('lodash');
const useMycustomerForm = (infoData, index, type) => {
    let errors = JSON.parse(JSON.stringify(infoData))
    _.map(errors, error => {
        if (error.PropName === "Land Owned (In acres)" && error.PropValue) {
            if (!/^([1-9]|[1-9][0-9]|[1-9][0-9][0-9])?(?:\.\d+|1000)?$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Numbers allowed(1-1000)"
            }
            else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "City" && error.PropValue) {
            // if (!/^[ a-zA-Z]+$/i.test(error.PropValue)) {
            //     error.PropClass = "error-border"
            //     error.PropMsg = "Only Alphabets allowed"
            // } else 
            if (error.PropValue.length > 40) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 40 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        //Gateway info
        if (error.PropName === "Gateway ID" && error.PropValue) {
            if (!/^[a-zA-Z0-9]+$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only alphanumeric characters are allowed."
            } else if (error.PropValue.length > 32) {
                error.PropClass = "error-border"
                error.PropMsg = "less than or equal to 32 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
            
        }

        //income details
        if (error.PropName === "Annual Income (INR)" && error.PropValue) {
            if (!/^([0-9][0-9]{0,9})$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Numbers(0-9999999999)"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        // if (error.PropName === "Search Address" && error.PropValue===null) {
        //     return;
        // }
        // if (error.PropName === "Search Address" && error.PropValue) {
        //     if (!/^[ a-zA-Z0-9]+$/i.test(error.PropValue)) {
        //         error.PropClass = "error-border"
        //         error.PropMsg = "Only Alphabets and Numbers are allowed"
        //     } else {
        //         error.PropClass = "valid-border"
        //         error.PropMsg = ""
        //     }
        // }
        if (error.PropName === "First Name" && error.PropValue) {
            if (!/^[ a-zA-Z]+$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Alphabets allowed"
            } else if (error.PropValue.length > 20) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 20 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Last Name" && error.PropValue) {
            if (!/^[ a-zA-Z]+$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Alphabets allowed"
            } else if (error.PropValue.length > 20) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 20 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Email" && error.PropValue) {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Email is not valid"
            } else if (error.PropValue.length > 50) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 50 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Contact Number" && error.PropValue) {
            if (!/^[0-9]*$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Phone Number is not valid"
            } else if (error.PropValue.length > 15) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 15 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Address" && error.PropValue) {
            // if (!/^[ a-zA-Z0-9\./#,-\s]+$/i.test(error.PropValue)) {
            //     error.PropClass = "error-border"
            //     error.PropMsg = "Only alphanumeric and ./#,-"
            // } else 
            if (error.PropValue.length > 150) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 150 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "State" && error.PropValue) {
            // if (!/^[ a-zA-Z]+$/i.test(error.PropValue)) {
            //     error.PropClass = "error-border"
            //     error.PropMsg = "Only Alphabets allowed"
            // } else 
            if (error.PropValue.length > 50) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 50 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Country" && error.PropValue) {
            // if (!/^[a-zA-Z ]+$/i.test(error.PropValue)) {
            //     error.PropClass = "error-border"
            //     error.PropMsg = "Only Alphabets allowed"
            // } else 
            if (error.PropValue.length > 20) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 20 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Pincode" && error.PropValue) {
            if (!/^[0-9a-zA-Z]*$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Pincode is not valid"
            } else if (error.PropValue.length > 10) {
                error.PropClass = "error-border"
                error.PropMsg = "Maximum 10 characters are allowed."
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "No. of Entrepreneurs (Men)" && error.PropValue) {
            if (!/^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Numbers allowed(0-999)"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "No. of Entrepreneurs (Women)" && error.PropValue) {
            if (!/^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Numbers allowed(0-999)"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Latitude" && error.PropValue) {
            if (!/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Latiude must be between -180 and 180 degrees inclusive"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Longitude" && error.PropValue) {
            if (!/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Longitude must be between -180 and 180 degrees inclusive"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "No. of Farmers Working" && error.PropValue) {
            if (!/^([0-9]|[1-9][0-9]|[1-9][0-9][0-9]||[1-9][0-9][0-9][0-8])$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Numbers allowed(0-1998)"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "No. of Farmers Men" && error.PropValue) {
            if (!/^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Numbers allowed(0-999)"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "No. of Farmers Women" && error.PropValue) {
            if (!/^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/i.test(error.PropValue)) {
                error.PropClass = "error-border"
                error.PropMsg = "Only Numbers allowed(0-999)"
            } else {
                error.PropClass = "valid-border"
                error.PropMsg = ""
            }
        }
        if (error.PropName === "Photo" && error.PropValue) {
            if (error.PropValue === "invalidImage") {
                error.PropMsg = "Only images with the format .png, .jpg, .jpeg are allowed."
            } else if (error.PropValue === "invalidSize") {
                error.PropMsg = "Failed to upload an image. Maximum size allowed 2MB only."
            } else {
                error.PropMsg = ""
            }
        }
        //irradiance
        if (index === 3) {
            _.map(error.data, data => {
                if (data.PropValue) {
                    if (!/^(?:\d*\.\d{1,2}|\d+)$/i.test(data.PropValue)) {
                        data.PropClass = "error-border"
                        data.PropMsg = "Only Numbers allowed"
                    }
                    else {
                        data.PropClass = "valid-border"
                        data.PropMsg = ""
                    }
                }
            })

        }
        //AddAssests
        if (index === 2 && type === "Agro") {
            _.map(error.PropData, PropData => {
                if (PropData.PropName === "Title" && PropData.PropValue) {
                    if (!/^[ a-zA-Z0-9]+$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only Alphanumeric allowed"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Asset Name" && PropData.PropValue) {
                    if (!/^[ a-zA-Z0-9]+$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only Alphanumeric allowed"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Motor HP" && PropData.PropValue) {
                    if (!/^([1-9]|[1-9]?\.\d{1,2}|1[0-4]?\.\d{1,2}|1[0-5]|0?\.[5-9][0-9]{1,2})$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(0.50 - 15)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Phase" && PropData.PropValue) {
                    if (PropData.PropValue !== '1' && PropData.PropValue !== '3') {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only 1 or 3 Phase is allowed"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Voltage" && PropData.PropValue) {
                    let motorVoltage = PropData.PropValue
                    let errorMsg = ""
                    _.map(error.PropData, ele => {
                        if (ele.PropValue == '1' && ele.PropName === "Phase") {
                            if (!/^(?:1[0-9]{2}|2[0-2][0-9]|230)$/i.test(motorVoltage)) {
                                errorMsg = "Only numbers(100-230V)"
                            } else {
                                errorMsg = ""
                            }
                        }
                        if (ele.PropValue == '3' && ele.PropName === "Phase") {
                            if (!/^(?:1[0-9]{2}|2[0-2][0-9]|230|(?:38[0-9]|39[0-9]|40[0-9]|41[0-5]))$/i.test(motorVoltage)) {
                                errorMsg = "Only numbers(100-230V) or (380-415V)"
                            } else {
                                errorMsg = ""
                            }
                        }
                    })
                    if (errorMsg !== "") {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = errorMsg
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = errorMsg
                    }
                }
                if (PropData.PropName === "Max Current" && PropData.PropValue) {
                    if (!/^([1-9]|[1-9](\.\d{1,2})|[1-9][0-9](\.\d{1,2})|[12]\d|3[0-5])$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-35A)"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Manufacturer" && PropData.PropValue) {
                    if (!/^[ a-zA-Z0-9]+$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only Alphanumeric allowed"
                    } else if (PropData.PropValue.length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }              
                if (PropData.PropName === "Drive HP" && PropData.PropValue) {
                    _.map(error.PropData, ele => {
                        if (ele.PropName === "Motor HP" && ele.PropValue) {
                            if (parseFloat(PropData.PropValue) < parseFloat(ele.PropValue)) {
                                PropData.PropClass = "error-border"
                                PropData.PropMsg = "Value must be greater than or equal to Motor HP"
                            }
                            if (parseFloat(PropData.PropValue) >= parseFloat(ele.PropValue)) {
                                PropData.PropClass = "valid-border"
                                PropData.PropMsg = ""
                            }
                        }
                        if (ele.PropName === "Motor HP" && !ele.PropValue) {
                            PropData.PropClass = "error-border"
                            PropData.PropMsg = "Please enter Motor HP Value First"
                        }
                    })
                    if ((!/\d+(\.\d{1,2})?$/i.test(PropData.PropValue))) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers are allowed"
                    }
                    if (parseFloat(PropData.PropValue) > 100) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Number should be less than 100"
                    }
                }
                if (PropData.PropName === "Capacity Produced/hr" && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3})?(?:\.\d+|10000)?$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-10000)"
                    } else if (PropData.PropValue.length > 10) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 10 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Tariff/hr" && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3})?(?:\.\d+|10000)?$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-10000)"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "PV" && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3})?(?:\.\d+|10000)?$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-10000)"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Load" && PropData.PropValue) {
                    PropData.PropClass = "valid-border"
                    PropData.PropMsg = ""
                }
                if (PropData.PropName === "Load" && PropData.PropValue === "") {
                    PropData.PropClass = "valid-border"
                    PropData.PropMsg = ""
                }
                if (PropData.PropName === "Enter Power Vs Water flow data" && PropData.PropData.pvq.length > 0) {
                    for (let i in PropData.PropData.pvq) {
                        if (PropData.PropData.pvq[i].Power && !/^(?:[1-9][0-9]{2}(\.\d*[0-9])?|[1-7][0-9]{3}(\.\d*[0-9])?|8000)$/i.test(PropData.PropData.pvq[i].Power)) {
                            PropData.PropClass = "error-border"
                            PropData.PropMsg = "Power in numbers(100 - 8000W)"
                        }
                        if (PropData.PropData.pvq[i].Discharge && !/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3}|10000)$/i.test(PropData.PropData.pvq[i].Discharge)) {
                            PropData.PropClass = "error-border"
                            PropData.PropMsg = "Discharge in numbers(1 - 10000L/min)"
                        }
                    }

                }
                if (PropData.PropName === "Motor Power" && PropData.PropValue) {
                    if (!/^([1-9]|[1-9]?\.\d{1,2}|1[0-4]?\.\d{1,2}|1[0-5]|0?\.[7-9][5-9]{1,2})$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(0.75 - 15)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Frequency" && PropData.PropValue) {
                    if (!/^(?:5[0-9]|60)$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(50 - 60Hz)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Motor Speed" && PropData.PropValue) {
                    if (!/(?:[1-2][0-9]{3}|3000)$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1000 - 3000)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if ((PropData.PropName === "Water Discharge/hr" || PropData.PropName ==="Water Discharge/hr ") && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3})?(?:\.\d+|10000)?$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-10000)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
            })
        }
        if (index === 2 && type === "SWP") {
            _.map(error.PropData, PropData => {
                if (PropData.PropName === "Title" && PropData.PropValue) {
                    if (!/^[ a-zA-Z0-9]+$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only Alphanumeric allowed"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }

                if (PropData.PropName === "Motor HP" && PropData.PropValue) {
                    // if (!/^(?:[1-9]|1[0-9])?(?:\.\d+|20)?$/i.test(PropData.PropValue)) {
                    //     PropData.PropClass = "error-border"
                    //     PropData.PropMsg = "Only numbers(1-20)"
                    // } else {
                    //     PropData.PropClass = "valid-border"
                    //     PropData.PropMsg = ""
                    // }
                    if (!/^([1-9]|[1-9]?\.\d{1,2}|1[0-4]?\.\d{1,2}|1[0-5]|0?\.[5-9][0-9]{1,2})$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(0.50 - 15)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Phase" && PropData.PropValue) {
                    if (PropData.PropValue !== '1' && PropData.PropValue !== '3') {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only 1 or 3 Phase is allowed"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Voltage" && PropData.PropValue) {
                    let motorVoltage = PropData.PropValue
                    let errorMsg = ""
                    _.map(error.PropData, ele => {
                        if (ele.PropValue == '1' && ele.PropName === "Phase") {
                            if (!/^(?:1[0-9]{2}|2[0-2][0-9]|230)$/i.test(motorVoltage)) {
                                errorMsg = "Only numbers(100-230V)"
                            } else {
                                errorMsg = ""
                            }
                        }
                        if (ele.PropValue == '3' && ele.PropName === "Phase") {
                            if (!/^(?:1[0-9]{2}|2[0-2][0-9]|230|(?:38[0-9]|39[0-9]|40[0-9]|41[0-5]))$/i.test(motorVoltage)) {
                                errorMsg = "Only numbers(100-230V) or (380-415V)"
                            } else {
                                errorMsg = ""
                            }
                        }
                    })
                    if (errorMsg !== "") {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = errorMsg
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = errorMsg
                    }
                }
                if (PropData.PropName === "Max Current" && PropData.PropValue) {
                    if (!/^([1-9]|[1-9](\.\d{1,2})|[1-9][0-9](\.\d{1,2})|[12]\d|3[0-5])$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-35A)"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Manufacturer" && PropData.PropValue) {
                    if (!/^[ a-zA-Z0-9]+$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only Alphanumeric allowed"
                    } else if (PropData.PropValue.length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Drive HP" && PropData.PropValue) {
                    _.map(error.PropData, ele => {
                        if (ele.PropName === "Motor HP" && ele.PropValue) {
                            if (parseFloat(PropData.PropValue) < parseFloat(ele.PropValue)) {
                                PropData.PropClass = "error-border"
                                PropData.PropMsg = "Value must be greater than or equal to Motor HP"
                            }
                            if (parseFloat(PropData.PropValue) >= parseFloat(ele.PropValue)) {
                                PropData.PropClass = "valid-border"
                                PropData.PropMsg = ""
                            }
                        }
                        if (ele.PropName === "Motor HP" && !ele.PropValue) {
                            PropData.PropClass = "error-border"
                            PropData.PropMsg = "Please enter Motor HP Value First"
                        }
                    })
                    if ((!/\d+(\.\d{1,2})?$/i.test(PropData.PropValue))) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers are allowed"
                    }
                    if (parseFloat(PropData.PropValue) > 100) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Number should be less than 100"
                    }
                }
                if (PropData.PropName === "Capacity Produced/hr" && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3})?(?:\.\d+|10000)?$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-10000)"
                    } else if (PropData.PropValue.length > 10) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 10 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Tariff/hr" && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3})?(?:\.\d+|10000)?$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-10000)"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "PV" && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9](\.\d*[0-9])|1[0-7](\.\d*[0-9])|1[0-8])$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-18)"
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Enter Power Vs Water flow data" && PropData.PropData.length > 0) {
                    for (let i in PropData.PropData) {
                        if (PropData.PropData[i].Power && !/^(?:[1-9][0-9]{2}(\.\d*[0-9])?|[1-7][0-9]{3}(\.\d*[0-9])?|8000)$/i.test(PropData.PropData[i].Power)) {
                            PropData.PropClass = "error-border"
                            PropData.PropMsg = "Power in numbers(100 - 8000W)"
                        }

                    }

                }
                if (PropData.PropName === "Motor Power" && PropData.PropValue) {
                    if (!/^([1-9]|[1-9]?\.\d{1,2}|1[0-4]?\.\d{1,2}|1[0-5]|0?\.[7-9][5-9]{1,2})$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(0.75 - 15)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Frequency" && PropData.PropValue) {
                    if (!/^(?:5[0-9]|60)$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(50 - 60Hz)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Motor Speed" && PropData.PropValue) {
                    if (!/(?:[1-2][0-9]{3}|3000)$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1000 - 3000)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }
                if (PropData.PropName === "Water discharge/hr" && PropData.PropValue) {
                    if (!/^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]{3})?(?:\.\d+|10000)?$/i.test(PropData.PropValue)) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Only numbers(1-10000)"
                    } else if ((PropData.PropValue).length > 20) {
                        PropData.PropClass = "error-border"
                        PropData.PropMsg = "Maximum 20 characters are allowed."
                    } else {
                        PropData.PropClass = "valid-border"
                        PropData.PropMsg = ""
                    }
                }

            })
        }
    })
    return { errors }
}

export default useMycustomerForm