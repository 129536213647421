import Axios from "axios";
import API_CONFIG from "./API_CONFIG";
import _ from "lodash";
import _get from "lodash/get";
import _isString from "lodash/isString";
import _property from "lodash/property";
import _noop from "lodash/noop";
import _startCase from "lodash/startCase";
import _camelCase from "lodash/camelCase";
import _compact from "lodash/compact";
import _isEmpty from "lodash/isEmpty";
import _each from "lodash/each";
import _reduce from "lodash/reduce";
import _replace from "lodash/replace";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "../services/authConst";

class ApiSession {
  UserManager;
  constructor(accessToken) {
    Axios.defaults.baseURL = API_CONFIG.baseUrl;
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC
      }
    });
    //Axios.defaults.headers.common['token']  = localStorage.getItem('token');
  }

  /* to post settings Widget */
  postSettingsWidget = (data) => {
    return this.apiPost(API_CONFIG.endpoints.settingsWidget, data);
  };

  /* to post calculate Widget */
  postCalculateWidget = (data) => {
    return this.apiPost(API_CONFIG.endpoints.calculatedwidget, data);
  };

  /* to post notification Widget in Overview */
  postNotificationWidget = (data) => {
    return this.apiPost(API_CONFIG.endpoints.notificationwidget, data);
  };

  /* to get notification Data  */
  getNotificationData = () => {
    return this.apiGet(API_CONFIG.endpoints.notificationData);
  };

  /* to get site list */
  getAgroSiteList = (customerType) => {
    return this.apiGet(
      API_CONFIG.endpoints.agroSiteList + "/" + customerType
    );
  };

  /* to get CustomersList */
  getAgroCustomersList = (customerType) => {
    return this.apiGet(
      API_CONFIG.endpoints.agroCustomersList + "/" + customerType
    );
  };

  /* to get CustomerPersonalInfo */
  getCustomerPersonalInfo = (customerId) => {
    return this.apiGet(
      API_CONFIG.endpoints.customerPersonalInfo + "/" + customerId
    );
  };

  /* to get CustomerLandInfo */
  getCustomerLandInfo = (customerId) => {
    return this.apiGet(API_CONFIG.endpoints.customerLandInfo + "/" + customerId);
  };

  /* to get CustomerGatewayInfo */
  getCustomerGatewayInfo = (customerId) => {
    return this.apiGet(API_CONFIG.endpoints.customerGatewayInfo + "/" + customerId);
  };

  /* to get CustomersList */
  getCustomerPVDetails = (customerId) => {
    return this.apiGet(API_CONFIG.endpoints.customerPVDetails + "/" + customerId);
  };

  /* to get CustomerIncome */
  getCustomerIncome = (customerId) => {
    return this.apiGet(API_CONFIG.endpoints.customerIncome + "/" + customerId);
  };

  /* to get IrRadiance */
  getCustomerIrRadiance = (customerId) => {
    return this.apiGet(API_CONFIG.endpoints.customerIrRadiance + "/" + customerId);
  };

  /* to get CustomerDeviceDetails */
  getCustomerDeviceDetails = (customerId) => {
    return this.apiGet(
      API_CONFIG.endpoints.customerDeviceDetails + "/" + customerId
    );
  };

  /* to get login User Profile information */
  getUserProfileInfo = () => {
    // return null
    return this.apiGet(API_CONFIG.endpoints.userProfileInfo);
  };
  getUsersList = () => {
    // return null
    return this.apiGet(API_CONFIG.endpoints.updateUserProfileInfo);
  };
  getRolesList = () => {
    // return null
    return this.apiGet(API_CONFIG.endpoints.roles);
  };
  updateUserRole = (data) => {
    return this.apiPost(API_CONFIG.endpoints.roles, data);
  };
  loginUser = (data) => {
    return this.apiPost(API_CONFIG.endpoints.login, data);
  };

  /* to post data */
  postCustomerprofile = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.customerprofile,
      data
    );
  };
  postAgroDriveDetails = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.agroDriveDetails,
      data
    );
  };
  postAgroLandDetails = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.agroLandDetails,
      data
    );
  };
  postAgroDeviceDetails = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.agroDeviceDetails,
      data
    );
  };
  postAgroIRRadianceDetails = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.agroIRRadianceDetails,
      data
    );
  };
  postAgroIncomeDetails = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.agroIncomeDetails,
      data
    );
  };

  /* to post data */
  postUserProfileData = (data) => {
    return this.apiPost(API_CONFIG.endpoints.updateUserProfileInfo, data);
  };
  /* Details page api call */
  postAgroDashboardDetails = (data) => {
    return this.apiPost(API_CONFIG.endpoints.agroDashboardDetails, data);
  };

  /* Details Notification api call */
  postNotifications = (data) => {
    return this.apiPost(API_CONFIG.endpoints.notifications, data);
  };
  /* Details Notification acknowledge api call */
  postNotificationAcknowledge = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.eventAcknowledge + "/" + data.notificationId + "/" + data.customerId,
      data
    );
  };
  /* Admin Consolte page api call */
  getAdminConsole = (id) => {
    return this.apiGet(API_CONFIG.endpoints.adminConsole + "/" + id);
  };
  postGeneratePdfReport = (data) => {
    return this.apiPost(API_CONFIG.endpoints.donwloadPdfReport, data);
  };

  postUpdateFirmwareSetting = (data) => {
    return this.apiPost(
      API_CONFIG.endpoints.updateFirmwareSettingsForDevice,
      data
    );
  };
  putUpdateFirmwareSetting = (data) => {
    return this.apiPut(
      API_CONFIG.endpoints.updateFirmwareSettingsForDevice + "/" + 1,
      data
    );
  };

  getReportsData = (customerType, customerId) => {
    return this.apiGet(
      API_CONFIG.endpoints.getReportsTableData + "/" + customerType + "/" + customerId
    );
  };
  getCustomerDeviceList = (data) => {
    return this.apiPost(API_CONFIG.endpoints.customerDeviceList, data);
  };
  getFirmwareVersion = (customerType) => {
    return this.apiGet(
      API_CONFIG.endpoints.firmwareVersion + "/" + customerType
    );
  };

  deleteCustomer = (customerId) => {
    return this.apiDelete(API_CONFIG.endpoints.deleteCustomer + "/" + customerId);
  };

  postUserFormDetails = (data) => {
    return this.apiPost(API_CONFIG.endpoints.createUser, data);
  };
  //post generate Raw Data
  postGenerateRawData = (data) => {
    return this.apiPost(API_CONFIG.endpoints.generateRawData, data);
  };
  /* methods for http call */
  apiGet = (url) => {
    return this.apiAny({ method: "get", url });
  };

  apiPost = (url, data) => {
    return this.apiAny({ method: "post", url, data });
  };

  apiPut = (url, data) => {
    return this.apiAny({ method: "put", url, data });
  };

  apiDelete = (url) => {
    return this.apiAny({ method: "delete", url });
  };

  apiAny = ({ method, url, params, data }) => {
    let result = Promise.resolve(url);
    let access_token = sessionStorage.getItem("access_token");
    result.cancel = _.noop;
    if (_.isString(url)) {
      const cancelSource = Axios.CancelToken.source();
      const cancelToken = cancelSource.token;
      Axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      // Axios.defaults.headers.common['Authentication'] = sessionStorage.getItem("id_token")
      result = Axios.request({ method, url, params, data, cancelToken }).then(
        _.property("data")
        
      ).catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          window.location.href = process.env.REACT_APP_APIROOT + "/VFP_IDMS_IDPSloInit?post_logout_redirect_uri=" + process.env.REACT_APP_LOGOFF_REDIRECT_URL;
          sessionStorage.clear();
          console.log('after logout click')
          this.UserManager.clearStaleState();
        }
      }).finally((res) => {
      });
      result.cancel = cancelSource.cancel;
    }
    return result;
  };
  getMapLocations = (subKey, query) => {
    let url =
      "https://atlas.microsoft.com/search/fuzzy/json?&subscription-key=" +
      subKey +
      "&typeahead=true&api-version=1&language=en-US&view=Auto&query=" +
      query;
    return this.apiGet(url);
  };
  getMapLocationsByLonLat = (subKey, query) => {
    let url =
      "https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=" +
      subKey +
      "&query=" +
      query[1] +
      "," +
      query[0];
    return this.apiGet(url);
  };
  PostGalleryPhoto = (data) => {
    return this.apiPost(API_CONFIG.endpoints.uploadGalleryPhoto, data);
  };
  getGalleryPhoto = (data) => {
    return this.apiGet(
      API_CONFIG.endpoints.uploadGalleryPhoto + "/" + 0 + "/" + data.customerId + "/" + data.customerType + "/" + data.pageNumber + "/" + data.pageCount
    );
  };
  deletePhoto = (customerId, customerType, data) => {
    let ids = data.toString();
    return this.apiDelete(API_CONFIG.endpoints.uploadGalleryPhoto + "/" + customerId + "/" + customerType + "/" + ids);
  };

  //CustomerDeviceConfigurations
  putUpdateMotorControlType = (UpdateMotorControlType, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations +
      "/" +
      UpdateMotorControlType,
      data
    );
  };

  putUpdateReferenceChannel = (UpdateReferenceChannel, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations +
      "/" +
      UpdateReferenceChannel,
      data
    );
  };

  putUpdateProfile = (UpdateProfile, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/" + UpdateProfile,
      data
    );
  };

  putUpdateFrequency = (UpdateFrequency, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/" + UpdateFrequency,
      data
    );
  };

  putUpdateMobileNumber = (MobileNumber, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/" + MobileNumber,
      data
    );
  };

  putUpdatePassword = (Password, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/" + Password,
      data
    );
  };

  putUpdateStartStop = (StartStop, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/" + StartStop,
      data
    );
  };

  putUpdateConfigurationsReset = (Reset, data) => {
    return this.apiPut(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/" + Reset,
      data
    );
  };
  getConfigurationData = (GetData, data) => {
    const queryString = Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");
    return this.apiGet(
      API_CONFIG.endpoints.CustomerDeviceConfigurations +
      "/" +
      GetData +
      "?" +
      queryString
    );
  };

  getLatestPacket = (GetLatestPacket, data) => {
    const queryString = Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");
    return this.apiGet(
      API_CONFIG.endpoints.CustomerDeviceConfigurations +
      "/" +
      GetLatestPacket +
      "?" +
      queryString
    );
  };

  getTransactionData = (GetTransaction, customerId, status) => {
    const queryString = "customerId=" + customerId + "&status=" + status;
    return this.apiGet(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/" + GetTransaction + "?" + queryString
    );
  };

  postTransaction = (data) => {
    return this.apiPost(
      API_CONFIG.endpoints.CustomerDeviceConfigurations + "/SetTransaction",
      data
    );
  };
   /* to get Diagnostics page data */
   getDiagnosticsList = (customerType) => {
    return this.apiGet(
      API_CONFIG.endpoints.getDiagnosticsData + "/" + customerType
    );
  };
  /* to post the Create Tickets page data */
  postTicketsList = (data) => {
    return this.apiPost(
      API_CONFIG.endpoints.postTicketsData,data
    );
  };

  /* to get the Tickets list data for Open Tickets and My Tickets pages*/
  getTicketsList = (userType, customerId) => {
    return this.apiGet(
      API_CONFIG.endpoints.getTicketsData +"/" + userType + "/" + customerId
    );
  };

   /* to get the Tickets list data for Resolved Ticket page */
   getResolvedTicketsList = (customerId) => {
    return this.apiGet(
      API_CONFIG.endpoints.getResolvedTicketsData + "/" + customerId
    );
  };

  /* to update the Resolved Flag for Resolved Ticket page */
  postResolvedTickets = (data) => {
    return this.apiPost(
      API_CONFIG.endpoints.updateResolvedTicketsData,data
    );
  };
}
export default ApiSession;
