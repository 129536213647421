import React from "react";
import PropTypes from "prop-types";

const CheckBox = ({ label, handleReset, name, selected }) => {
  return (
    <>
      <se-block-header width="300px" color="none">
        <se-checkbox
          option="checkbox"
          label={label}
          label-pos="right"
          size="medium"
          onClick={handleReset}
          name={name}
          selected={selected}
        ></se-checkbox>
      </se-block-header>
    </>
  );
};
export default CheckBox;

CheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};
