export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  // silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
  // audience: process.env.REACT_APP_AUDIENCE,
  response_type: "code",
  // automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
  scope: 'openid profile email api refresh_token'
};

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_APIROOT,
  jwks_uri: process.env.REACT_APP_APIROOT + "/services/apexrest/oidcpublickeys",
  authorization_endpoint: process.env.REACT_APP_APIROOT + "/idplogin",
  token_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/token",
  userinfo_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/userinfo",
  end_session_endpoint: process.env.REACT_APP_APIROOT + "/VFP_IDMS_IDPSloInit",
  // check_session_iframe: process.env.REACT_APP_APIROOT + "/connect/checksession",
  revocation_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/revoke",
  introspection_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/introspect"
};
