import React from 'react';
import useMycustomerForm from './useMycustomerForm';
const _ = require('lodash');
const IrradianceDetails = (props) => {
    const [irradianceArray, setIrradianceArray] = React.useState([])
    const [inputData, setInputData] = React.useState([])
    const [yearList, setYearList] = React.useState([])
    const [onDataLoad,setOnDataLoad]= React.useState(false)
    const [selectedYear, setSelectedYear] = React.useState('2019')
    const { errors } = useMycustomerForm(irradianceArray, props.index)
    const isMobile = window.innerWidth <= 600;
    const getYearList = () => {
        
        let count = 0;
        let date = new Date();
            date.setDate(date.getDate() + 30);
        let year = date.getFullYear()-1;        
        let yearArray = []
        let startYear = parseInt("2019");
        do {            
            yearArray.push(startYear.toString())
            startYear++;
        }
        while (startYear <= year)
        setYearList(yearArray)
        if (props.irRadianceData.length > 0) {
            let irradianceData = []
            
            _.map(yearArray, (ele, index) => {
                
                if (_.find(props.irRadianceData, ["Year", ele]) === undefined) {
                    irradianceData.push({
                        "Year": ele,
                        "data": [{ PropName: "January", PropValue: "", "units": "kWh/m²" }, { PropName: "February", PropValue: "", "units": "kWh/m²" },
                        { PropName: "March", PropValue: "", "units": "kWh/m²" }, { PropName: "April", PropValue: "", "units": "kWh/m²" },
                        { PropName: "May", PropValue: "", "units": "kWh/m²" }, { PropName: "June", PropValue: "", "units": "kWh/m²" },
                        { PropName: "July", PropValue: "", "units": "kWh/m²" }, { PropName: "August", PropValue: "", "units": "kWh/m²" },
                        { PropName: "September", PropValue: "", "units": "kWh/m²" }, { PropName: "October", PropValue: "", "units": "kWh/m²" },
                        { PropName: "November", PropValue: "", "units": "kWh/m²" }, { PropName: "December", PropValue: "", "units": "kWh/m²" }],
                    })
                } else {
                    irradianceData.push(props.irRadianceData[count])
                    count = count + 1;
                }
            })
            
            setIrradianceArray(_.sortBy(irradianceData, [function (o) { return o.Year; }]))
        } else {
            let irradianceData = []
            _.map(yearArray, (ele, index) => {
                irradianceData.push({
                    "Year": ele,
                    "data": [{ PropName: "January", PropValue: "", "units": "kWh/m²" }, { PropName: "February", PropValue: "", "units": "kWh/m²" },
                    { PropName: "March", PropValue: "", "units": "kWh/m²" }, { PropName: "April", PropValue: "", "units": "kWh/m²" },
                    { PropName: "May", PropValue: "", "units": "kWh/m²" }, { PropName: "June", PropValue: "", "units": "kWh/m²" },
                    { PropName: "July", PropValue: "", "units": "kWh/m²" }, { PropName: "August", PropValue: "", "units": "kWh/m²" },
                    { PropName: "September", PropValue: "", "units": "kWh/m²" }, { PropName: "October", PropValue: "", "units": "kWh/m²" },
                    { PropName: "November", PropValue: "", "units": "kWh/m²" }, { PropName: "December", PropValue: "", "units": "kWh/m²" }],

                })

            })
            setIrradianceArray(irradianceData)
            
        }
        setOnDataLoad(true)
    }
    const onValueChange = (e, index) => {
        inputData[index].PropValue = e.target.value
        let tempData = irradianceArray
        _.map(tempData, (data, innerIndex) => {
            if (data.Year === selectedYear) {
                tempData[innerIndex].data = inputData
            }
        })
        props.onBlur(tempData, props.index)
    }
    const optionClick = (Year) => {
        setSelectedYear(Year);        
        _.map(irradianceArray, data => {
            if (data.Year === Year) {
                setInputData(data.data)
            }
        })
    }
    const renderError = (dataIndex) => {
        return _.map(errors, error => {
            if (error.Year === selectedYear) {
                if (error.data[dataIndex].PropMsg) {
                    return <div className="se-error-text">
                        {/* <se-icon style={{ position: "relative", top: '4px' }} size="small" class="pointer">notification_error_plain</se-icon> */}
                        {error.data[dataIndex].PropMsg}</div>

                }
            }
        })
    }
    const renderClass = (dataIndex) => {
        let styleClass = null;
        _.map(errors, error => {
            if (error.Year === selectedYear) {
                if (error.data[dataIndex].PropMsg) {
                    styleClass = "se-form-control " + error.data[dataIndex].PropClass
                } else {
                    styleClass = "se-form-control"
                }
            }
        })
        return styleClass
    }
    
    props.onChange(errors)
    const renderOption = _.map(yearList, data => {
        return <option value={data}>{data}</option>
    })
    const renderMonth = (from, to) => {
        return <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 se-white-background mt-2" align="center">
            {_.map(inputData, (ele, dataIndex) => {
                if (dataIndex >= from && dataIndex <= to) {
                    return <div class="row pt-3" align={isMobile ?"left":"right"}>
                        <label className="col-sm-5 se-label">{ele.PropName} <span class="se-mandatory"></span></label>
                        <div className="col-md-4 px-0 text-left irradiance-mobile-margin">
                            <input style={{ display: "inline", width: "80px" }} type="text" disabled={props.isDisabled}
                                className={renderClass(dataIndex)} onChange={(e) => onValueChange(e, dataIndex)}
                                autoComplete="off" maxlength="4" value={ele.PropValue} />
                            <span className="font-size-12">&nbsp;({ele.units})</span>
                            {renderError(dataIndex)}
                        </div>
                    </div>
                }
            })}
        </div>
    }
    React.useEffect(() => {
        getYearList()
        
    }, [props.irRadianceData])
    React.useEffect(() => {
       if(onDataLoad){
        let n=new Date().getFullYear()-1;
        setSelectedYear(n.toString());
        optionClick(n.toString())
       }
    }, [onDataLoad])
    return <se-block display="grid">
        <div class="row justify-content-md-center se-white-background mt-4 mb-4 irradiance-mobile-margin tabpadRight" style={{marginLeft:'0px',marginRight:'0px'}}>
            <div class='margin-left-year'>
                <div class="row">
                    <label class="se-label text-right">Year <span class="se-mandatory"></span></label>
                    <select class="se-form-control" style={{marginLeft:"11px", width:"124px"}} value={selectedYear}
                        onChange={(e) => optionClick(e.target.value)}
                    >
                        {renderOption}
                    </select>
                </div>
            </div>
        </div>
        <div className="row irradiance-mobile-margin-bottom tabpadRight" style={{marginLeft:'0px',marginRight:'0px'}}>
            {renderMonth(0, 5)}
            {renderMonth(6, 11)}
        </div>
    </se-block>
}
export default IrradianceDetails