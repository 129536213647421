import "babel-polyfill";
//import 'react-app-polyfill/ie11';
import { applyPolyfills, defineCustomElements } from "@se/web-ui/loader";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";

import { store, persistor } from './store.js'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

applyPolyfills().then(() => {
  defineCustomElements(window);
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
, document.getElementById("root"));
