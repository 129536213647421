import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../images/logo.png";
// import './App.css';

const MobileMenus = () => {
  const [windowDimension, setWindowDimension] = useState(null);
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);
  //   const navigate = useNavigate();

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMoreOptions = () => {
    setMoreOptionsVisible(!moreOptionsVisible);
  };

  const closeSideBar = () => {
    setMoreOptionsVisible(false);
  };
  const isMobile = windowDimension <= 600;
  return (
    <>
      {isMobile ? (
        <>
          <nav className="navbar-wrapper fixed-bottom">
            <ul className="items space-around">
              <li className="item column" onClick={closeSideBar}>
                <NavLink to="/overview" className={" menu-font-color"}>
                  <se-icon size="medium" color="primary">
                    3d_view_360
                  </se-icon>
                  Overview
                </NavLink>
              </li>

              <li className="item column" onClick={closeSideBar}>
                <NavLink to="/notifications" className={" menu-font-color"}>
                  <se-icon size="medium" color="primary">
                    action_notification_default
                  </se-icon>
                  Notifications
                </NavLink>
              </li>

              <li className="item column" onClick={closeSideBar}>
                <NavLink to="/reports" className={" menu-font-color"}>
                  <se-icon size="medium" color="primary">
                    action_pdf_report
                  </se-icon>
                  Reports
                </NavLink>
              </li>
              <li className="item column" onClick={toggleMoreOptions}>
                <se-icon size="medium" color="primary">
                  more
                </se-icon>
                More
              </li>
            </ul>
          </nav>
          <div
            className={`backdrop ${moreOptionsVisible ? "visible" : ""}`}
            onClick={toggleMoreOptions}
          ></div>
          <div
            className={`side-panel ${moreOptionsVisible ? "show-panel" : ""}`}
          >
            <ul>
              <li className="column" onClick={toggleMoreOptions}>
                <NavLink to="/mycustomers" className={" menu-font-color"}>
                  <se-icon size="medium" color="primary">
                    user_group
                  </se-icon>
                  <span className="margin-bottom-icons">Customers</span>
                </NavLink>
              </li>
              {sessionStorage.getItem("siteSelectedInMobile") &&
                JSON.parse(sessionStorage.getItem("siteSelectedInMobile"))
                  .value != 0 && (
                  <li
                    className="column"
                    id="configmobilelink"
                    onClick={toggleMoreOptions}
                  >
                    <NavLink to="/configuration" className={" menu-font-color"}>
                      <se-icon size="medium" color="primary">
                        action_settings2
                      </se-icon>
                      <span className="margin-bottom-icons">
                        Configurations
                      </span>
                    </NavLink>
                  </li>
                )}
              <li className="column" onClick={toggleMoreOptions}>
                <NavLink to="/gallery" className={" menu-font-color"}>
                  <se-icon size="medium" color="primary">
                    media_photo_gallery
                  </se-icon>
                  <span className="margin-bottom-icons">Gallery</span>
                </NavLink>
              </li>
              <li className="column" onClick={toggleMoreOptions}>
                <NavLink to="/tickets" className={" menu-font-color"}>
                  <se-icon size="medium" color="primary">
                  user_group
                  </se-icon>
                  <span className="margin-bottom-icons">Tickets</span>
                </NavLink>
              </li>
            
            <li>
            <div className="margintop-side-div">
            <se-link url="https://www.se.com/in/en/about-us/legal/terms-of-use.jsp">
              Terms of use
            </se-link>
            <se-link url="https://www.se.com/in/en/about-us/legal/data-privacy.jsp">
              Data Privacy Statement
            </se-link>
            
            
            {/* <Link to="www.se.com">
              www.se.com
            </Link> */}
            </div>
            </li>
            <li><img className="mobile-image-logo" src={logo} alt="Logo"></img></li>
            <li><a href="https://www.se.com/" className="link-padding" target="_blank">www.se.com</a></li>
            {/* <li><UserManagment /></li> */}
            </ul>
          </div>
          
        </>
      ) : null}
    </>
  );
};

export default MobileMenus;
