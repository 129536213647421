import React from 'react';
import useMycustomerForm from './useMycustomerForm';
import Auth from '../../api/ApiSession';
import * as atlas from 'azure-maps-control';
import img_active from '../../images/mechine_running.png'
import img_incative from '../../images/mechine_inactive.png'

const _ = require('lodash');
const authCall = new Auth();
const LandDetailsWithMap = (props) => {
    let mapKey = "EjxE0q0P0oaDwLytJ7BcKHyEwyKW6xCv7pHEPQ5SYvBF8aEy9PrnJQQJ99AIAC5RqLJs0DShAAAgAZMPCbuT"
    const [infoData, setInfoData] = React.useState(props.infoData)
    const [locationData, setlocationData] = React.useState({})
    const [locationDataList, setlocationDataList] = React.useState([])
    const { errors } = useMycustomerForm(infoData, props.index)
    const [noMen, setNoMen] = React.useState(0)
    const [noWomen, setNoWomen] = React.useState(0)
    const [_working, setWorking] = React.useState(0)
    const [customerType, setCustomerType] = React.useState(props.customerType);
    const [lat,setLat]=React.useState(props.infoData.find(obj => obj.PropName === "Latitude"));
    const isLandDetailsExists = props.infoData.some(item => item.PropName === "Land Owned (In acres)");
    const onValueChange = (e, index) => {
        let { name, value } = e.target
        if (name === "Search Address") {
            authCall.getMapLocations(mapKey, value).then(json => {

                if (json.results) {
                    setlocationDataList(json.results);
                }
                let a = locationDataList.find(item => item.address.freeformAddress === value);
                if (a !== undefined) {
                    let latlog = []
                    latlog.push(a.position.lon)
                    latlog.push(a.position.lat)
                    setLat(a.position.lat)
                    updateMapLatLong(latlog);
                }
            })
                .catch(error => {
                    console.log(error);
                });
        }
        else if (name === "No. of Farmers Men") {
            setNoMen(value)
        } else if (name === "No. of Farmers Women") {
            setNoWomen(value)
        }
        // console.log(value)

        infoData[index].PropValue = value
        props.onBlur(infoData, props.index)
    }
    props.onChange(errors)
    const renderError = (data, index) => {
        if (errors[index].PropName === data.PropName && errors[index].PropMsg) {
            return <div className="se-error-text"> {errors[index].PropMsg}</div>
        }
    }
    const renderClass = (data, index) => {
        if (errors[index].PropName === data.PropName && errors[index].PropMsg) {
            return "se-form-control " + errors[index].PropClass
        } else {
            return "se-form-control"
        }
    }
    const updateMapLatLong = (latLong) => {
        let updatedValue
        setInfoData(latest_value => {
            updatedValue = latest_value
            return latest_value
        })

        authCall.getMapLocationsByLonLat(mapKey, latLong).then(json => {
            _.map(updatedValue, ele => {
                if (ele.PropName === "Latitude") { ele.PropValue = latLong[1].toFixed(6) }
                if (ele.PropName === "Longitude") { ele.PropValue = latLong[0].toFixed(6) }
                if (json.addresses !== undefined) {
                    let addres = json.addresses[0].address.streetName === undefined ? '' : json.addresses[0].address.streetName + ", "
                    if (ele.PropName === "Address") { ele.PropValue = (json.addresses[0].address.freeformAddress === undefined) ? "" : json.addresses[0].address.freeformAddress }
                    if (ele.PropName === "City") { ele.PropValue = (json.addresses[0].address.countrySecondarySubdivision === undefined) ? "" : json.addresses[0].address.countrySecondarySubdivision }
                    if (ele.PropName === "State") { ele.PropValue = (json.addresses[0].address.countrySubdivision === undefined) ? "" : json.addresses[0].address.countrySubdivision }
                    if (ele.PropName === "Country") { ele.PropValue = (json.addresses[0].address.country === undefined) ? "" : json.addresses[0].address.country }
                    if (ele.PropName === "Pincode") { ele.PropValue = (json.addresses[0].address.postalCode === undefined) ? "" : json.addresses[0].address.postalCode }
                }
            })
            props.onBlur(updatedValue, props.index)
            setInfoData(updatedValue)
            setlocationData(json.addresses[0])
        })

    }

    //Define an HTML template for a custom popup content laypout.
    let popupTemplate = '<div class="customInfobox"><div class="name">{name}</div></div>';
    let popup = null;
    const initiateMap = (disabledClick, data) => {
        let map = null, selectedShape = null;
        
        const container = document.getElementById('myMap')
        if (!container) return;
        map = new atlas.Map('myMap', {
            language: 'en-US',
            authOptions: {
                authType: 'subscriptionKey',
                subscriptionKey: mapKey
            },
            renderWorldCopies: false,
            showLogo: false,
            color: 'DodgerBlue',
            text: '10',
            language: 'en-US',
            view: 'Auto',
            center: [80, 20],
            zoom: 3
        });

        //Wait until the map resources are ready.
        map.events.add('ready', function () {
            var iconPromises = [
                map.imageSprite.add('motor_active_icon', img_active),
                map.imageSprite.add('motor_inactive_icon', img_incative),
            ];
            Promise.all(iconPromises).then(function () {
                map.resize();
                let zoomControl = new atlas.control.ZoomControl();
                /* Add the zoom control to the map*/
                map.controls.add(zoomControl, {
                    position: "top-right"
                });

                let dataSource = new atlas.source.DataSource();
                map.sources.add(dataSource);
                if (data !== undefined) {
                    let latlon = data.position.split(",")
                    let pin = new atlas.data.Feature(new atlas.data.Point([latlon[1], latlon[0]]), {
                        title: 'Times Square'
                    });
                    dataSource.add(pin);
                    
                    let symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
                        // iconOptions: {
                        //     ignorePlacement: true, //To skip label collision detection for better performance.

                        //     allowOverlap: true    //To ensure smooth rendering when dragging, allow symbol to overlap all other symbols on the map.
                        // }
                        iconOptions: {
                            image: [
                                'match',
                                ['get', 'EntityType'],
                                1, 'motor_active_icon',
                                // 0, 'motor_inactive_icon',
                                'motor_inactive_icon'
                                // 'marker-blue'
                            ],
                            size: 0.4
                        },
                        textOptions: {
                            textField: ['get', 'location'],    //Use the title property to populate the text for the symbols.
                            size: 14,
                            // font: ['SegoeUi-Bold'],
                            color: 'gray',
                            offset: [0, 0.7]
                        },
                        filter: ['!', ['has', 'point_count']]
                    });
                    //Add the polygon and line the symbol layer to the map.
                    map.layers.add(symbolLayer);
                    //Create a popup but leave it closed so we can update it and display it later.

                    popup = new atlas.Popup({
                        pixelOffset: [0, -18],
                        closeButton: false
                    });

                    let ne = data.address.boundingBox.northEast.split(',')
                    let sw = data.address.boundingBox.southWest.split(',')
                    //Zoom the map into the selected location.
                    map.setCamera({
                        bounds: [sw[1], sw[0], ne[1], ne[0]],
                        padding: 70
                    });
                    map.events.add('mouseover', symbolLayer, function (e) {
                        if (e.shapes && e.shapes.length > 0) {
                            let content = null
                            let coordinate = null
                            let properties = {}
                            // let site_name=e.shapes[0].properties._azureMapsShapeId?e.shapes[0].properties._azureMapsShapeId :null;
                            properties.name = "Site Name :- " //+ JSON.parse(site_name).siteName
                            content = popupTemplate.replace(/{name}/g, properties.name)
                            coordinate = e.position
                            popup.setOptions({
                                //Update the content of the popup.
                                content: content,
                                //Update the popup's position with the symbol's coordinate.
                                position: coordinate

                            });
                            //Open the popup.
                            popup.open(map);
                        }
                    })
                    map.events.add('mouseleave', symbolLayer, function () {
                        popup.close();
                    });
                    map.events.add('mousedown', symbolLayer, function (e) {
                        if (disabledClick === false) {
                            if (e.shapes && e.shapes.length > 0) {
                                //Capture the selected shape.
                                selectedShape = e.shapes[0];

                                //Lock the maps ability to pan so that we can drag the symbol.
                                map.setUserInteraction({
                                    dragPanInteraction: false
                                });
                            }
                        }
                    });

                    map.events.add('mousemove', function (e) {
                        //Update the position of the selected shape.
                        if (selectedShape) {
                            selectedShape.setCoordinates(e.position);
                        }
                    });
                    map.events.add('mouseup', function (e) {
                        //Stop tracking the selected shape.
                        selectedShape = null;

                        //Make map panable again.
                        map.setUserInteraction({
                            dragPanInteraction: true
                        });
                        if (disabledClick === false) {
                            updateMapLatLong(e.position);
                        }
                    });
                }
            });
        });
        // map view end

    }
    React.useEffect(() => {
        setTimeout(() => {
            setInfoData((prevInfoData) => {
                let updatedInfoData = [...prevInfoData]; // Create a shallow copy of the array
    
                let searchAddressIndex = updatedInfoData.findIndex(obj => obj.PropName === "Search Address");
                
                if(props.customerType === '1'){
                    let propNamesToRemove = ["Land Owned (In acres)","No. of Farmers Men","No. of Farmers Women","No. of Farmers Working"]; 
                    const filteredInfoData = updatedInfoData.filter(item => !propNamesToRemove.includes(item.PropName));
                    updatedInfoData = filteredInfoData;
                }                
                    // Add the "Search Address" property if it doesn't exist and conditions are met
                if (!props.isDisabled && searchAddressIndex < 0 && props.index === 0) {
                    if(customerType === '1'){
                        updatedInfoData.splice(0, 0, { PropName: "Search Address", PropValue: " " });
                    }
                    else{

                        updatedInfoData.splice(1, 0, { PropName: "Search Address", PropValue: " " });
                    }
                }
                else if(props.isDisabled && searchAddressIndex === 0){
                    // Remove the "Search Address" property if it exists and conditions are met
                    updatedInfoData.splice(0, 1);
                }
                else if (props.isDisabled && searchAddressIndex > 0) {
                    // Remove the "Search Address" property if it exists and conditions are met
                        updatedInfoData.splice(1, 1);
                }
                return updatedInfoData;
            });
        }, 500);
    }, [props.infoData, props.isDisabled, props.index, props.customerType]);

    React.useEffect(() => {
        let lat = props.infoData.find(obj => obj.PropName === "Latitude")
        let lon = props.infoData.find(obj => obj.PropName === "Longitude")
        if (lat.PropValue !== '') {
                let latlog = []
                latlog.push(lon.PropValue)
                latlog.push(lat.PropValue)
                
                const container = document.getElementById('myMap')
                if (container) {
                    authCall.getMapLocationsByLonLat(mapKey, latlog).then(json => {
                        if (json.addresses[0].address !== undefined) {
                            // console.log(json)
                            setlocationData(json.addresses[0])
                            initiateMap(props.isDisabled, json.addresses[0])
                        }
                    })
                }

            }
            else {
                // console.log("else")
                initiateMap(props.isDisabled, undefined)
            }
    }, [lat])

    React.useEffect(() => {
        if(sessionStorage.getItem("dropdownCustomerType") === "Agro"){
            return;
        }
        if(isLandDetailsExists){  
        let working = props.infoData.find(obj => obj.PropName === "No. of Farmers Working")
        let women = props.infoData.find(obj => obj.PropName === "No. of Farmers Women")
        let men = props.infoData.find(obj => obj.PropName === "No. of Farmers Men")
        let nm = noMen === 0 ? men.PropValue : noMen;

        working.PropValue = Number(nm) + Number(women.PropValue)
        setWorking(working.PropValue);
        // console.log(men,women,working,Number(men.PropValue),Number(women.PropValue),working.PropValue)
        setInfoData(props.infoData)
           
    }
    }, [noMen, props.infoData])
    React.useEffect(() => {
        if(sessionStorage.getItem("dropdownCustomerType") === "Agro"){
            return;
        }
        if(isLandDetailsExists){ 
        let working = props.infoData.find(obj => obj.PropName === "No. of Farmers Working")
        let men = props.infoData.find(obj => obj.PropName === "No. of Farmers Men")
        let women = props.infoData.find(obj => obj.PropName === "No. of Farmers Women")
        let nm = noWomen === 0 ? women.PropValue : noWomen;
        working.PropValue = Number(men.PropValue) + Number(nm)
        setWorking(working.PropValue);
        // console.log(men,women,working,Number(men.PropValue),Number(women.PropValue),working.PropValue)
        //setTimeout(() => {
        setInfoData(props.infoData)
        }
       // },500)
    }, [noWomen, props.infoData])
    React.useEffect(() => {
        setInfoData(props.infoData)
    }, [_working])
    const isMobile = window.innerWidth <= 600;
    return <se-block display="grid">
        <div className="rows" align="">
            <div className="col-lg-6 col-md-8 px-0">
                {_.map(infoData, (eachData, index) => {
                    return sessionStorage.getItem("dropdownCustomerType") === "Agro"?
                    eachData.PropName === "No. of Farmers Women" || eachData.PropName === "No. of Farmers Men" ? null
                    :
                    <div class="createBlockSize text-right pb-3">
                        
                        <label className="col-sm-6 col-lg-6 col-xl-5 se-label height20 textbox-padding-land">{eachData.PropName==="No. of Farmers Working"?"No. of Beneficiaries":eachData.PropName}
                            {eachData.PropName === "Annual Income (INR)" ? null : <span class="se-mandatory">*</span>}
                        </label>
                        <div className="col-md-6 px-0 text-left">
                            {
                                 eachData.PropName === "Land Owned (In acres)" || eachData.PropName === "No. of Farmers Working" ?
                                    <input type="text"  style={{ width: isMobile ? '90%' : '100%'}} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                        disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                                    : eachData.PropName === "Search Address" ?
                                        <span><input type="text" list="locationDataList" style={{ width: isMobile ? '90%' : '100%'}} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                            disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                                            <datalist id="locationDataList">
                                                {locationDataList.map((item, key) =>
                                                    <option key={key} value={item.address.freeformAddress} />
                                                )}
                                            </datalist></span>
                                        :
                                        <input type="text"  style={{ width: isMobile ? '90%' : '100%'}} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                            disabled={true} value={eachData.PropValue} onKeyUp={(e) => onValueChange(e, index)} onChange={(e) => onValueChange(e, index)} />
                                // <input type="text" style={{ width: '100%' }} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                // disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                            }

                            {renderError(eachData, index)}
                        </div>
                    </div>
                    :
                    <div class="createBlockSize pb-3" style={{textAlign:isMobile ? 'left' : 'right'}}>
                        
                    <label className="col-sm-6 col-lg-6 col-xl-5 col-sm-3 se-label height20 textbox-padding-land">{eachData.PropName}
                        {eachData.PropName === "Annual Income (INR)" ? null : <span class="se-mandatory">*</span>}
                    </label>
                    <div className="col-md-6 col-sm-5 px-0 text-left">
                        {
                             eachData.PropName === "Land Owned (In acres)" ||eachData.PropName === "Address" || eachData.PropName === "No. of Farmers Women" || eachData.PropName === "No. of Farmers Men" ?
                                <input type="text"  style={{ width: isMobile ? '90%' : '100%'}} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                    disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                                : eachData.PropName === "Search Address" ?
                                    <span><input type="text" list="locationDataList" style={{ width: isMobile ? '90%' : '100%'}} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                        disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                                        <datalist id="locationDataList">
                                            {locationDataList.map((item, key) =>
                                                <option key={key} value={item.address.freeformAddress} />
                                            )}
                                        </datalist></span>
                                    :
                                    <input type="text"  style={{ width: isMobile ? '90%' : '100%'}} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                        disabled={true} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                            // <input type="text" style={{ width: '100%' }} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                            // disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                        }

                        {renderError(eachData, index)}
                    </div>
                </div>
                })}
            </div>
            {/* {_.isEmpty(locationData)? null : */}
            <div className="col-lg-6 col-md-4 hideinMobile">
                {/* <AzureMapControls isDisabled={props.isDisabled} locationData={locationData} mapKey={mapKey} onClick={updateMapLatLong} info={infoData} classStyle="azureMapControlsStyle2" /> */}
                <div id="myMap" className="azureMapControlsStyle2"></div>
            </div>
            {/* } */}
        </div>
    </se-block>
}
export default LandDetailsWithMap