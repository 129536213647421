import React, { useState,useEffect } from 'react';
import Auth from '../api/ApiSession';
import TableComponent from '../components/tableComponent';

const tickets = () => {
    const authCall = new Auth();
    const [openTicketsData, setTicketsData] = useState([]);
    const [resolvedTicketsData, setResolvedTicketsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenTicketActive, setOpenTicketActive] = useState(true);
    const [isResolveTicketActive, setResolveTicketActive] = useState(false);
    const [successMsg, setSucessMsg] = useState('');
    const [successType, setSuccessType] = useState('');
    const isMobile = window.innerWidth <= 600;

    const onCurrentStatus=()=>
    {
        setOpenTicketActive(true);
        setResolveTicketActive(false);
    }
    const onHistoryStatus=()=>
    {
        setOpenTicketActive(false);
        setResolveTicketActive(true);
        fetchResolvedTicketsData();
    }  

    const fetchOpenTicketsData = () => {       
        authCall.getTicketsList("Admin",sessionStorage.getItem("customerId")).then(json => {
            if (json) {
                const modifiedTicketsData = json.TicketDataResponse.TicketsList.map(ticket => {
                    const {Status,ClosedDate,...ticketWithoutStatusDate} = ticket;
                    return ticketWithoutStatusDate;
                });           
                setTicketsData(modifiedTicketsData)
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const fetchResolvedTicketsData = () => {       
        authCall.getResolvedTicketsList(sessionStorage.getItem("customerId")).then(json => {
            if (json) {
                const modifiedTicketsData = json.TicketDataResponse.TicketsList.map(ticket => {
                    const {Status,...ticketWithoutStatus} = ticket;
                    return ticketWithoutStatus;
                });
                setResolvedTicketsData(modifiedTicketsData)
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const iSResolvedTicketsData = (ticketid) => {   
        setIsLoading(true);   
        const ISTOffset = 330;
        const date = new Date(Date.now() + ISTOffset * 60000);
        const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
        const dataToPost = {
            TicketId: ticketid,
            ClosedDate:formattedDate
            //ClosedDate: currentDateTime.toLocaleString('en-US', {timeZone: 'Asia/Kolkata'})
            //ClosedDate : `${currentDateTime.getDate().toString().padStart(2, '0')}/${(currentDateTime.getMonth() + 1).toString().padStart(2, '0')}/${currentDateTime.getFullYear()} ${currentDateTime.getHours().toString().padStart(2, '0')}:${currentDateTime.getMinutes().toString().padStart(2, '0')}`
        };
        authCall.postResolvedTickets(dataToPost).then(json => {
            if (json) {
                setIsLoading(false);
                document.getElementById('snackbar_success').open = true;
                setSucessMsg(json);
                setSuccessType("success");
                fetchOpenTicketsData();
            }                    
        })
        .catch(error => {
            setIsLoading(false);
            console.log(error);
        });       
    }

    useEffect(() => {
        fetchOpenTicketsData();
    }, []);  
    return(
        <div>         
           {isMobile ? 
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintopmobile" align="center">
                <button className={isOpenTicketActive ? "ActiveButton pointer" : "InactiveButton pointer"} onClick={onCurrentStatus}>Open</button>
                <button className={isResolveTicketActive ? "histortActiveButton pointer" : "historyInactiveButton pointer"} onClick={onHistoryStatus}>Resolved</button>                
            </div>
            :
            <se-block color="standard" align="center" height="70px">
                <div className="rows grey-backgroundCreate margintopticket margintoptablet" style={{ textAlign: "center"}}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" align="center">
                        <div>
                            <button className={isOpenTicketActive ? "ActiveButton pointer" : "InactiveButton pointer"} onClick={onCurrentStatus}>Open</button>
                            <button className={isResolveTicketActive ? "histortActiveButton pointer" : "historyInactiveButton pointer"} onClick={onHistoryStatus}>Resolved</button>
                        </div>
                    </div>
                </div>
            </se-block>}
            {isOpenTicketActive && (
                <div id="myopenticketForm" className="ticket Scroll">
                    <se-block display="block" className="px-0">
                        <TableComponent sortable={true} tableHeader={['Ticket ID', 'Site Name', 'Gateway ID', 'Customer Message', 'Raised Date','Action']} tableData={openTicketsData} onResolveClick={iSResolvedTicketsData} tableName='OpenTicketsTable' />
                    </se-block>
                </div>
            )}
            {isResolveTicketActive && (
                <div id="myresolveticketForm" className="ticket Scroll">
                    <se-block display="block" className="px-0">
                        <TableComponent sortable={true} tableHeader={['Ticket ID', 'Site Name', 'Gateway ID', 'Customer Message', 'Raised Date', 'Closed Date']} tableData={resolvedTicketsData} tableName='ResolvedTicketsTable' />
                    </se-block>
                </div>
            )}
            <div class="row justify-content-md-center">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <se-snackbar id="snackbar_success" duration="5000" type={successType} icon="" message={successMsg} ></se-snackbar>
                </div>
            </div>
        </div>       
    );
};

export default tickets;