import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _, { result } from 'lodash';
import AzureMap from '../components/azureMaps';
import './styles.css';
import Auth from '../api/ApiSession';
import { useSelector } from 'react-redux'
const authCall = new Auth();
const LocalMap = (props) => {
  const userObj = useSelector((state)=> state.user)
  var mapKey = "EjxE0q0P0oaDwLytJ7BcKHyEwyKW6xCv7pHEPQ5SYvBF8aEy9PrnJQQJ99AIAC5RqLJs0DShAAAgAZMPCbuT";
  var LocationObject = [], customerObjet = [];
  const [customerList, setCustomerList] = React.useState([])
  const [customerData, setCustomerData] = React.useState([])
  const [locationMapData, setlocationMapData] = React.useState({})
  const fetchCustomerDetails = () => {
    let customerType = (sessionStorage.getItem("customerType") === null ? 'Agro' : sessionStorage.getItem("customerType"))
    authCall.getAgroCustomersList(customerType).then(entries => {
      // setCustomerList(entries)
      entries = entries.filter((item) => item.installedDate !== null);
      entries.map(ele => {
        if (ele.longitude && ele.latitude) {
          LocationObject.push({ "type": "Feature", "geometry": { "type": "Point", "coordinates": [Number(ele.longitude), Number(ele.latitude)] }, "properties": { "id": ele.customerId, "location": ele.location, "EntityType": ele.pump_status } })
        }
        customerObjet.push(ele);
      })
      setCustomerList(LocationObject)
      setCustomerData(customerObjet)
    }).catch(error => {
      console.log(error);
    });
  }
  // setCustID = (customerId, siteName) => {
  //     this.props.onClick(customerId, siteName)
  //   }
  React.useEffect(() => {
    fetchCustomerDetails();

  }, [])
  const load = (customerData) => {
    // console.log(customerData)
    return customerData.map((d, index) => {
      if (d.location) {
        var addr = d.address;
        // if (addr.length > 30) {
        //   addr = addr.substring(0, 30) + "...";
        // }
        return (

          <se-list-item key={index} selected={parseInt(d.customerId) === parseInt(sessionStorage.getItem("customerId")) ? true : false} item={d.location} value={d.customerId} onClick={() => { ZoomCustID(d.customerId, d.location, d) }} description={addr} />

        )
      }
    })
  }
  const navigateToOverview = () => {
    let { history } = props;
    history.push({
      pathname: '/overview'
    });

  }
  const setCustID = (customerId, siteName) => {
    // this.props.onClick(customerId, siteName)
    // console.log(customerId,siteName)
    sessionStorage.setItem("customerId", parseInt(customerId));
    sessionStorage.setItem("siteName", siteName);
    navigateToOverview()
  }
  React.useEffect(() => {
    let cdata = customerData.find(obj => obj.customerId === Number(sessionStorage.getItem("customerId")))
    console.log(cdata, sessionStorage.getItem("customerId"))
    if (cdata !== undefined) {
      ZoomCustID(cdata.customerId, cdata.siteName, cdata)
    }

  }, [customerData])
  const ZoomCustID = (customerId, siteName, d) => {
    // this.props.onClick(customerId, siteName)
    // console.log(customerId,siteName)
    sessionStorage.setItem("customerId", parseInt(customerId));
    sessionStorage.setItem("siteName", siteName);
    console.log(d, d.latitude)
    //navigateToOverview()

    let lat = d.latitude
    let lon = d.longitude
    console.log(lat, lon)

    if (lat !== '') {
      let latlog = []
      latlog.push(lon)
      latlog.push(lat)
      
      authCall.getMapLocationsByLonLat(mapKey, latlog).then(json => {
        if (json.addresses[0].address !== undefined) {
          // console.log(json)
          setlocationMapData(json.addresses[0])
          // initiateMap(props.isDisabled, json.addresses[0])
        }
      })
    }

  }
  return <se-container>
    {customerList.length ?
      <se-container padding="none" color="standard" display="flex">
        <se-block option="card" class="col-md-3 px-0" >
          <div className="rows" style={{ height: "50px" }}>

            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pt-2 pl-0 color-standard text-right">

              <se-button caption="click to go back" title="click to go back" size="small" icon="action_previous" color="alternative" class="" onClick={() => navigateToOverview()} icon-only></se-button>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 pr-0 my-auto">
              <span className="font-normal font-weight-600 NotificationHeaderColor">Customer locations</span>
            </div>
          </div>
          <se-block-content option="fill">
            <div className="nav-content">
              <se-list>
                {load(customerData)}
              </se-list>
            </div>
          </se-block-content>
          <se-block-footer class="grey-backgroundCreate" >
            <div slot="start" class="rows profile_fontsize" >
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-7 font-normal font-weight-600" align="left">Total customers: {customerList.length}</div>
            </div>
          </se-block-footer>
        </se-block>
        {/* <se-list >
              {load(customerData)}

            </se-list> */}
        {/* <se-block-content option="fill" padding="none" margin="none" display="grid" style={{ height: "85vh", overflow: "hidden", padding: "0px !important" }}> */}
        <se-block-content option="fill">
          <AzureMap id="" mapKey={mapKey} onClick={setCustID} MapZoomData={locationMapData} LocationObject={customerList} latitude={13.0392} longitude={77.7586} style={{ height: "85vh" }} />
        </se-block-content>
      </se-container>
      : null}
  </se-container>
}
export default LocalMap
