import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, FormText } from 'react-bootstrap';
import Select from "react-select";
import Auth from '../api/ApiSession';
import LandDetails2 from './myCustomersComponents/landDetails';
import LandDetailsWithMap from './myCustomersComponents/landDetailsWithMap'
import AssetsDetails from './myCustomersComponents/assestDetails';
import IrradianceDetails from './myCustomersComponents/irradiance';
import CustomerProfile2 from './createNewUserComponents/customerProfile2';
import AddAssets from './createNewUserComponents/addAssets';
import GatewayInfo2 from './createNewUserComponents/gatewayInfo2';
import IncomeDetails2 from './createNewUserComponents/incomeDetails2';
import CryptoJS from 'crypto-js'
import moment from 'moment'
import { useSelector } from 'react-redux'
let isClearable = true;
let optionList = [];
const authCall = new Auth();
const _ = require("lodash");
const MyCustomers = (props) => {
  let user = "";
  const encrypted = sessionStorage.getItem("user");    
  if(encrypted!=null){
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    user = JSON.parse(decrypted);
  }
  const [customerList, setCustomerList] = React.useState([])
  const [customerInfo, setCustomerInfo] = React.useState({})
  const [copyCustomerInfo, setCopyCustomerInfo] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [isDisabled, setIsDisabled] = React.useState(true)
  let [tempArray] = React.useState([])
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(false)
  const [customerListCopy, setCustomerListCopy] = React.useState([])
  const [selectedCustomer, setSelectedCustomer] = React.useState({})
  const [snackBarObj, setSnackBarObj] = React.useState({})
  const [assetData, setAssetData] = React.useState({})
  const [addAssestsData, setAddAssestsData] = React.useState(null)
  const [editAssetsData, setEditAssetsData] = React.useState(null)
  const [isSaveDisabled2, setIsSaveDisabled2] = React.useState(false)
  const [isSaveDisabled3, setIsSaveDisabled3] = React.useState(false)
  const [terminal, setTerminal] = React.useState(null)
  const [tabLoader, setTabLoader] = React.useState(false)
  let counter = 0
  const [selectedTermialOnEdit, setSelectedTermialOnEdit] = React.useState(false)
  const userObj = useSelector((state)=> state.user)

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3DCD58" : "white",
      color: state.isSelected ? "white" : "black",
      fontFamily: "Nunito"
    }),
  };

  const selectedSiteMobile = (site) => {
    let selectedCustomerList = customerList.find(item => item.customerId === site.value);
    getCustomerDetails(site.value,selectedCustomerList.Customers, selectedCustomerList.state, selectedCustomerList.deviceType);    
  }
    

  React.useEffect(() => {
    optionList = customerList.map((item) => {
      return { value: item.customerId, label: item.Customers + ' - ' + item.state };
    });
  },[customerList]);

  const fetchCustomerDetails = () => {
    props.onChange(moment().format('DD/MM/YYYY h:mm:ss a'))
    authCall.getAgroCustomersList(sessionStorage.getItem("customerType")).then(entries => {
      if (entries && entries.length > 0) 
      {
        _.map(entries, (eachCustomer, index) => {
         
          if (index === 0 && sessionStorage.getItem("customerId")==='0') {
            eachCustomer.selected = true
            getCustomerDetails(eachCustomer.customerId,'')
          }
          else if(sessionStorage.getItem("customerId").toString()===eachCustomer.customerId.toString()){
            eachCustomer.selected = true
            getCustomerDetails(eachCustomer.customerId,'')
          }
          else {
            eachCustomer.selected = false
          }
        })
        setCustomerListCopy(JSON.parse(JSON.stringify(entries)))
        setCustomerList(entries)
        //update first record details when we come from All Sites
        if(user.gatewayType === null || user.gatewayType === undefined || user.gatewayType === '' || user.typeofuser == null || user.typeofuser == undefined || user.typeofuser == '')
        {
          const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: entries[0].typeofUser, gatewayType: entries[0].deviceType}), process.env.REACT_APP_SECRET_KEY).toString();        
          sessionStorage.setItem("user", encrypted);
        }
        let cid=sessionStorage.getItem("customerId")
        setTimeout(() => {
          if(window.document.getElementById(cid)){
            window.document.getElementById(cid).scrollIntoView({block:"center"});
          }  
        }, 1000);
      }else{
        // update user details when no customer found
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: null, gatewayType: user.gatewayType}), process.env.REACT_APP_SECRET_KEY).toString();
        sessionStorage.setItem("user", encrypted);
          
        user.typeofuser = "";
      }
    }).catch(error => {
      console.log(error);
    });
  }
  const gotoNewCustomer = () => {
    let { history } = props;
    history.push({
      pathname: "/createnewuser",
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "createNewCustomer" });
  };
  const getCustomerDetails = (id, Customers, state,  deviceType) => {
    console.log("getCustomerDetails " + id)
    if (deviceType !== undefined && deviceType !== null && deviceType !== "") {   
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: user.typeofuser, gatewayType: deviceType}), process.env.REACT_APP_SECRET_KEY).toString();
      sessionStorage.setItem("user", encrypted);
    }
    let site ={};
    if(Customers){
      site =  { value: id, label: Customers + ' - ' + state };
    }
    sessionStorage.setItem("siteSelectedInMobile", JSON.stringify(site)); 
    setIsLoading(true);
    setCustomerInfo({});

    _.map(customerList, (eachCustomer) => {
      id === eachCustomer.customerId
        ? (eachCustomer.selected = true)
        : (eachCustomer.selected = false);
    });
    authCall
      .getCustomerPersonalInfo(id)
      .then((details) => {        
        const username = details.personalData.find(obj => obj.PropName === "First Name").PropValue;        
        const state = details.personalData.find(obj => obj.PropName === "State").PropValue;
        site =  { value: id, label: username + ' - ' + state };
        sessionStorage.setItem("siteSelectedInMobile", JSON.stringify(site));
        setCustomerInfo(details);
        setCopyCustomerInfo(JSON.parse(JSON.stringify(details)));
        setIsLoading(false);
        sessionStorage.setItem("customerId", parseInt(id));
        sessionStorage.setItem("deviceType",details.detailsInfo[1].infoData[1] !== undefined ? details.detailsInfo[1].infoData[1].PropValue: "");
        
        //const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser:details.personalData[1].PropValue, gatewayType: deviceType}), process.env.REACT_APP_SECRET_KEY).toString();            
        //sessionStorage.setItem("user", encrypted);
        if(document.getElementById("configPage") !== null)
        {
          if(details.detailsInfo[1].infoData[1] === undefined)
          {            
            document.getElementById("configPage").style.display = "none;"
          }else{
            document.getElementById("configPage").style.display = details.detailsInfo[1].infoData[1].PropValue === "ATV320"? "flex" : "none";
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const headerClick = (index) => {
    setSelectedIndex(index);
  };

  const renderCustomerList = _.map(customerList, (eachCustomer) => {
    return (
      <se-list-item
        devicetype={eachCustomer.deviceType}
        id={eachCustomer.customerId}
        selected={eachCustomer.selected}
        item={eachCustomer.Customers}
        value={eachCustomer.customerId}
        description={eachCustomer.state.toUpperCase()}
        onClick={() =>
          getCustomerDetails(eachCustomer.customerId,eachCustomer.Customers, eachCustomer.state,  eachCustomer.deviceType)
        }
      >
        {eachCustomer.selected ? (
          <se-icon
            onClick={(e) => {
              onSelectCustomerDelete(
                e,
                eachCustomer.customerId,
                eachCustomer.Customers
              );
            }}
            size="small"
            color="standard"
          >
            action_delete
          </se-icon>
        ) : null}
      </se-list-item>
    );
  });
  const onEdit = () => {
    let assetData = customerInfo.detailsInfo[2].DeviceDetails;  
    if(customerInfo.detailsInfo[2].DeviceDetails[0] !== undefined)
    {
      if(customerInfo.personalData[1].PropValue === "1"){      
        assetData = customerInfo.detailsInfo[2].DeviceDetails[0].LoadData[0].PropData;
        const propToMove = assetData.find(obj => obj.PropName === "Tariff/hr");
        const indexOfPropToMove = assetData.indexOf(propToMove);
        if (indexOfPropToMove > -1) {
          assetData.splice(indexOfPropToMove, 1);
        }
      }
    }
    setTabLoader(true);
    onAssetsDetailsChange(assetData, 2);
    setIsDisabled(false);
    setTimeout(() => {
      setTabLoader(false);
    }, 1000);

    //added to solve individual customer edit issue
    let copyInfoData = JSON.parse(JSON.stringify(customerInfo));
    setCustomerInfo(copyInfoData);
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "EditCustomer" });
  };
  const onCancel = () => {
    setIsDisabled(true);
    let copyInfoData = JSON.parse(JSON.stringify(copyCustomerInfo));
    setCustomerInfo(copyInfoData);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "CancelEditCustomer" });
    window.location.reload();
  };
  const postCustomerProfile = (data) => {
    let jsonBody = {
      customerId: customerInfo.customerId,
      "personalData": data
    }
    authCall.postCustomerprofile(jsonBody).then(json => {
      if (json) {
        document.getElementById('mycustomer-snackbar').open = true;
        setSnackBarObj({ type: "success", msg: "Data saved successfully" })
      }
    })
      .catch(error => {
        console.log(error);
      });
  };
  const postLandDetails = (data) => {
    let jsonBody = {
      customerId: customerInfo.customerId,
      "detailsInfo": [{ "infoData": data }]
    }
    authCall.postAgroLandDetails(jsonBody).then(json => {
      if (json) {
        document.getElementById('mycustomer-snackbar').open = true;
        setSnackBarObj({ type: "success", msg: "Data saved successfully" })
      }
    })
      .catch(error => {
        console.log(error);
      });
  };
  const postGatewayInfo = (data) => {
    let jsonBody = {
      customerId: customerInfo.customerId,
      "detailsInfo": [{ "infoData": data }]
    }
    authCall.postAgroDriveDetails(jsonBody).then(json => {
      if (json) {
        if (json === customerInfo.customerId) {
          document.getElementById('mycustomer-snackbar').open = true;
          setSnackBarObj({ type: "success", msg: "Data saved successfully" })
        } else {
          setTimeout(() => {
            let copyInfoData = JSON.parse(JSON.stringify(copyCustomerInfo))
            let copyCustomerInfo2 = JSON.parse(JSON.stringify(customerInfo))
            copyCustomerInfo2.detailsInfo[1].infoData = copyInfoData.detailsInfo[1].infoData
            setCustomerInfo(copyCustomerInfo2)
            document.getElementById('mycustomer-snackbar').open = true;
            setSnackBarObj({ type: "error", msg: json })
          }, 1000);
        }
      }
    })
      .catch(error => {
        console.log(error);
      });
  };
  const postAssets = (data) => {
    authCall.postAgroDeviceDetails(data).then(json => {
      if (json) {
        document.getElementById('mycustomer-snackbar').open = true;
        setSnackBarObj({ type: "success", msg: "Data saved successfully" })
      }
    })
      .catch(error => {
        console.log(error);
      });
  };
  const postIrradiance = (data) => {
    let jsonBody = {
      customerId: customerInfo.customerId,
      "detailsInfo": [{ "irRadianceData": data }]
    }
    authCall.postAgroIRRadianceDetails(jsonBody).then(json => {
      if (json) {
        document.getElementById('mycustomer-snackbar').open = true;
        setSnackBarObj({ type: "success", msg: "Data saved successfully" })
      }
    })
      .catch(error => {
        console.log(error);
      });
  };
  const postIncomeDetails = (data) => {
    let jsonBody = {
      customerId: customerInfo.customerId,
      "detailsInfo": [{ "infoData": data }]
    }
    authCall.postAgroIncomeDetails(jsonBody).then(json => {
      if (json) {
        document.getElementById('mycustomer-snackbar').open = true;
        setSnackBarObj({ type: "success", msg: "Data saved successfully" })
      }
    })
      .catch(error => {
        console.log(error);
      });
  };
  const onSave = () => {
    postCustomerProfile(customerInfo.personalData);
    postLandDetails(customerInfo.detailsInfo[0].infoData);
    postGatewayInfo(customerInfo.detailsInfo[1].infoData);
    postAssets(assetData);
    postIrradiance(customerInfo.detailsInfo[3].irRadianceData);
    postIncomeDetails(customerInfo.detailsInfo[4].infoData);
    setIsDisabled(true);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "UpdateCustomer" });
  };
  const getResult = (error, index) => {
    for (let i = 0; i < error.length; i++) {
      if (error[i].PropMsg || error[i].PropValue === "") {
        tempArray[index] = true;
        break;
      } else {
        tempArray[index] = false;
      }
    }
    let result = _.find(tempArray, (o) => {
      if (o === true) {
        return true;
      }
    });
    setIsSaveDisabled2(result);
  };
  const landDetailsError = (error) => {
    getResult(error, 0);
  };
  const gatewayInfoError = (error) => {
    getResult(error, 1);
  };
  const incomeDetailsError = (error) => {
    for (let i = 0; i < error.length; i++) {
      if (error[i].PropMsg) {
        tempArray[2] = true;
        break;
      } else {
        tempArray[2] = false;
      }
    }
    let result = _.find(tempArray, (o) => {
      if (o === true) {
        return true;
      }
    });
    setIsSaveDisabled2(result);
  };
  const irradianceDetailsError = (errors) => {
    let result = false;
    for (let j = 0; j < errors.length; j++) {
      if (result === true) {
        break;
      }
      for (let i = 0; i < errors[j]["data"].length; i++) {
        if (errors[j]["data"][i].PropMsg) {
          tempArray[3] = true;
          break;
        } else {
          tempArray[3] = false;
        }
      }
      result = _.find(tempArray, (o) => {
        if (o === true) {
          return true;
        }
      });
    }

    setIsSaveDisabled2(result);
  };
  const onBlur = (callback, index) => {
    let copyInfoData = JSON.parse(JSON.stringify(customerInfo));
    copyInfoData.detailsInfo[index].infoData = callback;
    setCustomerInfo(copyInfoData);
  };

  const onIrradianceChange = (callback, index) => {
    let copyInfoData = JSON.parse(JSON.stringify(customerInfo));
    copyInfoData.detailsInfo[index].irRadianceData = callback;
    setCustomerInfo(copyInfoData);
  };
  
  const removeLandDetailsProperty = () => {
    let infoData = customerInfo.detailsInfo[0].infoData;
    if(userObj.user_type !== "Admin" && userObj.user_type !== "Site Manager" && user.typeofuser === '1' || customerInfo.personalData[1].PropValue === '1'){
      let propNamesToRemove = ["Land Owned (In acres)","No. of Farmers Men","No. of Farmers Women","No. of Farmers Working"]; 
      const filteredInfoData = infoData.filter(item => !propNamesToRemove.includes(item.PropName));
      return filteredInfoData;
    }
    return infoData;
  };
  const formJson = (data, AssetsType) => {
    let tempArray = [];
    tempArray.push({
      PropName: "Asset Type",
      PropValue: AssetsType,
      units: "",
      PropData: null,
    });
    _.map(data.PropData, (ele) => {
      tempArray.push({
        PropName: ele.PropName,
        PropValue: ele.PropValue,
        units: ele.units,
        PropData: ele.PropData,
      });
    });
    return tempArray;
  };
  const onAssetsDetailsChange = (callback, index) => {
    let tempArray = [];
    _.map(callback, (ele) => {
      _.map(ele.LoadData, (LoadData, index) => {
        tempArray.push({
          PropId: LoadData.PropId,
          PropStatus: LoadData.PropStatus,
          PropData: formJson(LoadData, ele.AssetType),
        });
      });
    });
    let dataForAssests = {
      customerId: customerInfo.customerId,
      detailsInfo: [
        {
          DeviceDetails: tempArray,
        },
      ],
    };
    let copyInfoData = JSON.parse(JSON.stringify(customerInfo));
    copyInfoData.detailsInfo[index].DeviceDetails = callback;
    setCustomerInfo(copyInfoData);
    setAssetData(dataForAssests);
  };
  const CustomerProfileChange = (callback) => {
    let copyInfoData = JSON.parse(JSON.stringify(customerInfo));
    copyInfoData.personalData = callback;
    setCustomerInfo(copyInfoData);
  };
  const getError = (callback) => {
    let tempArray = [];
    let result = false;
    _.map(callback, (ele) => {
      if (ele.PropName !== "Site Type") {
        tempArray.push(ele);
      }
    });
    for (let i in tempArray) {
      if (tempArray[i].PropValue === "" || tempArray[i].PropMsg) {
        result = true;
        break;
      } else {
        result = false;
      }
    }
    setIsSaveDisabled(result);
  };
  const openPopUp = (id) => {
    if (isDisabled === false) {
      document.getElementById(id).open = true;
    }
    let dataForaddAssets = {
      assetsType: null,
      data: null,
      disabledAssetType: false,
    };
    setEditAssetsData(dataForaddAssets);
    setSelectedTermialOnEdit(false);
  };
  const onClosePopUp = (callback) => {
    setEditAssetsData(null);
    document.getElementById(callback).open = false;
  };
  const onLoadClosePopUp = (id) => {
    document.getElementById(id).open = false;
  };
  const onAssestsClosePopUp = (callback) => {
    let found = 0;
    let count = 0;
    if (
      _.find(addAssestsData[0].PropData, ["PropName", "Asset Type"])
        .PropValue !== "" &&
      addAssestsData[0].PropId === ""
    ) {
      _.map(
        customerInfo.detailsInfo[2].DeviceDetails,
        (DeviceDetail, index) => {
          if (
            DeviceDetail.AssetType.toLowerCase() ===
            _.find(addAssestsData[0].PropData, [
              "PropName",
              "Asset Type",
            ]).PropValue.toLowerCase()
          ) {
            found = 1;
            let finalData = [];
            _.map(addAssestsData[0].PropData, (eachData) => {
              _.map(DeviceDetail.loadHeader, (loadHeader) => {
                if (
                  eachData.PropName.toLowerCase() ===
                  loadHeader.PropName.toLowerCase()
                ) {
                  finalData.push({
                    PropName: eachData.PropName,
                    PropValue: eachData.PropValue,
                    units: eachData.units,
                    PropData: eachData.PropData,
                  });
                }
              });
              if (
                eachData.PropName === "Enter Power Vs Water flow data" &&
                _.find(DeviceDetail.loadHeader, [
                  "PropName",
                  "Enter Power Vs Water flow data",
                ]) === undefined
              ) {
                finalData.push({
                  PropName: eachData.PropName,
                  PropValue: eachData.PropValue,
                  units: eachData.units,
                  PropData: eachData.PropData,
                });
              }
            });
            DeviceDetail.LoadData.push({
              PropId: "",
              PropStatus: addAssestsData[0].PropStatus,
              PropData: finalData,
            });
          }
        }
      );
      if (found === 0) {
        let loadHeaderData = [];
        let finalData = [];
        _.map(addAssestsData[0].PropData, (eachData) => {
          if (eachData.PropName !== "Asset Type") {
            loadHeaderData.push({
              PropName: eachData.PropName,
              units: eachData.units,
            });
            finalData.push({
              PropName: eachData.PropName,
              PropValue: eachData.PropValue,
              units: eachData.units,
              PropData: eachData.PropData,
            });
          }
        });
        customerInfo.detailsInfo[2].DeviceDetails.push({
          AssetType: _.find(addAssestsData[0].PropData, [
            "PropName",
            "Asset Type",
          ]).PropValue,
          loadHeader: loadHeaderData,
          LoadData: [
            {
              PropId: "",
              PropStatus: addAssestsData[0].PropStatus,
              PropData: finalData,
            },
          ],
        });
      }
    } else {
      _.map(
        customerInfo.detailsInfo[2].DeviceDetails,
        (DeviceDetail, index) => {
          _.map(DeviceDetail.LoadData, (LoadData) => {
            if (LoadData.PropId === addAssestsData[0].PropId) {
              let copyAddAssestsData = JSON.parse(
                JSON.stringify(addAssestsData[0])
              );
              copyAddAssestsData.PropData.splice(0, 1);
              LoadData.PropData = copyAddAssestsData.PropData;
            }
          });
        }
      );
    }
    let comparingArray = ["1", "2", "3", "4"];
    let arrayLoad = [];
    _.map(customerInfo.detailsInfo[2].DeviceDetails, (DeviceDetail, index) => {
      _.map(DeviceDetail.LoadData, (LoadData) => {
        if (LoadData.PropId) {
          arrayLoad.push(LoadData.PropId);
        }
      });
    });
    let missing = [];
    let lenC = comparingArray.length;
    for (let i = 0; i < lenC; i++) {
      if (arrayLoad.indexOf(comparingArray[i]) === -1)
        missing.push(comparingArray[i]);
    }
    _.map(customerInfo.detailsInfo[2].DeviceDetails, (DeviceDetail, index) => {
      _.map(DeviceDetail.LoadData, (LoadData) => {
        if (LoadData.PropId === "") {
          LoadData.PropId = missing[0];
        }
      });
    });
    onAssetsDetailsChange(customerInfo.detailsInfo[2].DeviceDetails, 2);
    if (selectedTermialOnEdit === false) {
      setTerminal(missing[0]);
    }
    document.getElementById(callback).open = false;
    document.getElementById("dialog-load-Popup").open = true;
  };
  const onAssetsChange = (data, errors) => {
    setAddAssestsData(data);
    let result = false;
    for (let i in errors) {
      for (let j in errors[i].PropData) {
        if (
          errors[i].PropData[j].PropName !== "Asset Type" &&
          errors[i].PropData[j].PropName !== "Type" &&
          // && (errors[i].PropData[j].PropValue === "" && errors[i].PropData[j].PropName === "Motor Power")
          errors[i].PropData[j].PropName !== "Enter Power Vs Water flow data"
        ) {
          if (
            errors[i].PropData[j].PropValue === "" &&
            (errors[i].PropData[j].PropName === "Motor Power" ||
              errors[i].PropData[j].PropName === "Frequency" ||
              errors[i].PropData[j].PropName === "Water Discharge/hr ")
          ) {
          } else if (
            errors[i].PropData[j].PropValue === "" ||
            errors[i].PropData[j].PropMsg
          ) {
            result = true;
            break;
          }
        }
      }
    }
    setIsSaveDisabled3(result);
  };
  const filterList = (e) => {
    var searchString = e.target.value;
    searchString = searchString.toLowerCase();
    let responseData = null;
    if (searchString.length > 0) {
      responseData = customerListCopy.filter((item) => {
        return item.Customers.toLowerCase().indexOf(searchString) !== -1;
      });
      setCustomerList(responseData);
    } else {
      setCustomerList(customerListCopy);
    }
  };
  const onSelectCustomerDelete = (e, id, customer) => {
    setSelectedCustomer({ customerId: id, customerName: customer });
    e.stopPropagation();
    document.getElementById("dialog-load-Popup2").open = true;
    // debugger
    // document.getElementById('dialog-simple-notitle').open = true;
  };
  const items = document.querySelectorAll(".accordion button");

  const modalClick = (id) => {
    const tabs = ["accordion-button-1", "accordion-button-2", "accordion-button-3", "accordion-button-4", "accordion-button-5"]
    tabs.forEach(element => {
      if(element.toString() !== id){
        document.getElementById(element).setAttribute("aria-expanded", "false");
      }
    });
    let x = document.getElementById(id).getAttribute("aria-expanded");
    if (x === "true") 
    {
    x = "false"
    } else {
    x = "true"
    }
    document.getElementById(id).setAttribute("aria-expanded", x);
  }
  
  const CustomerDelete = () => {
    authCall
      .deleteCustomer(selectedCustomer.customerId)
      .then((json) => {
        if (json) {
          // document.getElementById('dialog-simple-notitle').open = false;
          document.getElementById("dialog-load-Popup2").open = false;
          document.getElementById("mycustomer-snackbar").open = true;
          setSnackBarObj({
            type: "success",
            msg: "Customer Deleted Successfully.",
          });
          fetchCustomerDetails();
          getCustomerDetails(customerList[0].customerId, customerList[0].Customers, customerList[0].state, customerList[0].deviceType);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const reSetUserType = () => {
    if (customerList.length === 0) {
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: null, gatewayType: ""}), process.env.REACT_APP_SECRET_KEY).toString();
      sessionStorage.setItem("user", encrypted);
    }
  };
  React.useEffect(() => {
    fetchCustomerDetails();
    //reSetUserType();
  }, []);
  React.useEffect(() => {
    setCustomerInfo(customerInfo);
  }, [customerInfo]);
  React.useEffect(() => {
    setAddAssestsData(addAssestsData);
  }, [addAssestsData]);
  const onEditAssets = (callback, data, assetsType) => {
    setTerminal(data.PropId);
    setSelectedTermialOnEdit(true);
    setEditAssetsData({
      assetsType: assetsType,
      data: data,
      disabledAssetType: true,
    });
    document.getElementById("dialog-AgroAsset-Popup").open = callback;
  };
  const isMobile = window.innerWidth <= 600;
  return (
    <>
      {isMobile && (
        <div>
            <span>              
            </span>
            
            <div className="search-mobile" style={{display:"none"}}>
              <Select
                styles={customStyles}
                defaultMenuIsOpen={false}
                isClearable={isClearable}
                options={optionList}
                placeholder="Select site"
                onChange={selectedSiteMobile}
                value={JSON.parse(sessionStorage.getItem("siteSelectedInMobile"))}
                width="100%"
              />              
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-right edit_link">
                          <div style={{width: '100%', display: "block"}}>
                            <div>
                            <se-icon class="pointer" size="small" onClick={() => onEdit()} color="standard">action_editor</se-icon>
                            <se-icon size="medium" class="pointer margin-top-add-logo" color="primary" onClick={() => gotoNewCustomer()}>action_add_flat</se-icon>
                            </div>
                            {!isDisabled ? (
                              <div >
                                <div class="btn-flex">
                                  <se-button
                                    size="small"
                                    option="outline"
                                    onClick={() => onCancel()}
                                  >
                                    Cancel
                                  </se-button>
                                  <se-button
                                    size="small"
                                    option="flat"
                                    disabled={false}
                                    onClick={() => onSave()}
                                  >
                                    Save
                                  </se-button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
          <div>
            <div class="accordion">
              <div class="accordion-item">
                <button id="accordion-button-1" onClick={() => modalClick("accordion-button-1")} aria-expanded="false">
                  <span class="accordion-title">Land Details</span>
                  <span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  {Object.keys(customerInfo).length > 0 ? (
                    <div
                      padding="large"
                      color="standard"
                      display="flex"
                      class="lp-0"
                    >
                      <div
                        padding="none"
                        margin="none"
                        display="block"
                        direction="column"
                        row-padding=""
                        class="pl-0 overflow-hidden"
                        color="standard"
                      >
                        <div
                          padding="none"
                          margin="none"
                          display="flex"
                          color="standard"
                        >
                          <div display="flex" color="standard">
                            <div color="alternative" divider="false">
                              <se-block-header option="fill">
                                {tabLoader ? (
                                  <div class="align-items-center">
                                    <se-loading loading="true"></se-loading>
                                  </div>
                                ) : null}
                                <LandDetailsWithMap
                                  isDisabled={isDisabled}
                                  index={0}
                                  onChange={landDetailsError}
                                  onBlur={onBlur}
                                  infoData={removeLandDetailsProperty()}
                                  customerType={
                                    customerInfo.personalData[1].PropValue
                                  }
                                />
                              </se-block-header>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-2" onClick={() => modalClick("accordion-button-2")} aria-expanded="false">
                  <span class="accordion-title">Gateway Info</span>
                  <span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  {Object.keys(customerInfo).length > 0 ? (
                    <div>
                      {tabLoader ? (
                        <div class="align-items-center">
                          <se-loading loading="true"></se-loading>
                        </div>
                      ) : null}
                      <GatewayInfo2
                        isDisabled={isDisabled}
                        index={1}
                        onChange={gatewayInfoError}
                        onBlur={onBlur}
                        infoData={customerInfo.detailsInfo[1].infoData}
                        disabeldGraph="true"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-3" onClick={() => modalClick("accordion-button-3")} aria-expanded="false">
                  <span class="accordion-title">Assets</span>
                  <span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  {Object.keys(customerInfo).length > 0 ? (
                    <div style={{height: isMobile?"164px":""}}>
                      {tabLoader ? (
                        <div class="align-items-center">
                          <se-loading loading="true"></se-loading>
                        </div>
                      ) : null}
                      <div>
                        {_.map(
                          customerInfo.detailsInfo[2].DeviceDetails,
                          (DeviceDetails) => {
                            _.map(
                              DeviceDetails.LoadData,
                              (LoadData, LoadDataIndex) => {
                                counter = counter + 1;
                              }
                            );
                          }
                        )}
                        {counter === 4 ||
                        isDisabled ||
                        (sessionStorage.getItem("dropdownCustomerType") ===
                          "SWP" &&
                          counter > 0) ? null : (
                          <div className="pr-3 text-right">
                            <se-icon
                              size="medium"
                              onClick={() =>
                                openPopUp("dialog-AgroAsset-Popup")
                              }
                              class="pointer icon-medium hydrated"
                              color="primary"
                            >
                              action_add_flat
                            </se-icon>
                          </div>
                        )}
                        <AssetsDetails
                          isDisabled={isDisabled}
                          onBlur={onAssetsDetailsChange}
                          index={2}
                          onEdit={onEditAssets}
                          DeviceDetails={
                            customerInfo.detailsInfo[2].DeviceDetails
                          }
                          customerType={
                            customerInfo.personalData[1].PropValue}
                        />  
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-4" onClick={() => modalClick("accordion-button-4")} aria-expanded="false">
                  <span class="accordion-title">Irradiance Info</span>
                  <span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  {Object.keys(customerInfo).length > 0 ? (
                    <div>
                      {tabLoader ? (
                        <div class="align-items-center">
                          <se-loading loading="true"></se-loading>
                        </div>
                      ) : null}
                      <IrradianceDetails
                        isDisabled={isDisabled}
                        onChange={irradianceDetailsError}
                        onBlur={onIrradianceChange}
                        index={3}
                        irRadianceData={
                          customerInfo.detailsInfo[3].irRadianceData
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-5" onClick={() => modalClick("accordion-button-5")} aria-expanded="false">
                  <span class="accordion-title">Income Details</span>
                  <span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  {Object.keys(customerInfo).length > 0 ? (
                    <div>
                      {tabLoader ? (
                        <div class="align-items-center">
                          <se-loading loading="true"></se-loading>
                        </div>
                      ) : null}
                      <LandDetails2
                        isDisabled={isDisabled}
                        index={4}
                        onChange={incomeDetailsError}
                        onBlur={onBlur}
                        infoData={customerInfo.detailsInfo[4].infoData}
                        disabeldGraph="true"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <se-container className="my-customer">
        {isLoading && customerList.length ? (
          <div class="align-items-center">
            <se-loading loading="true"></se-loading>
          </div>
        ) : null}
        {/* {customerInfo.personalData.length > 0 ? 
     
                                <LandDetailsWithMap isDisabled={isDisabled} index={0}
                                  onChange={landDetailsError} onBlur={onBlur}
                                  infoData={removeLandDetailsProperty()} customerType={customerInfo.personalData[1].PropValue} /> :null
                               
      } */}

        <se-container
          padding="none"
          color="standard"
          display="flex"  
        >
          <se-block option="card" class="col-md-3 col-sm-4 px-0 hideinMobile">
            <input
              type="search"
              id="filter"
              onChange={(e) => filterList(e)}
              placeholder="Search for..."
            />
            <se-block-content option="fill">
              <div className="nav-content">
                <se-list>{renderCustomerList}</se-list>
              </div>
            </se-block-content>
            <div align="right" className="padingRight10">
              <se-icon
                size="large"
                class="pointer"
                color="primary"
                onClick={() => gotoNewCustomer()}
              >
                action_add_flat
              </se-icon>
            </div>
            <se-block-footer class="grey-backgroundCreate">
              <div slot="start" class="rows profile_fontsize">
                <div
                  class="col-sm-12 col-md-12 col-lg-12 col-xl-7 font-normal font-weight-600"
                  align="left"
                >
                  Total customers: {customerList.length}
                </div>
              </div>
            </se-block-footer>
          </se-block>

          <se-dialog
            id="dialog-AgroAsset-Popup"
            color="alternative"
            can-backdrop="false"
            size="large"
            style={{display: 'block',position: 'fixed'}}
          >
            <se-container display="flex" padding="small">
              <AddAssets
                index={2}
                infoData={editAssetsData}
                disabledIncreament={true}
                onChange={onAssetsChange}
                close={() => onClosePopUp("dialog-AgroAsset-Popup")}
                customerType={customerInfo.personalData !== undefined ? customerInfo.personalData[1].PropValue : '0'}
              />
            </se-container>
            <se-dialog-footer style={{paddingBottom: '140px'}}>
              <se-button disabled={isSaveDisabled3}
                onClick={() =>onAssestsClosePopUp("dialog-AgroAsset-Popup")}>Confirm changes
              </se-button>
            </se-dialog-footer>
          </se-dialog>
                    
          {Object.keys(customerInfo).length > 0 ? (
            <se-container
              padding="large"
              color="standard"
              display="flex"
              class="lp-0 hideinMobile"
            >
              <se-container
                padding="none"
                margin="none"
                display="block"
                direction="column"
                row-padding=""
                class="pl-0 overflow-hidden"
                color="standard"
              >
                <se-block class="mb-3">
                  <se-container display="flex" padding="none" class="pt-2 pb-2">
                    <div
                      class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 text-left"
                      onClick={() => openPopUp("dialog-Popup")}
                    >
                      <div class="row">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-2 text-left pl-2 imageContainer pointer">
                          <img alt="img"
                            src={
                              _.find(customerInfo.personalData, ["PropName","Photo",]) !== undefined ? _.find(customerInfo.personalData, ["PropName","Photo",]).PropValue : ''
                            }
                            height="68px"
                            width="68px"
                          ></img>
                        </div>
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9 col-xl-10 pl-2 customer-info">
                          <strong>
                            {
                              _.find(customerInfo.personalData, ["PropName","First Name"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","First Name",]).PropValue : ''
                            }{" "}
                            {
                              _.find(customerInfo.personalData, ["PropName","Last Name"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","Last Name"]).PropValue : ''
                            }{" "}
                          </strong>
                          <div className="pointer">
                            <p>
                              {
                                _.find(customerInfo.personalData, ["PropName","Address"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","Address"]).PropValue : ''
                              }
                            </p>
                            <p>
                              {
                                _.find(customerInfo.personalData, ["PropName","City"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","City"]).PropValue : ''
                              }
                              ,{" "}
                              {
                                _.find(customerInfo.personalData, ["PropName","State"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","State"]).PropValue : ''
                              }
                              ,{" "}
                              {
                                _.find(customerInfo.personalData, ["PropName","Country"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","Country"]).PropValue : ''
                              }
                              ,{" "}
                              {
                                _.find(customerInfo.personalData, ["PropName","Pincode"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","Pincode"]).PropValue : ''
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 text-left">
                      <div class="">
                        <div class="text-left px-0">
                          <div className="font-normal pb-2 pointer emailOption" style={{display: 'inline-flex'}} onClick={() => openPopUp("dialog-Popup")}>
                            <se-icon size="small" class="mr-2">
                              internet
                            </se-icon>
                            <span style={{ display: "inline" }}>
                              {
                                _.find(customerInfo.personalData, ["PropName","Email"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","Email"]).PropValue : ''
                              }
                            </span>
                          </div>
                          <div class="font-normal pointer phoneOption" style={{display: 'inline-flex'}} onClick={() => openPopUp("dialog-Popup")}>
                            <se-icon size="small" class="mr-2">
                              phone
                            </se-icon>
                            <span>
                              {
                                _.find(customerInfo.personalData, ["PropName","Contact Number"]) !== undefined ? _.find(customerInfo.personalData, ["PropName","Contact Number"]).PropValue : ''
                              }
                            </span>
                          </div>
                        </div>
                        <div class="text-right">
                          <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">                              
                              <se-icon class="pointer" size="small" onClick={() => onEdit()} color="standard">action_editor</se-icon>
                            </div>
                            {!isDisabled ? (
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-0 text-right">
                                <div class="btn-flex">
                                  <se-button
                                    size="small"
                                    option="outline"
                                    onClick={() => onCancel()}
                                  >
                                    Cancel
                                  </se-button>
                                  <se-button
                                    size="small"
                                    option="flat"
                                    disabled={false}
                                    onClick={() => onSave()}
                                  >
                                    Save
                                  </se-button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <se-dialog
                      id="dialog-Popup"
                      color="alternative"
                      can-backdrop="false"
                      size="large"
                    >
                      <se-container display="flex" padding="large">
                        <CustomerProfile2
                          personalData={customerInfo.personalData}
                          disabledIcon={false}
                          disabled={isSaveDisabled}
                          onChange={CustomerProfileChange}
                          isError={getError}
                          close={() => onClosePopUp("dialog-Popup")}
                        />
                      </se-container>
                      <se-dialog-footer>
                        <se-button
                          disabled={isSaveDisabled}
                          onClick={() => onClosePopUp("dialog-Popup")}
                        >
                          Confirm changes
                        </se-button>
                      </se-dialog-footer>
                    </se-dialog>
                    <se-dialog
                      id="dialog-load-Popup"
                      color="alternative"
                      can-backdrop="false"
                      size="small"
                    >
                      <se-container display="flex" padding="large">
                        <div>
                          <strong>
                            {addAssestsData !== null
                              ? _.find(addAssestsData[0].PropData, [
                                  "PropName",
                                  "Asset Type",
                                ]).PropValue
                              : null}
                          </strong>{" "}
                          connected to <strong>Load - {terminal}</strong>{" "}
                          terminal
                        </div>
                      </se-container>
                      <se-dialog-footer>
                        <se-button
                          disabled={isSaveDisabled3}
                          onClick={() => onLoadClosePopUp("dialog-load-Popup")}
                        >
                          Ok
                        </se-button>
                      </se-dialog-footer>
                    </se-dialog>
                  </se-container>
                </se-block>
                <se-block
                  padding="none"
                  margin="none"
                  display="flex"
                  color="standard"
                >
                  <se-container display="flex" color="standard">
                    <se-block color="alternative" divider="false">
                      <se-block-header option="fill">
                        {tabLoader ? (
                          <div class="align-items-center">
                            <se-loading loading="true"></se-loading>
                          </div>
                        ) : null}
                        <se-tabbar option="content" color="alternative">
                          <Tabs
                            activeKey={selectedIndex}
                            onSelect={(e) => headerClick(e)}
                            className="landTabHght"
                          >
                            {_.map(
                              customerInfo.detailsInfo,
                              (detailsInfo, index) => {
                                return (
                                  <Tab
                                    eventKey={index}
                                    title={detailsInfo.infoType}
                                  >
                                    <div className="Scroll tabMenuHeight">
                                      <se-block-content
                                        display="grid"
                                        padding="large"
                                      >
                                        {index === 0 ? (
                                          <LandDetailsWithMap
                                            isDisabled={isDisabled}
                                            index={index}
                                            onChange={landDetailsError}
                                            onBlur={onBlur}
                                            infoData={removeLandDetailsProperty()}
                                            customerType={
                                              customerInfo.personalData[1]
                                                .PropValue
                                            }
                                          />
                                        ) : null}
                                        {index === 1 ? (
                                          <GatewayInfo2
                                            isDisabled={isDisabled}
                                            index={index}
                                            onChange={gatewayInfoError}
                                            onBlur={onBlur}
                                            infoData={
                                              customerInfo.detailsInfo[index]
                                                .infoData
                                            }
                                            disabeldGraph="true"
                                          />
                                        ) : null}
                                        {index === 4 ? (
                                          <LandDetails2
                                            isDisabled={isDisabled}
                                            index={index}
                                            onChange={incomeDetailsError}
                                            onBlur={onBlur}
                                            infoData={
                                              customerInfo.detailsInfo[index]
                                                .infoData
                                            }
                                            disabeldGraph="true"
                                          />
                                        ) : null}
                                        {index === 2 ? (
                                          <div>
                                            {_.map(
                                              customerInfo.detailsInfo[index]
                                                .DeviceDetails,
                                              (DeviceDetails) => {
                                                _.map(
                                                  DeviceDetails.LoadData,
                                                  (LoadData, LoadDataIndex) => {
                                                    counter = counter + 1;
                                                  }
                                                );
                                              }
                                            )}
                                            {counter === 4 ||
                                            isDisabled ||
                                            (sessionStorage.getItem(
                                              "dropdownCustomerType"
                                            ) === "SWP" &&
                                              counter > 0) ? null : (
                                              <div className="pr-3 text-right">
                                                <se-icon
                                                  size="medium"
                                                  onClick={() =>
                                                    openPopUp(
                                                      "dialog-AgroAsset-Popup"
                                                    )
                                                  }
                                                  class="pointer icon-medium hydrated"
                                                  color="primary"
                                                >
                                                  action_add_flat
                                                </se-icon>
                                              </div>
                                            )}
                                            <AssetsDetails
                                              isDisabled={isDisabled}
                                              onBlur={onAssetsDetailsChange}
                                              index={index}
                                              onEdit={onEditAssets}
                                              DeviceDetails={
                                                customerInfo.detailsInfo[index].DeviceDetails  
                                              }
                                              customerType={
                                                customerInfo.personalData[1]
                                                  .PropValue
                                              }
                                            />
                                          </div>
                                        ) : null}
                                        {index === 3 ? (
                                          <IrradianceDetails
                                            isDisabled={isDisabled}
                                            onChange={irradianceDetailsError}
                                            onBlur={onIrradianceChange}
                                            index={index}
                                            irRadianceData={
                                              customerInfo.detailsInfo[index]
                                                .irRadianceData
                                            }
                                          />
                                        ) : null}
                                      </se-block-content>
                                    </div>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>
                        </se-tabbar>
                      </se-block-header>
                    </se-block>
                  </se-container>
                </se-block>
              </se-container>
            </se-container>
          ) : null}
          <se-dialog
            id="dialog-simple-notitle"
            color="alternative"
            size="small"
            can-backdrop="false"
          >
            <se-dialog-content>
              <div class="font-size-14 pb-4">
                Do you want to delete {selectedCustomer.customerName}?
              </div>
            </se-dialog-content>
            <se-dialog-footer align="right">
              <se-button onclick="document.getElementById('dialog-simple-notitle').open = false">
                Cancel
              </se-button>
              <se-button onClick={() => CustomerDelete()}>OK</se-button>
            </se-dialog-footer>
          </se-dialog>
          <se-dialog
            id="dialog-load-Popup2"
            color="alternative"
            can-backdrop="false"
            size="small"
          >
            <se-dialog-content>
              <div class="font-size-14 pb-4">
                Do you want to delete {selectedCustomer.customerName}?
              </div>
            </se-dialog-content>
            <se-dialog-footer align="right">
              <se-button onclick="document.getElementById('dialog-load-Popup2').open = false">
                Cancel
              </se-button>
              <se-button onClick={() => CustomerDelete()}>OK</se-button>
            </se-dialog-footer>
          </se-dialog>
          <se-snackbar
            id="mycustomer-snackbar"
            duration="5000"
            type={snackBarObj.type}
            message={snackBarObj.msg}
          ></se-snackbar>
        </se-container>
        {/* : <div className="createNewUserInfo2">
          <div className="createNewUserInfo">
            <div>
              <se-icon color="standard" size="xlarge">user_standard</se-icon>
            </div>
            <div>
              <se-link color="secondary" onClick={() => gotoNewCustomer()} class="pointer" option="internal">Create New Customer Profile</se-link>
            </div>
          </div>

        </div> }*/}
        {/* <div style={{ position: "absolute", left: '15px' }}>
              <se-snackbar id="mycustomer-snackbar" duration="5000" type={snackBarObj.type}
                  message={snackBarObj.msg} ></se-snackbar>
          </div> */}
      </se-container>
    </>
  );
};
export default withRouter(MyCustomers);
