import React from "react";
import { withRouter } from 'react-router-dom';
import "./widget.scss";
import _ from 'lodash';
const Dashboardwidgetinstallaton = (props) => {
    const isMobile = window.innerWidth <= 600;
    const [installation, setInstallation] = React.useState({})
    const [installation1, setInstallation1] = React.useState({})
    React.useEffect(() => {
        
        if (props.installation) {
            setInstallation(props.installation)
            setInstallation1(props.installation1)
        }
        
    }, [props.installation,props.installation1])
    const navigatetoUtilizedDetails = () => {
        let { history } = props;
        history.push({
            pathname: '/installationDetails',
            widgetType: "Installation"
        });
    }
    const AgroList = _.map(installation1.widgetData, (ele, index) => {
        return ele.propName===null?null: <div className="col pl-0 pr-0" key={index} >
            <div class="row align-items-center" style={{lineHeight: '20px'}}>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" style={{textAlign: "center"}}>
                    <span className="dot"></span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 pr-0">
                    <div class="text-align font-size-12 textColor">{ele.propName}</div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 px-0 text-center" style={{display:'inline'}}>
                    <div class="font-size-16 font-weight-400">{ele.propValue}</div>
                   {/* {ele.units? <div class="font-size-12 font-weight-600 text-right mt-2">{ele.units}</div>:null}  */}
                </div>
              
            </div>
        </div>
    })
    const AgrorenderList = _.map(installation.widgetData, (ele, index) => {
        return ele.propName===null ||(sessionStorage.getItem("customerId")==="0" && ele.propName==="Control Panel Status")?null: <se-block-content key={index} class="blockheight">
            <div class="row align-items-center">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <se-icon size="large" color="standard" style={{fontSize:"40px"}}>{ele.propIcon}</se-icon>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 pr-0">
                    <div class="text-align font-size-12 textColor">{ele.propName}</div>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 px-0 text-center" style={{display:'inline',paddingTop:'12px'}}>
                    <div class="font-size-lg">{ele.propValue}</div><div> <span class="font-size-12 font-weight-600" style={{lineHeight:'30px'}}>{ele.units}</span></div>
                    {ele.propName==="Total No. of Assets" && sessionStorage.getItem("customerId")==="0"?<span className="downarrowposition downarrow"> </span>:null}
                   {/* {ele.units? <div class="font-size-12 font-weight-600 text-right mt-2">{ele.units}</div>:null}  */}
                </div>
              
            </div>
        </se-block-content>
    })
    const renderList = _.map(installation.widgetData, (ele, index) => {
        return <div  key={index} class="blockheight">
            { index !== 1 &&
            <div class="row align-items-center">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <se-icon class= "margin-logo-mobile" size="large" color="standard" style={{fontSize:"40px"}}>{ele.propIcon}</se-icon>
                </div>                
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 pr-0">
                    <div class="margin-logo-mobile text-align font-size-12 textColor margin-mobile-spacing">{ele.propName}</div>
                </div>                
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 px-0 text-center" style={{display:'inline',paddingTop:'12px'}}>
                    <div class="font-size-lg">{ele.propValue}</div><div> <span class="font-size-12 font-weight-600" style={{lineHeight:'30px'}}>{ele.units}</span></div>                   
                </div>              
            </div>
            }
        </div>        
    })
    return <se-block margin="none" class="border-bottom-overview installationwidget-height widgetfont-icon">
        {/* <div class="border-bottom-overview bordergreenColor"> */}
        <div className={"rows align-items-center border-bottom-overview"} style={{backgroundColor: '#f7f7f7'}}>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8"><span class="font-size-16">{installation.widgetType} <se-icon class="pointer valign-super margin-left-expand-installation hideinDesktop showinMobile-expand" size="nano" color="#00000029" onClick={() => navigatetoUtilizedDetails()}>presentation_mode</se-icon>
               </span></div>
            <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 textColor font-size-12 my-auto">Lifetime</div>
            <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 my-auto">
                <div class="row">
                    <div class="col-md-12 text-right">
                        <se-icon class="pointer valign-super" size="nano" color="#00000029" onClick={() => navigatetoUtilizedDetails()}>presentation_mode</se-icon>
                    </div>
                </div>
            </div>
            </div>
        {/* </div> */}
        {sessionStorage.getItem("customerType") === "Agro"?AgrorenderList:
        <div className="installationwidget-display-mobile installationwidget-display-tablet" class={isMobile ? 'row' : 'divAlign color-standard'}>{renderList}</div>}
        {sessionStorage.getItem("customerType") === "Agro" && parseInt(sessionStorage.getItem("customerId"))===0?<se-block-content >{AgroList}</se-block-content>:null}

    </se-block>
}
export default withRouter(Dashboardwidgetinstallaton);