import React, { useState, useEffect, useMemo } from 'react';
import Auth from '../api/ApiSession';
import TableComponent from '../components/tableComponent';

const Diagnostics = (props) => {
    const [customer, setCustomer] = React.useState([])
    const [customerCopy, setCustomerData] = useState([]);
    const authCall = new Auth();
    const [totalSites, setTotalCount] = useState(0);
    const [totalActive, setActiveCount] = useState(0);
    const [totalInactive, setInactiveCount] = useState(0);
    const fetchCustomerDetails = () => {
        authCall.getDiagnosticsList(sessionStorage.getItem("customerType"))
            .then(entries => {
                entries = entries.filter((item) => item.installedDate !== null);
                setTotalCount(entries.length);
                setActiveCount(entries.filter(x => x.ActiveStatus === 'Active').length);
                setInactiveCount(entries.filter(x => x.ActiveStatus === 'Inactive').length);
                setCustomer(entries);
                setCustomerData(entries);
            })
            .catch(error => {
                console.log(error);
            });
    };
    const search = (e) => {
        let searchValue = e.target.value;
        searchValue = searchValue.toLowerCase();
        let searchResult = customerCopy.filter(ele => {
            return (ele.SiteName).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.GatewayId).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.ActiveStatus).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.LastPacketUpdated).toLowerCase().indexOf(searchValue) !== -1
        })
        setCustomer(searchResult);
    }

    useEffect(() => {
        fetchCustomerDetails();
    }, []);

  return (
        
    <se-container display="block" padding="large" style={{ overflow: 'auto', maxHeight: '500px' }}>                  
        <div className="col-md-12 px-0">
            <div className="row justify-content-center">
                <div className="col-md-10 col-lg-10 col-xl-10 px-0">
                    <div className='status'>
                        <div>
                            <span className='label'>Total Sites</span>
                            <span className='count'>{totalSites}</span>
                        </div>
                        <div>
                            <span className='label'>Active</span>
                            <span className='count'>{totalActive}</span>
                        </div>
                        <div>
                            <span className='label'>Inactive</span>
                            <span className='count'>{totalInactive}</span>
                        </div>

                    </div>
                    <se-block display="block" color="standard" style={{marginTop:"20px"}}>
                        <div className="col-md-12 pb-3 px-0 my-auto text-right">
                            <input className="mr-2" type="search1" placeholder="Search" onChange={(e) => search(e)}/>
                        </div>
                    </se-block>
                    <se-block display="block" class="px-0">
                        <TableComponent sortable={true} tableHeader={['Site Name','Gateway ID','Status ','Last Packet Updated']} tableData={customer} tableName='DiagnosticsTable' />              
                    </se-block>
                </div>
            </div>
        </div>
    </se-container>
                    

  );
};

export default Diagnostics;