import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Auth from "../../api/ApiSession";
import { CONFIGURATIONS, MOTORCONTROLPARAMS } from "./constants";
import { useSelector } from 'react-redux'

const authCall = new Auth();

const styles = {
  countryStyles: {
    minWidth: "32%",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    maxHeight: "34px",
    lineHeight: "16px",
    fontSize: "12px",
    padding: "0 3%",
  },
  mobileNumberStyles: {
    minWidth: "68%",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    maxHeight: "34px",
    lineHeight: "16px",
    fontSize: "12px",
  },
  selectStyles: {
    minWidth: "100%",
    borderRadius: "3px",
    maxHeight: "34px",
    lineHeight: "16px",
    fontSize: "12px",
    padding: "6px 32px 6px 8px",
  },
  buttonStyles: {
    minWidth: "35%",
    marginLeft: "2%",
  },
  submitStyles: {
    minWidth: "25%",
    marginRight: "10%",
  },
  passwordStyles: { position: "absolute", left: "90%" },
  redBtnEnable: {
    color: "red"
  },
  redBtnDisable: {
    color: "red",
    visibility: "collapse"
  },
  bold: {
    fontWeight: "bold"
  }
};

const CountryNumber = ({ operatorCountryCode, operatorMobileNum, operatorMobileChange, onChange, isMobileInProgress }) => {
  return (
    <>
      <se-form-field label="Operator Mobile No" disabled={isMobileInProgress}>
        <div className="d-flex">
          <PhoneInput international countryCallingCodeEditable={false} defaultCountry="IN" value={operatorCountryCode} name="operatorCountryCode" onChange={operatorMobileChange} placeholder="Mobile Number" title="" readOnly="true" autoComplete="off" />
          <input style={styles.mobileNumberStyles} type="number" value={operatorMobileNum} name="operatorMobileNum" onChange={onChange} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="Mobile Number" />
        </div>
      </se-form-field>
    </>
  );
};

const DeviceSettings = ({DeviceId}) => {
  const userObj = useSelector((state)=> state.user)
  const [deviceData, setDeviceData] = useState({ frequency: "", controlType: "", nominalMotorPower: "", nominalMotorVoltage: "", nominalMotorCurrent: "", nominalMotorFrequency: "", nominalMotorSpeed: "", MotorParameterChoice: "", nominalMotorCos: "", automaticTuning: "", tuningStatus: "", tuningSelection: "", operatorCountryCode: "", operatorMobileNum: "", adminCountryCode: "", adminMobileNum: "", password: "", gprs: "" });
  const [deviceSettings, setDeviceSettings] = useState({ frequency: "", controlType: "", nominalMotorPower: "", nominalMotorVoltage: "", nominalMotorCurrent: "", nominalMotorFrequency: "", nominalMotorSpeed: "", MotorParameterChoice: "", nominalMotorCos: "", automaticTuning: "", tuningStatus: "", tuningSelection: "", operatorCountryCode: "", operatorMobileNum: "", adminCountryCode: "", adminMobileNum: "", password: "", gprs: "" });
  const [validUserPhNum, setValidUserPhNum] = useState("");
  const [validAdminPhNum, setAdminValidPhNum] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isControlTypeChanged, setIsControlTypeChanged] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isEnable, setEnable] = useState(false);
  const [snackBarObj, setSnackBarObj] = React.useState({});
  const [isMobileInProgress, setMobileInProgress] = useState(false);
  const [isAdminMobileInProgress, setAdminMobileInProgress] = useState(false);
  const [isPasswordInProgress, setIsPasswordInProgress] = useState(false);
  const [isMCTInProgress, setMCTInProgress] = useState(false);
  const [showProgressMsg, setShowProgressMsg] = useState(false);
  const [isFreqValid, setIsFreqValid] = useState(true);
  const [isFrequencyInProgress, setIsFrequencyInProgress] = useState(false);
  const [isFrequencyDisable, setIsFrequencyDisable] = useState(false);
  const [isMCTDisable, setIsMCTDisable] = useState(false);

  const [isValidForm, setIsValidForm] = useState(false);
  const [isNominalMotorPowerValid, setIsNominalMotorPowerValid] = useState(true);
  const [isNominalMotorCosValid, setIsNominalMotorCosValid] = useState(true);
  const [isNominalMotorVoltageValid, setIsNominalMotorVoltageValid] = useState(true);
  const [isNominalMotorVoltageInProgress, setIsNominalMotorVoltageInProgress] = useState(false);
  const [isNominalMotorCurrentValid, setIsNominalMotorCurrentValid] = useState(true);
  const [isNominalMotorCurrentInProgress, setIsNominalMotorCurrentInProgress] = useState(false);
  const [isNominalMotorFrequencyValid, setIsNominalMotorFrequencyValid] = useState(true);
  const [isNominalMotorFrequencyInProgress, setIsNominalMotorFrequencyInProgress] = useState(false);
  const [isNominalMotorSpeedValid, setIsNominalMotorSpeedValid] = useState(true);
  const [isNominalMotorSpeedInProgress, setIsNominalMotorSpeedInProgress] = useState(false);
  const [isNamePlateInProgress, setIsNamePlateInProgress] = useState(false);
  const [isGPRSValid, setIsGPRSValid] = useState(true);

  const [isDryRunDetectDelayValid, setIsDryRunDetectDelayValid] = useState(true);
  const [isDryRunDetectDelayInProgress, setIsDryRunDetectDelayInProgress] = useState(false);
  const [isDryRunUpperThresholdValid, setIsDryRunUpperThresholdValid] = useState(true);
  const [isDryRunUpperThresholdInProgress, setIsDryRunUpperThresholdInProgress] = useState(false);
  const [isDryRunLowerThresholdValid, setIsDryRunLowerThresholdValid] = useState(true);
  const [isDryRunLowerThresholdInProgress, setIsDryRunLowerThresholdInProgress] = useState(false);
  const [isDryRunFreqThresholdValid, setIsDryRunFreqThresholdValid] = useState(true);
  const [isDryRunFreqThresholdInProgress, setIsDryRunFreqThresholdInProgress] = useState(false);
  const [isGPRSInProgress, setIsGPRSInProgress] = useState(false);
  const [isShowNPR, setIsShowNPR] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [isDryRunInProgress, setIsDryRunInProgress] = useState(false);
  
  const validateSubmit = () => {
    let res = true;
    const pattern = /^\d{8,15}$/;

    if (!(deviceSettings.frequency >= 0 && deviceSettings.frequency <= 100)) {
      res = false;
    }
    else if (!(deviceSettings.nominalMotorVoltage >= 100 && deviceSettings.nominalMotorVoltage <= 480)) {
      res = false;
    }
    else if (!(deviceSettings.nominalMotorCurrent >= 0.2 && deviceSettings.nominalMotorCurrent <= 99)) {
      res = false;
    }
    else if (!(deviceSettings.nominalMotorFrequency >= 45 && deviceSettings.nominalMotorFrequency <= 65)) {
      res = false;
    }
    else if (!(deviceSettings.nominalMotorSpeed >= 500 && deviceSettings.nominalMotorSpeed <= 5000)) {
      res = false;
    }
    else if (!(deviceSettings.DryRunDetectDelay >= 0 && deviceSettings.DryRunDetectDelay <= 100) || deviceSettings.DryRunDetectDelay === "") {
      res = false;
    }
    else if (!(deviceSettings.DryRunUpperThreshold >= 20 && deviceSettings.DryRunUpperThreshold <= 100)) {
      res = false;
    }
    else if (!(deviceSettings.DryRunLowerThreshold >= 0 && deviceSettings.DryRunLowerThreshold <= 100) || deviceSettings.DryRunLowerThreshold === "") {
      res = false;
    }
    else if (!(deviceSettings.DryRunFreqThreshold >= 0 && deviceSettings.DryRunFreqThreshold <= 599) || deviceSettings.DryRunFreqThreshold === "") {
      res = false;
    }
    else if (deviceSettings.gprs === '' || !(deviceSettings.gprs >= 1 && deviceSettings.gprs <= 60)) {
      res = false;
    }       
    else if (!(deviceSettings.operatorMobileNum.match(pattern))){
      res = false;
    }
    else if (!(deviceSettings.adminMobileNum.match(pattern))){
      res = false;
    }    
    if (deviceSettings.password === '' || deviceSettings.password.length < 5) {
      res = false;
    } 
    if (isShowNPR) {
      if (!(deviceSettings.nominalMotorPower >= 0.09 && deviceSettings.nominalMotorPower <= 18)) {
        res = false;
      }
    } else {
      if (!(deviceSettings.nominalMotorCos >= 0.5 && deviceSettings.nominalMotorCos <= 1)) {
        res = false;
      }
    }

    if (deviceData.Frequency === deviceSettings.frequency
      && deviceData.motorControlType === deviceSettings.controlType
      && deviceData.MotorParameterChoice === deviceSettings.MotorParameterChoice
      && deviceData.nominalMotorPower / 100 === deviceSettings.nominalMotorPower
      && deviceData.nominalMotorVoltage === deviceSettings.nominalMotorVoltage
      && deviceData.nominalMotorCurrent / 10 === deviceSettings.nominalMotorCurrent
      && deviceData.nominalMotorFrequency / 10 === deviceSettings.nominalMotorFrequency
      && deviceData.nominalMotorSpeed === deviceSettings.nominalMotorSpeed
      && deviceData.nominalMotorCos / 100 === deviceSettings.nominalMotorCos
      && deviceData.UserMobileNo === `${deviceSettings.operatorCountryCode} ${deviceSettings.operatorMobileNum}`
      && deviceData.AdminMobileNo === `${deviceSettings.adminCountryCode} ${deviceSettings.adminMobileNum}`
      && deviceData.Password === deviceSettings.password
      && deviceData.DryRunDetectDelay === deviceSettings.DryRunDetectDelay
      && deviceData.DryRunUpperThreshold === deviceSettings.DryRunUpperThreshold
      && deviceData.DryRunLowerThreshold === deviceSettings.DryRunLowerThreshold
      && deviceData.DryRunFreqThreshold / 10 === deviceSettings.DryRunFreqThreshold
      && (deviceData.gprs / (1000 * 60)).toString().trim() === deviceSettings.gprs.toString().trim()) {
      res = false;
    }

    return res;
  };

  const getLatestPacket = () => {
    //set state to show in progress loader
    setShowProgressMsg(true);
    var transactionID = 0;
    //Push command to gateway 
    authCall
      .putUpdateConfigurationsReset(CONFIGURATIONS.Reset, {
        DeviceId: DeviceId, TopicName: "Get", ResetCommand: "Get", ResetType: "True", customerId: sessionStorage.getItem("customerId")
      })
      .then((response) => {
        transactionID = response;
      })
      .catch((error) => {
        console.log(error);
      });

    let job = setInterval(function () {
      authCall
        .getLatestPacket(CONFIGURATIONS.GetLatestPacket, { id: transactionID, DeviceId: DeviceId, customerId: sessionStorage.getItem("customerId") })
        .then((response) => {          
          if (response[0].response === undefined) {
            setDeviceData({
              ...response[0],
              DeviceId,
            });
            setDeviceSettings({
              ...deviceSettings,
              frequency: response[0].Frequency,
              controlType: response[0].motorControlType,
              nominalMotorPower: response[0].nominalMotorPower / 100,
              nominalMotorVoltage: response[0].nominalMotorVoltage,
              nominalMotorCurrent: response[0].nominalMotorCurrent / 10,
              nominalMotorFrequency: response[0].nominalMotorFrequency / 10,
              nominalMotorSpeed: response[0].nominalMotorSpeed,
              MotorParameterChoice: response[0].MotorParameterChoice,
              nominalMotorCos: response[0].nominalMotorCos / 100,
              automaticTuning: response[0].automaticTuning,
              tuningStatus: response[0].tuningStatus,
              tuningSelection: response[0].tuningSelection,
              operatorCountryCode: getMobileNoOrCode(response[0].UserMobileNo, 'Code'),
              operatorMobileNum: getMobileNoOrCode(response[0].UserMobileNo, 'Mobile'),
              adminCountryCode: getMobileNoOrCode(response[0].AdminMobileNo, 'Code'),
              adminMobileNum: getMobileNoOrCode(response[0].AdminMobileNo, 'Mobile'),
              password: response[0].Password,
              DryRunDetectDelay: response[0].DryRunDetectDelay,
              DryRunUpperThreshold: response[0].DryRunUpperThreshold,
              DryRunLowerThreshold: response[0].DryRunLowerThreshold,
              DryRunFreqThreshold: response[0].DryRunFreqThreshold / 10,
              gprs: response[0].gprs / (1000 * 60),
              DeviceId
            });

            InitialValidation(response[0]);

            clearInterval(job);
            clearInterval(job1);
            //clear state to hide in progress loader
            setShowProgressMsg(false);
            alert('Get Data was Successfull!', "success");
          }else if(response[0].response === 'FailedResponse'){
            clearInterval(job);
            clearInterval(job1);
            //clear state to hide in progress loader
            setShowProgressMsg(false);
            alert('Get Data was Failed!', "error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 2000);

    let job1 =setTimeout(function () {
      alert('Device Not Responded. Request Failed!', "error");
      setShowProgressMsg(false);
      clearInterval(job);
    }, 30000);
  }

  const fetchDeviceData = (DeviceId) => {
    authCall
      .getConfigurationData(CONFIGURATIONS.GetData, { DeviceId: DeviceId, customerId: sessionStorage.getItem("customerId") })
      .then((response) => {
        if (response.length > 0) {
          setDeviceData({
            ...response[0],
            DeviceId,
          });
          setDeviceSettings({
            ...deviceSettings,
            frequency: response[0].Frequency,
            controlType: response[0].motorControlType,
            nominalMotorPower: response[0].nominalMotorPower / 100,
            nominalMotorVoltage: response[0].nominalMotorVoltage,
            nominalMotorCurrent: response[0].nominalMotorCurrent / 10,
            nominalMotorFrequency: response[0].nominalMotorFrequency / 10,
            nominalMotorSpeed: response[0].nominalMotorSpeed,
            MotorParameterChoice: response[0].MotorParameterChoice,
            nominalMotorCos: response[0].nominalMotorCos / 100,
            automaticTuning: response[0].automaticTuning,
            tuningStatus: response[0].tuningStatus,
            tuningSelection: response[0].tuningSelection,
            operatorCountryCode: getMobileNoOrCode(response[0].UserMobileNo, 'Code'),
            operatorMobileNum: getMobileNoOrCode(response[0].UserMobileNo, 'Mobile'),
            adminCountryCode: getMobileNoOrCode(response[0].AdminMobileNo, 'Code'),
            adminMobileNum: getMobileNoOrCode(response[0].AdminMobileNo, 'Mobile'),
            password: response[0].Password,
            DryRunDetectDelay: response[0].DryRunDetectDelay,
            DryRunUpperThreshold: response[0].DryRunUpperThreshold,
            DryRunLowerThreshold: response[0].DryRunLowerThreshold,
            DryRunFreqThreshold: response[0].DryRunFreqThreshold / 10,
            gprs: response[0].gprs / (1000 * 60),
            DeviceId
          });

          InitialValidation(response[0]);
          DefaultDisable(response[0]);
          getTransaction();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Initial Page refresh
  useEffect(() => {
    fetchDeviceData(DeviceId);
  }, []);

  const DefaultDisable = (deviceData) => {
    if (deviceData.Status === "Local") {
      setIsFrequencyDisable(true);
      if (deviceData.RemoteStartStop) {
        setIsMCTDisable(true);
      } else {
        setIsMCTDisable(false);
      }
    } else {
      if (deviceData.RemoteStartStop) {
        setIsFrequencyDisable(false); setIsMCTDisable(true);
      } else {
        setIsFrequencyDisable(false); setIsMCTDisable(false);
      }
    }

    if(deviceData.MotorParameterChoice === 1){
      setIsShowNPR(false);
    }
    else {
      setIsShowNPR(true);
    }
  }

  const InitialValidation = (deviceData) => {
    setIsValidForm(true);
    if(!(deviceData.Frequency >= 1 && deviceData.Frequency <= 100)){
      setIsFreqValid(false); setIsValidForm(false);
    }else{
      setIsFreqValid(true);
    }
    
    if (deviceData.MotorParameterChoice === 0) {
      if (!(deviceData.nominalMotorPower / 100 >= 0.09 && deviceData.nominalMotorPower / 100 <= 18)) {
        setIsNominalMotorPowerValid(false); setIsValidForm(false);
      }else{
        setIsNominalMotorPowerValid(true);
      }
    } else {
      if (!(deviceData.nominalMotorCos / 100 >= 0.5 && deviceData.nominalMotorCos / 100 <= 1)) {
        setIsNominalMotorCosValid(false); setIsValidForm(false);
      }else{
        setIsNominalMotorCosValid(true);
      }
    }

    if (!(deviceData.nominalMotorVoltage >= 100 && deviceData.nominalMotorVoltage <= 480)) {
      setIsNominalMotorVoltageValid(false); setIsValidForm(false);
    }{
      setIsNominalMotorVoltageValid(true);
    }
    if (!(deviceData.nominalMotorCurrent / 10 >= 0.2 && deviceData.nominalMotorCurrent / 10 <= 99)) {
      setIsNominalMotorCurrentValid(false); setIsValidForm(false);
    }else{
      setIsNominalMotorCurrentValid(true);
    }
    if (!(deviceData.nominalMotorFrequency / 10 >= 45 && deviceData.nominalMotorFrequency / 10 <= 65)) {
      setIsNominalMotorFrequencyValid(false); setIsValidForm(false);
    }else{
      setIsNominalMotorFrequencyValid(true);
    }
    if (!(deviceData.nominalMotorSpeed >= 500 && deviceData.nominalMotorSpeed <= 5000)) {
      setIsNominalMotorSpeedValid(false); setIsValidForm(false);
    }else{
      setIsNominalMotorSpeedValid(true);
    }
    if (!(deviceData.DryRunDetectDelay >= 0 && deviceData.DryRunDetectDelay <= 100)) {
      setIsDryRunDetectDelayValid(false); setIsValidForm(false);
    }else{
      setIsDryRunDetectDelayValid(true);
    }
    if (!(deviceData.DryRunUpperThreshold >= 20 && deviceData.DryRunUpperThreshold <= 100)) {
      setIsDryRunUpperThresholdValid(false); setIsValidForm(false);
    }else{
      setIsDryRunUpperThresholdValid(true);
    }
    if (!(deviceData.DryRunLowerThreshold >= 0 && deviceData.DryRunLowerThreshold <= 100)) {
      setIsDryRunLowerThresholdValid(false); setIsValidForm(false);
    }else{
      setIsDryRunLowerThresholdValid(true);
    }
    if (!(deviceData.DryRunFreqThreshold / 10 >= 0 && deviceData.DryRunFreqThreshold / 10 <= 599)) {
      setIsDryRunFreqThresholdValid(false); setIsValidForm(false);
    }else{
      setIsDryRunFreqThresholdValid(true);
    }
    if (deviceData.password === '' ) {
      setIsPasswordValid(false); setIsValidForm(false);
    }else{
      setIsPasswordValid(true);
    }
    
    const pattern = /^\d{8,15}$/;
    let operatorMobile = getMobileNoOrCode(deviceData.UserMobileNo, 'Mobile');
    if (operatorMobile.match(pattern)){
      setValidUserPhNum("");
    }else{
      setValidUserPhNum("false");setIsValidForm(false);
    }
    let adminMobile = getMobileNoOrCode(deviceData.AdminMobileNo, 'Mobile');
    if (adminMobile.match(pattern)){
      setAdminValidPhNum("");
    }else{
      setAdminValidPhNum("false");setIsValidForm(false);
    }
    if (!(deviceData.gprs / (1000 * 60) > 0 && deviceData.gprs / (1000 * 60) <= 60)) {
      setIsGPRSValid(false); setIsValidForm(false);
    }else{
      setIsGPRSValid(true);
    }

    if (!isValidForm) {
      return;
    }
  }

  const getTransaction = () => {
    authCall.getTransactionData(CONFIGURATIONS.GetTransaction, sessionStorage.getItem("customerId"), 'Wait,InProgress').then(json => {
      if (json.length > 0) {
        setToggle(true);

        var nameplateArray = json.filter(function (cmd) { return cmd.commandName === 'MotorNamePlate'; });
        var frequencyArray = json.filter(function (cmd) { return cmd.commandName === 'Frequency'; });
        var mctArray = json.filter(function (cmd) { return cmd.commandName === 'MotorControl'; });
        var userMobArray = json.filter(function (cmd) { return cmd.commandName === 'MobNumber'; });
        var adminMobArray = json.filter(function (cmd) { return cmd.commandName === 'AdminMobNumber'; });
        var passwordArray = json.filter(function (cmd) { return cmd.commandName === 'SetPassword'; });
        var nominalMotorCos = json.filter(function (cmd) { return cmd.commandName === 'nominalMotorCos'; });
        var nominalMotorVoltage = json.filter(function (cmd) { return cmd.commandName === 'nominalMotorVoltage'; });
        var nominalMotorCurrent = json.filter(function (cmd) { return cmd.commandName === 'nominalMotorCurrent'; });
        var nominalMotorFrequency = json.filter(function (cmd) { return cmd.commandName === 'nominalMotorFrequency'; });
        var nominalMotorSpeed = json.filter(function (cmd) { return cmd.commandName === 'nominalMotorSpeed'; });
        var gprs = json.filter(function (cmd) { return cmd.commandName === 'PostInterval'; });
        var dryrunArray = json.filter(function (cmd) { return cmd.commandName === 'DryRun'; });        

        if (frequencyArray.length > 0) { setIsFrequencyInProgress(true); }
        if (mctArray.length > 0) { setMCTInProgress(true); }
        if (userMobArray.length > 0) { setMobileInProgress(true); }
        if (adminMobArray.length > 0) { setAdminMobileInProgress(true); }
        if (passwordArray.length > 0) { setIsPasswordInProgress(true); }
        if (nominalMotorVoltage.length > 0) { setIsNominalMotorVoltageInProgress(true); }
        if (nominalMotorCurrent.length > 0) { setIsNominalMotorCurrentInProgress(true); }
        if (nominalMotorFrequency.length > 0) { setIsNominalMotorFrequencyInProgress(true); }        
        if (gprs.length > 0) { setIsGPRSInProgress(true); }
        if (nameplateArray.length > 0) { setIsNamePlateInProgress(true); }
        if (dryrunArray.length > 0) { setIsDryRunInProgress(true); }        
      }
    })
  };

  function toggleField(control) {
    if ((deviceData.Status === 'Local' && control === true) || (deviceData.Status === 'Local' && control === false) || (deviceData.Status === 'Remote' && control === true)) {
      setEnable(true);
    }
    else if (deviceData.Status === 'Remote' && control === false) {
      setEnable(false);
    }
  }

  const handleFrequencyChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 1 && value <= 100) {
      setIsFreqValid(true);
    } else {
      setIsFreqValid(false);
    }
    setDeviceSettings({ ...deviceSettings, frequency: result });
  };

  const handleNominalMotorPowerChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 0.09 && value <= 18) {
      setIsNominalMotorPowerValid(true);
    } else {
      setIsNominalMotorPowerValid(false);
    }
    setDeviceSettings({ ...deviceSettings, nominalMotorPower: result });
  };

  const handleNominalMotorVoltageChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 100 && value <= 480) {
      setIsNominalMotorVoltageValid(true);
    } else {
      setIsNominalMotorVoltageValid(false);
    }
    setDeviceSettings({ ...deviceSettings, nominalMotorVoltage: result });
  };

  const handleNominalMotorCurrentChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 0.2 && value <= 99) {
      setIsNominalMotorCurrentValid(true);
    } else {
      setIsNominalMotorCurrentValid(false);
    }
    setDeviceSettings({ ...deviceSettings, nominalMotorCurrent: result });
  };

  const handleNominalMotorFrequencyChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 45 && value <= 65) {
      setIsNominalMotorFrequencyValid(true);
    } else {
      setIsNominalMotorFrequencyValid(false);
    }
    setDeviceSettings({ ...deviceSettings, nominalMotorFrequency: result });
  };

  const handleNominalMotorSpeedChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 500 && value <= 5000) {
      setIsNominalMotorSpeedValid(true);
    } else {
      setIsNominalMotorSpeedValid(false);
    }
    setDeviceSettings({ ...deviceSettings, nominalMotorSpeed: result });
  };

  const handleDryRunDetectDelayChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 0 && value <= 100 && value != "") {
      setIsDryRunDetectDelayValid(true);
    } else {
      setIsDryRunDetectDelayValid(false);
    }
    setDeviceSettings({ ...deviceSettings, DryRunDetectDelay: result });
  };

  const handleDryRunUpperThresholdChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 20 && value <= 100) {
      setIsDryRunUpperThresholdValid(true);
    } else {
      setIsDryRunUpperThresholdValid(false);
    }
    setDeviceSettings({ ...deviceSettings, DryRunUpperThreshold: result });
  };

  const handleDryRunLowerThresholdChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 0 && value <= 100 && value != "") {
      setIsDryRunLowerThresholdValid(true);
    } else {
      setIsDryRunLowerThresholdValid(false);
    }
    setDeviceSettings({ ...deviceSettings, DryRunLowerThreshold: result });
  };

  const handleDryRunFreqThresholdChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 0 && value <= 599 && value != "") {
      setIsDryRunFreqThresholdValid(true);
    } else {
      setIsDryRunFreqThresholdValid(false);
    }
    setDeviceSettings({ ...deviceSettings, DryRunFreqThreshold: result });
  };

  const handleNominalMotorCosChange = ({ target: { value } }) => {
    const result = value;
    if (value >= 0.5 && value <= 1) {
      setIsNominalMotorCosValid(true);
    } else {
      setIsNominalMotorCosValid(false);
    }
    setDeviceSettings({ ...deviceSettings, nominalMotorCos: result });
  };

  const handleGPRS = ({ target: { value } }) => {
    const result = value;
    if (value > 0 && value <= 60) {
      setIsGPRSValid(true);
    } else {
      setIsGPRSValid(false);
    }
    setDeviceSettings({ ...deviceSettings, gprs: result });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const getMobileNoOrCode = (data, type) => {
    if (data != undefined) {
      var mobArray = data.split(' ');
      return type == 'Code' ? mobArray[0] : mobArray[1];
    }
  }

  const handleSubmit = () => {    
    let data = [];
    if (deviceData.Frequency != deviceSettings.frequency) {
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "Frequency", commandType: "Frequency", commandStatus: "Wait", commandValue: deviceSettings.frequency * 10 });
    }
    if (deviceData.motorControlType != deviceSettings.controlType) {
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "MotorControl", commandType: "MotorControl", commandStatus: "Wait", commandValue: deviceSettings.controlType });
    }
    if (deviceData.UserMobileNo != `${deviceSettings.operatorCountryCode} ${deviceSettings.operatorMobileNum}`) {
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "MobNumber", commandType: "MobNumber", commandStatus: "Wait", commandValue: `${deviceSettings.operatorCountryCode} ${deviceSettings.operatorMobileNum}` });
    }
    if (deviceData.AdminMobileNo != `${deviceSettings.adminCountryCode} ${deviceSettings.adminMobileNum}`) {
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "AdminMobNumber", commandType: "AdminMobileNo", commandStatus: "Wait", commandValue: `${deviceSettings.adminCountryCode} ${deviceSettings.adminMobileNum}` });
    }
    if (deviceData.Password != deviceSettings.password) {
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "SetPassword", commandType: `${deviceSettings.operatorCountryCode} ${deviceSettings.operatorMobileNum}`, commandStatus: "Wait", commandValue: `${deviceSettings.operatorCountryCode} ${deviceSettings.operatorMobileNum}` + "," + deviceSettings.password });
    }
    if (deviceData.gprs / (1000 * 60) != deviceSettings.gprs) {
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "PostInterval", commandType: "PostInterval", commandStatus: "Wait", commandValue: deviceSettings.gprs * 60 * 1000 });
    }
    if (deviceData.nominalMotorPower / 100 != deviceSettings.nominalMotorPower || deviceData.nominalMotorVoltage != deviceSettings.nominalMotorVoltage || deviceData.nominalMotorCurrent / 10 != deviceSettings.nominalMotorCurrent || deviceData.nominalMotorFrequency / 10 != deviceSettings.nominalMotorFrequency || deviceData.nominalMotorSpeed != deviceSettings.nominalMotorSpeed || deviceData.MotorParameterChoice != deviceSettings.MotorParameterChoice || deviceData.nominalMotorCos / 100 != deviceSettings.nominalMotorCos) {
      var jsonData = { NPR: (deviceSettings.nominalMotorPower * 100).toFixed(), UNS: deviceSettings.nominalMotorVoltage, NCR: deviceSettings.nominalMotorCurrent * 10, FRS: deviceSettings.nominalMotorFrequency * 10, NSP: deviceSettings.nominalMotorSpeed, MPC: deviceSettings.MotorParameterChoice, COS: deviceSettings.nominalMotorCos * 100, TUN: "1" };
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "MotorNamePlate", commandType: "MotorNamePlate", commandStatus: "Wait", commandValue: JSON.stringify(jsonData) });
      setIsNamePlateInProgress(true);
    }
    if (deviceData.DryRunDetectDelay != deviceSettings.DryRunDetectDelay || deviceData.DryRunUpperThreshold != deviceSettings.DryRunUpperThreshold || deviceData.DryRunLowerThreshold != deviceSettings.DryRunLowerThreshold || deviceData.DryRunFreqThreshold / 10 != deviceSettings.DryRunFreqThreshold) {
      var jsonData = { ULT: deviceSettings.DryRunDetectDelay, LUN: deviceSettings.DryRunUpperThreshold, LUL: deviceSettings.DryRunLowerThreshold, RMUD: deviceSettings.DryRunFreqThreshold * 10 };
      data.push({ cust_id: sessionStorage.getItem("customerId"), deviceId: DeviceId, isProcessed: 0, commandName: "DryRun", commandType: "DryRun", commandStatus: "Wait", commandValue: JSON.stringify(jsonData) });
      setIsDryRunInProgress(true);
    }

    authCall
      .postTransaction(data)
      .then((response) => {
        alert("Command Initiated successfully.", "success");
        getTransaction();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateCountryCode = (code) => {
    const pattern = /^\+\d{1,5}$/;
    if (code.match(pattern)) {
      setValidUserPhNum("");
    } else {
      setValidUserPhNum("Please Check Country Code");
    }
  };

  const validatePhoneNumber = (mobile, type) => {
    const pattern = /^\d{8,15}$/;
    if (mobile.match(pattern)) {
      type === 'operatorMobileNum' ? setValidUserPhNum("") : setAdminValidPhNum("");
    } else {
      type === 'operatorMobileNum' ? setValidUserPhNum("please check phone number") : setAdminValidPhNum("please check phone number");
    }
  };

  const handleChange = (e) => {
    var val = e.target.value;
    setDeviceSettings({
      ...deviceSettings,
      [e.target.name]: val,
    });
    e.target.name === "controlType" && setIsControlTypeChanged(true);
    document.getElementById("MotorParameterChoice").value === '0' ? setIsShowNPR(true) : setIsShowNPR(false);
    if (e.target.name === "operatorMobileNum") {
      validatePhoneNumber(val, 'operatorMobileNum');
    } else if (e.target.name === "operatorCountryCode") {
      validateCountryCode(val);
    } else if (e.target.name === "adminMobileNum") {
      validatePhoneNumber(val, 'adminMobileNum');
    } else if (e.target.name === "adminCountryCode") {
      validateCountryCode(val);
    }
  };

  const adminMobileChange = (e) => {
    setDeviceSettings({ ...deviceSettings, ["adminCountryCode"]: e });
  }

  const operatorMobileChange = (e) => {
    setDeviceSettings({ ...deviceSettings, ["operatorCountryCode"]: e });
  }

  const validatePassword = (password) => {
    const pattern = /^\d{5,}$/;
    if (password.match(pattern)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const handlePasswordChange = (e) => {
    const val = e.target.value.replace(/\D/g, '');
    setDeviceSettings({
      ...deviceSettings,
      [e.target.name]: val
    });

    if (e.target.name === "password") {
      validatePassword(val);
    }
  };

  const alert = (msgTxt, type) => {
    document.getElementById('deviceSettings-snackbar').open = true;
    setSnackBarObj({ type: type, msg: msgTxt });
  };

  return (
    <>
      <se-block-content>
        <se-container className="d-flex flex-column flex-wrap justify-content-start" class="containerStyles">
          {showProgressMsg ? <div id="wait" className='wait'><h5> Your request is in progress. Please Wait<div class="loading">...</div></h5></div> : null}
          <div className="col-md-9 px-0" style={{ marginBottom: '40px' }}>
            <div className="createBlockSize text-right">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 text-left"><div style={styles.bold}>Basic Motor Details</div></div>
              <div className="col-md-6 text-left">
                <se-button size="small" option="flat" color="primary" style={styles.submitStyles} onClick={() => getLatestPacket()}>{CONFIGURATIONS.Get}</se-button>
                <se-button size="small" option="flat" color="primary" style={styles.submitStyles} disabled={!validateSubmit() || toggle} onClick={() => handleSubmit()}>{CONFIGURATIONS.Set}</se-button>
              </div>
            </div>
            <hr />

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.Drive_Frequency} disabled={isFrequencyInProgress || isFrequencyDisable} >
                  <input type="number" value={deviceSettings.frequency} onChange={handleFrequencyChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="1 to 100" min="1" max="100" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                {isFrequencyDisable === true ? '' :
                  (<p style={(isFreqValid === false || isFrequencyInProgress === true) ? styles.redBtnEnable : styles.redBtnDisable}>{isFrequencyInProgress === true ? 'Frequency InProgress' : 'Range is between 1 to 100'}</p>)}
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.Motor_Control_Type}>
                  <select style={styles.selectStyles} disabled={isNamePlateInProgress || isMCTDisable} value={deviceSettings.controlType} onChange={handleChange} name="controlType">
                    <option value={MOTORCONTROLPARAMS.SensorlessFluxVectorV}>{CONFIGURATIONS.Sensorless_Flux}</option>
                    <option value={MOTORCONTROLPARAMS.StandardMotorLaw}>{CONFIGURATIONS.Standard_MotorLaw}</option>
                    <option value={MOTORCONTROLPARAMS.FivePointVoltageFrequencey}>{CONFIGURATIONS.FivePoint_VoltageFrequencey}</option>
                    <option value={MOTORCONTROLPARAMS.SynchronousMotor}>{CONFIGURATIONS.Synchronous_Motor}</option>
                    <option value={MOTORCONTROLPARAMS.VFQuadratic}>{CONFIGURATIONS.VF_Quadratic}</option>
                    <option value={MOTORCONTROLPARAMS.EnergySaving}>{CONFIGURATIONS.Energy_Saving}</option>
                  </select>
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isMCTInProgress == true || isMCTDisable == true ? styles.redBtnEnable : styles.redBtnDisable}>{isMCTDisable === true ? '' : CONFIGURATIONS.MCT_Inprogress_Msg}</p></div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.MotorParameterChoice} disabled={isNamePlateInProgress || isMCTDisable}>
                  <select id="MotorParameterChoice" style={styles.selectStyles} value={deviceSettings.MotorParameterChoice} onChange={handleChange} name="MotorParameterChoice">
                    <option value="0">NPR</option>
                    <option value="1">COS</option>
                  </select>
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isNamePlateInProgress == true ? styles.redBtnEnable : styles.redBtnDisable}>{CONFIGURATIONS.MotorParameterChoice} Transaction InProgress </p></div>
            </div>

            {isShowNPR ?
              <div className="createBlockSize text-right pb-3 trStyles">
                <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                  <se-form-field label={CONFIGURATIONS.Nominal_Motor_Power} disabled={isNamePlateInProgress || isMCTDisable}>
                    <input type="number" value={deviceSettings.nominalMotorPower} name="nominalMotorPower" onChange={handleNominalMotorPowerChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0.09 to 18" min="0.09" max="18" title="" />
                  </se-form-field>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                  <p style={isNominalMotorPowerValid === false || isNamePlateInProgress === true ? styles.redBtnEnable : styles.redBtnDisable}>{isNamePlateInProgress === true ? ' Transaction InProgress' : ' Range is between 0.09 to 18'}</p>
                </div>
              </div> :
              <div className="createBlockSize text-right pb-3 trStyles">
                <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                  <se-form-field label={CONFIGURATIONS.NominalMotorCos} disabled={isNamePlateInProgress || isMCTDisable}>
                    <input type="number" value={deviceSettings.nominalMotorCos} name="nominalMotorCos" onChange={handleNominalMotorCosChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0.5 to 1" min="0.5" max="1" />
                  </se-form-field>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                  <p style={isNominalMotorCosValid === false || isNamePlateInProgress == true ? styles.redBtnEnable : styles.redBtnDisable}>{isNamePlateInProgress == true ? ' Transaction InProgress' : ' Range is between 0.5 to 1'}</p></div>
              </div>
            }

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.Nominal_Motor_Voltage} disabled={isNamePlateInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.nominalMotorVoltage} name="nominalMotorVoltage" onChange={handleNominalMotorVoltageChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="100 to 480" min="100" max="480" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isNominalMotorVoltageValid === false || isNamePlateInProgress === true ? styles.redBtnEnable : styles.redBtnDisable}>{isNamePlateInProgress === true ? ' Transaction InProgress' : ' Range is between 100 to 480'}</p></div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.Nominal_Motor_Current} disabled={isNamePlateInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.nominalMotorCurrent} name="nominalMotorCurrent" onChange={handleNominalMotorCurrentChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0.2 to 99" min="0.2" max="99" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isNominalMotorCurrentValid == false || isNamePlateInProgress === true ? styles.redBtnEnable : styles.redBtnDisable}>{isNamePlateInProgress === true ? ' Transaction InProgress' : ' Range is between 0.2 to 99'}</p></div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.Nominal_Motor_Frequency} disabled={isNamePlateInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.nominalMotorFrequency} name="nominalMotorFrequency" onChange={handleNominalMotorFrequencyChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="45 to 65" min="45" max="65" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isNominalMotorFrequencyValid == false || isNamePlateInProgress === true ? styles.redBtnEnable : styles.redBtnDisable}>{isNamePlateInProgress === true ? ' Transaction InProgress' : ' Range is between 45 to 65'}</p></div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.Nominal_Motor_Speed} disabled={isNamePlateInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.nominalMotorSpeed} name="nominalMotorSpeed" onChange={handleNominalMotorSpeedChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="500 to 5000" min="500" max="5000" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isNominalMotorSpeedValid == false || isNamePlateInProgress === true ? styles.redBtnEnable : styles.redBtnDisable}>{isNamePlateInProgress === true ? ' Transaction InProgress' : ' Range is between 500 to 5000'}</p></div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles margin-top-tuning">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.TuningStatus} disabled="disabled">
                  <input type="text" value={deviceSettings.tuningStatus} name="tuningStatus" onChange={handleChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 4" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg"></div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.TuningSelection} disabled="disabled">
                  <input type="text" value={deviceSettings.tuningSelection} name="tuningSelection" onChange={handleChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 2" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg"></div>
            </div>
            <div>&nbsp;</div>
            <div style={styles.bold}>Mobile no & Password</div><hr />

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <CountryNumber operatorCountryCode={deviceSettings.operatorCountryCode} operatorMobileNum={deviceSettings.operatorMobileNum} operatorMobileChange={operatorMobileChange} onChange={handleChange} isMobileInProgress={isMobileInProgress} />
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isMobileInProgress === true || validUserPhNum !== "" ? styles.redBtnEnable : styles.redBtnDisable}>{isMobileInProgress === true ? 'Transaction InProgress' : 'Length is between 8 to 15'}</p>
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label="Admin Mobile No" disabled={isAdminMobileInProgress} >
                  <div className="d-flex">
                    <PhoneInput international countryCallingCodeEditable={false} defaultCountry="IN" value={deviceSettings.adminCountryCode} name="adminCountryCode" onChange={adminMobileChange} placeholder="Mobile Number" title="" readOnly="true" autoComplete="off" />
                    <input style={styles.mobileNumberStyles} type="number" value={deviceSettings.adminMobileNum} name="adminMobileNum" onChange={handleChange} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="Mobile Number" title="" />
                  </div>
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isAdminMobileInProgress === true || validAdminPhNum !== "" ? styles.redBtnEnable : styles.redBtnDisable}>{isAdminMobileInProgress === true ? 'Transaction InProgress' : 'Length is between 8 to 15'}</p>
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label="Set Password" disabled={isPasswordInProgress}>
                  <div className="d-flex align-items-center position-relative">
                    <input placeholder="********" style={styles.selectStyles}
                      value={deviceSettings.password} name="password" type={passwordType} onChange={handlePasswordChange}
                      min={5} maxLength={5} title="" />
                    <span onClick={togglePassword} style={styles.passwordStyles}>
                      {passwordType === "password" ? (<se-icon>eye_dont_show</se-icon>) : (<se-icon>body_eye</se-icon>)}
                    </span>
                  </div>
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isPasswordValid === false || isPasswordInProgress === true ? styles.redBtnEnable : styles.redBtnDisable}>{isPasswordInProgress === true ? CONFIGURATIONS.Password_Inprogress_Msg : 'length should be minimum 5 numbers'}</p>
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.GPRS} disabled={isGPRSInProgress}>
                  <input type="number" value={deviceSettings.gprs} name="gprs" onChange={handleGPRS} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="1 to 60" min="1" max="60" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isGPRSInProgress === true || isGPRSValid === false ? styles.redBtnEnable : styles.redBtnDisable}>{isGPRSInProgress === true ? 'Transaction InProgress' : 'Range is between 1 to 60'}</p>
              </div>
            </div>

            <div>&nbsp;</div>
            <div style={styles.bold}>Dry Run Protection</div><hr />

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.DryRunDetectDelay} disabled={isDryRunInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.DryRunDetectDelay} name="DryRunDetectDelay" onChange={handleDryRunDetectDelayChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 100" min="0" max="100" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isDryRunInProgress === true || isDryRunDetectDelayValid == false ? styles.redBtnEnable : styles.redBtnDisable}>{isDryRunInProgress === true ? 'Transaction InProgress' : 'Range is between 0 to 100'}</p>
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.DryRunUpperThreshold} disabled={isDryRunInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.DryRunUpperThreshold} name="DryRunUpperThreshold" onChange={handleDryRunUpperThresholdChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="20 to 100" min="20" max="100" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isDryRunInProgress === true || isDryRunUpperThresholdValid === false ? styles.redBtnEnable : styles.redBtnDisable}>{isDryRunInProgress === true ? 'Transaction InProgress' : 'Range is between 20 to 100'}</p>
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.DryRunLowerThreshold} disabled={isDryRunInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.DryRunLowerThreshold} name="DryRunLowerThreshold" onChange={handleDryRunLowerThresholdChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 100" min="0" max="100" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isDryRunInProgress === true || isDryRunLowerThresholdValid === false ? styles.redBtnEnable : styles.redBtnDisable}>{isDryRunInProgress === true ? 'Transaction InProgress' : 'Range is between 0 to 100'}</p>
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 text-left">
                <se-form-field label={CONFIGURATIONS.DryRunFreqThreshold} disabled={isDryRunInProgress || isMCTDisable}>
                  <input type="number" value={deviceSettings.DryRunFreqThreshold} name="DryRunFreqThreshold" onChange={handleDryRunFreqThresholdChange} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 599" min="0" max="599" title="" />
                </se-form-field>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg">
                <p style={isDryRunInProgress === true || isDryRunFreqThresholdValid === false ? styles.redBtnEnable : styles.redBtnDisable}>{isDryRunInProgress === true ? 'Transaction InProgress' : 'Range is between 0 to 599'}</p>
              </div>
            </div>

            <div className="createBlockSize text-right pb-3 trStyles margin-top-set">
              <div className="col-md-6 col-lg-6 col-md-6 col-sm-12 px-0">
                
              </div>
              <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg"></div>
            </div>
          </div>

        </se-container>
        <div className="paddingLeft300 margin-bottom-snack" align="left">
          <se-snackbar id="deviceSettings-snackbar" duration="5000" type={snackBarObj.type}
            message={snackBarObj.msg} ></se-snackbar>
        </div>
      </se-block-content>
    </>
  );
};
export default DeviceSettings;

DeviceSettings.propTypes = {
  nominalMotorPower: PropTypes.number,
  operatorMobileNum: PropTypes.number,
  operatorCountryCode: PropTypes.string,
  password: PropTypes.string,
  controlType: PropTypes.string,
  options: PropTypes.string,
};
