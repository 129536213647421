import React from "react";
import { withRouter } from 'react-router-dom';
import "./widget.scss";
import _ from 'lodash';
import { Line } from 'react-chartjs-2'
const Dashboardwidget = (props) => {
  const [utilityDetails, setUtilityDetails] = React.useState({})
  React.useEffect(() => {
    if (props.environment) {
      setUtilityDetails(props.environment)
    }
  }, [props.environment])
  const navigatetoDetailsPage = () => {
    let { history } = props;
    history.push({
      pathname: '/EnvironmentDetails',
      widgetType: "ENVIRONMENT"
    });
  }
  const renderValue = _.map(utilityDetails.widgetData, (widgetData,index) => {
    return <div key={index} class="col-xs-5 col-sm-5 col-md-5 col-lg-5 pt-0 margin-left-mobile">
      <div class="row align-items-center">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-3">
          <se-icon size="large" color="standard" style={{fontSize:"40px"}}>{widgetData.propIcon}</se-icon>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 LineheightOver" style={{ paddingLeft: "calc(1rem + 0.8vw)" }}>
          <span class="text-align font-size-12 textColor">{widgetData.propName}</span>
          <span class="text-align font-size-12 textColor"> ({widgetData.units})</span>
        </div>
      </div>
      <div class="row align-items-center pt-4 pl-0 margin-left-mobile-c02value">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <span class="font-size-lg pt-2">{widgetData.propVal}</span>
        </div>
      </div>
    </div>
  })
  const renderGraph = () => {
    return _.map(utilityDetails.widgetDetails, widgetDetail => {
      let dataValue = {
        labels: [],
        datasets: []
      }
      dataValue.labels = utilityDetails.periods
      _.map(widgetDetail.graphData, graphData => {
        dataValue.datasets.push(
          {
            label: graphData.propLable,
            backgroundColor: graphData.propColor,
            borderColor: graphData.propColor,
            borderWidth: 2,
            fill: false,
            borderWidth: 1,
            pointRadius: 2,
            pointHoverRadius: 2,
            pointBorderColor: graphData.propColor,
            pointBackgroundColor: "white",
            pointStyle: 'circle',
            data: graphData.propValue
          }
        )
      })

      let options = {
        tooltips: {
          intersect: false,
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']] + " ";
            }
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              fontSize: 8,
            }
          }],
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            scaleLabel: {
              display: true,
              labelString: widgetDetail.YUnits,
              padding: 3,
              fontSize: 8,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 6, maxTicksLimit: 10,
              padding: 3,
              labelString: widgetDetail.YUnits,
            }
          }],
        },
        legend: {
          display: false,
        }
      }
      return <Line data={dataValue} options={options} width="175" height="105" />
    })
  }
  return <se-block margin="none" class="mt-2 font-icon" style={{ height:"26vh" , overflow:"hidden"}}>
    <div class="border-bottom-overview bordergreenColor">
      <div class="rows align-items-center">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8"><span class="font-size-16">{utilityDetails.widgetType}<se-icon class="pointer valign-super margin-left-expand-environment hideinDesktop showinMobile-expand" option="" size="nano" color="" onClick={() => navigatetoDetailsPage()}>presentation_mode</se-icon>
         </span></div>
        <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 textColor font-size-12 my-auto">Lifetime</div>
        <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 my-auto">
          <div class="row">
            <div class="col-md-12 text-right">
              <se-icon class="pointer valign-super" option="" size="nano" color="" onClick={() => navigatetoDetailsPage()}>presentation_mode</se-icon>
            </div>

          </div>
        </div>
      </div>
    </div>
    <se-block-content class="border-bottom-overview overflow-hidden">
      <div class="row align-items-center envblockheight">
        {renderValue}
        <div class="hideinMobile col-xs-7 col-sm-7 col-md-7 col-lg-7 pl-0 border-left">
          {renderGraph()}
        </div>
      </div>
    </se-block-content>
  </se-block>
}
export default withRouter(Dashboardwidget);