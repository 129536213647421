import React from "react";
import { withRouter } from "react-router-dom";
import "react-day-picker/lib/style.css";
import Auth from "../api/ApiSession";
import _ from "lodash";
import "./styles.css";
import ChoosePeriod from "../components/choosePeriod";
import useImpactMetricsGraphDetails from "./useImpactMetricsGraphDetails";
import { useSelector } from 'react-redux'
const authCall = new Auth();

const AgriproducedDetails = (props) => {
  const userObj = useSelector((state)=> state.user)
  const [utilityDetails, setUtilityDetails] = React.useState({});
  const [utilityDetailsCopy, setUtilityDetailsCopy] = React.useState({});
  const [selectedCheckBox, setSelectedCheckBox] = React.useState([]);
  const [snackBarObj, setSnackBarObj] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [graphType, setGraphType] = React.useState({
    barGraphClass: "iconBtnLeft active",
    lineGraphClass: "iconBtnRight",
    type: "Bar",
  });
  const [dataToPost, setDataToPost] = React.useState({
    type: "Agri Process Value Addition",
    granularity: null,
    startDate: null,
    endDate: null,
    siteName: sessionStorage.getItem("siteName"),
    customerId: parseInt(sessionStorage.getItem("customerId")),
    customerType: sessionStorage.getItem("customerType"),
    graphType: "bar",
  });
  const intervalRef = React.useRef();
  let idToPrint = [];
  let dataValue = {};
  let options = {};
  const onPeriodChange = (startDate, endDate, type) => {
    let dataTopost = dataToPost;
    dataTopost.startDate = startDate;
    dataTopost.endDate = endDate;
    dataTopost.granularity = type;
    setDataToPost(dataTopost);
    fetchUtilityDetails("normalCall");
  };
  const { renderwaterGraphDetails } = useImpactMetricsGraphDetails(
    utilityDetails,
    graphType,
    idToPrint,
    dataValue,
    options,
    utilityDetails.widgetType
  );
  // const { renderGraphDetails1,renderGraphDetails2 } = useImpactMetricsGraphDetails(utilityDetails, graphType, idToPrint, dataValue, options, utilityDetails.widgetType)
  const fetchUtilityDetails = (call) => {
    call === "normalCall" ? setIsLoading(true) : setIsLoading(false);
    authCall.postAgroDashboardDetails(dataToPost).then((json) => {
      if (json) {
        setIsLoading(false);
        let jsonCopy = JSON.parse(JSON.stringify(json));
        removeDataFromGraph(jsonCopy);
        setUtilityDetailsCopy(json);
      }
    });
  };
  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      fetchUtilityDetails("initialCall");
    }, 900000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  const removeDataFromGraph = (jsonCopy) => {
    _.map(selectedCheckBox, (ele) => {
      _.map(jsonCopy.widgetDetails, (widgetDetail) => {
        let i = 0;
        while (i < widgetDetail.graphData.length) {
          if (widgetDetail.graphData[i].propName === ele) {
            widgetDetail.graphData.splice(i, 1);
            i = 0;
          } else {
            i++;
          }
        }
      });

      if (ele === "Pump") {
        _.find(jsonCopy.widgetData, ["propName", "Water Discharge"]).propVal =
          "";
        _.find(jsonCopy.widgetData, ["propName", "Water Discharge"]).units = "";
      }
      _.map(jsonCopy.widgetData, (widgetData) => {
        if (widgetData.propName === ele) {
          widgetData.propVal = "";
          widgetData.units = "";
        }
      });
    });
    setUtilityDetails(jsonCopy);
  };
  const changeToBarGraph = () => {
    setGraphType({
      barGraphClass: "iconBtnLeft active",
      lineGraphClass: "iconBtnRight",
      type: "Bar",
    });
    dataToPost.graphType = "bar";
  };
  const changeToLineGraph = () => {
    setGraphType({
      barGraphClass: "iconBtnLeft",
      lineGraphClass: "iconBtnRight active",
      type: "Line",
    });
    dataToPost.graphType = "Line";
  };
  const navigateToOverview = () => {
    let { history } = props;
    history.push({
      pathname: "/overview",
    });
  };
  const onCheckBoxClicked = (selected, selectedData) => {
    let utilityDetails1 = JSON.parse(JSON.stringify(utilityDetailsCopy));
    if (selected === false) {
      if (selectedCheckBox.indexOf(selectedData.propName) === -1) {
        selectedCheckBox.push(selectedData.propName);
      }
    } else {
      if (selectedCheckBox.indexOf(selectedData.propName) !== -1) {
        selectedCheckBox.splice(
          selectedCheckBox.indexOf(selectedData.propName),
          1
        );
      }
    }
    removeDataFromGraph(utilityDetails1);
  };
  const generateReport = () => {
    setIsLoading(true);

    authCall.postGeneratePdfReport(dataToPost).then((json) => {
      if (json) {
        setIsLoading(false);
        let a = document.createElement("a");
        a.href = json.includes('"https://')
          ? "https://" + json.replace('"https://', "").replace('"', "")
          : json;
        a.target = "_blank";
        a.click();
      } else {
        setIsLoading(false);
        document.getElementById("admin-snackbar").open = true;
        setSnackBarObj({ type: "warning", msg: "Failed to generate PDF" });
      }
    });
  };
  const getPropValue = (ele) => {
    let count = 0;
    if (ele.propName === "Water Discharge") {
      if (ele.propVal === "") {
        ele.propVal = "0.0";
      }
      count = parseFloat(ele.propVal);
    } else {
      let jsonCopy = JSON.parse(JSON.stringify(utilityDetails.widgetData));
      _.map(jsonCopy, (widgetData) => {
        if (
          widgetData.propName !== "Pump" &&
          !widgetData.units.includes("liter") &&
          widgetData.propName !== "Water Discharge" &&
          widgetData.propName !== "Produce Transformed"
        ) {
          console.log(widgetData);
          if (widgetData.propVal === "") {
            widgetData.propVal = "0.0";
          }
          count = count + parseFloat(widgetData.propVal);
        }
      });
    }
    if (count === 0.0) {
      return "";
    } else {
      return count.toFixed(1);
    }
  };
  const isMobile = window.innerWidth <= 600;
  const renderCheckBoxList = _.map(utilityDetails.widgetData, (ele, index) => {
    if (sessionStorage.getItem("customerType") === "SWP") {
        if(isMobile) {
            return(
                <div key={index} class="row ">
                <div class="col-2 col-md-2 px-0 text-center">
                  <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>
                    {ele.propIcon}
                  </se-icon>
                </div>
                <div class="col-4 col-md-4 px-0 pt-2 text-left">
                  <span class="font-size-12 textColor">{ele.propName}</span>
                </div>
                <div class="col-3 col-md-3 px-0 text-right">
                  <span class="font-size-37">{ele.propVal}</span> <span class="font-size-12 font-weight-600" style={{marginTop:'15px', paddingLeft:'6px'}}>{ele.units}</span>   
                </div>
              </div>
            )
        }
      return (
        <div key={index} class="row align-items-center detailscheckBlock">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-center">
                    <se-icon size="large" color="standard" style={{fontSize:"40px"}}>{ele.propIcon}</se-icon>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-4 col-xl-3 px-0 textLineHight pt-2 text-left">
                    <span class="font-size-12 textColor impactMetricsLabel">{ele.propName}</span>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-5 col-lg-3 col-xl-5 px-0 text-right">
                    <span class="font-size-37" style={{ position: 'relative' }}>{ele.propVal} 
                    {/* <span className={'displayUnits'}>{ele.units}</span> */}
                        </span> 
                        <div class="font-size-12 font-weight-600" style={{marginTop:"-1vh"}}>{ele.units}</div>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-3 col-lg-3 col-xl-2" style={{padding:"15px"}}>
                    <se-checkbox selected="true" onClick={(e) => onCheckBoxClicked(e.target.selected, ele)}></se-checkbox>
                </div>
            </div>
      );
      
    }
   
     else if (ele.propIcon) {
      return (
        <div key={index} class="row align-items-center">
          <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-center">
            <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>
              {ele.propIcon}
            </se-icon>
          </div>
          <div class="col-md-3  px-0">
            <span class="font-size-12 textColor">{ele.propName}</span>
          </div>
          <div class="col-md-7">
            <span class="font-size-37" style={{ position: "relative" }}>
              {getPropValue(ele)}
              <span class="font-size-12 font-weight-600">
                {getPropValue(ele) ? ele.units : null}
              </span>
              {/* <span className={'displayUnits'}>{getPropValue(ele)?ele.units:null}</span> */}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div key={index} class="row align-items-center">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 font-normal">
            {ele.propName}
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-5 px-0 text-right">
            <se-checkbox
              selected="true"
              onClick={(e) => onCheckBoxClicked(e.target.selected, ele)}
            ></se-checkbox>
          </div>
        </div>
      );
    }
  });
  return (
    <se-container position="absolute" direction="row">
      {isLoading ? (
        <div class="align-items-center">
          <se-loading loading="true"></se-loading>
        </div>
      ) : null}
      <se-block>
        <se-block-header class="headerColor">
          <span class="font-size-22">
            {utilityDetails.widgetType}-
            {sessionStorage.getItem("siteName") !== ""
              ? sessionStorage.getItem("siteName")
              : "All Sites"}
          </span>
          <div slot="end">
            <se-icon
              option=""
              class="pointer"
              onClick={() => navigateToOverview()}
            >
              action_close
            </se-icon>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </se-block-header>
        <se-block-content class="grey-backgroundCreate">
          <se-container
            position="absolute"
            direction="row"
            margin="large"
            padding="large"
          >
            <div
              class="col-md-4 col-sm-4 col-lg-3 col-xl-3 px-0"
              option="widget"
              margin="none"
              style={{ background: "white" }}
            >
              <div class="border-bottom divDetailsHeight text-left">
                <span class="font-normal font-weight-400">
                  Last updated: {utilityDetails.lastUpdated}
                </span>
              </div>
              <div class="border-bottom">
                <se-block-content>
                  <ChoosePeriod
                    onChange={onPeriodChange}
                    InstaltedDate={utilityDetails.installationDate}
                  />
                </se-block-content>
              </div>
              <se-block-content class="ChooseDivHeight">
                {renderCheckBoxList}
              </se-block-content>
            </div>
            <se-divider option="vertical"></se-divider>
            <se-block class="px-0 hideinMobile">
              <div class="exportalign" style={{display: 'inline-grid'}}>
                <div class="">
                  <div className="toggleBtn">
                    <span
                      onClick={() => changeToBarGraph()}
                      className={graphType.barGraphClass}
                      style={{float: 'right'}}>
                      <se-icon>graph_barchart</se-icon>
                    </span>
                    <span
                      onClick={() => changeToLineGraph()}
                      className={graphType.lineGraphClass}
                      style={{float: 'right'}}>
                      <se-icon>energy</se-icon>
                    </span>
                  </div>
                  <se-icon
                    option="button"
                    onClick={() => generateReport()}
                    size="large"
                    style={{float: 'right'}}
                  >
                    action_pdf_report
                  </se-icon>
                </div>
                {renderwaterGraphDetails()}
                {/* {renderGraphDetails1()}
                        {renderGraphDetails2()} */}
              </div>
            </se-block>
          </se-container>
        </se-block-content>
      </se-block>
      <div style={{ position: "absolute", left: "15px" }}>
        <se-snackbar
          id="admin-snackbar"
          duration="5000"
          type={snackBarObj.type}
          message={snackBarObj.msg}
        ></se-snackbar>
      </div>
    </se-container>
  );
};
export default withRouter(AgriproducedDetails);
