import React from "react";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Doughnut } from 'react-chartjs-2'
import img_pumpstatus from '../images/pumpstatus.png'
import "./chartjs.css";
import "../pages/styles.css";
import CryptoJS from 'crypto-js';

const DashboardAgriProduced = (props) => {
    let user = "";
    const encrypted = sessionStorage.getItem("user");    
    if(encrypted!=null){
        const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        user = JSON.parse(decrypted);
    }
    const [utilityDetails, setUtilityDetails] = React.useState({})
    const gatewayType =  user.gatewayType === null || user.gatewayType === undefined ? '' :user.gatewayType;
    
    // let data = [
    //     { "propName": "Water Discharge", "propVal": "0.0", "units": "Liters", "propColor": null, "propIcon": "water_drop_closed" },
    //     { "propName": "Runtime", "propVal": "10", "units": "hrs", "propColor": null, "propIcon": "timer_switch" },
    //     { "propName": "Pump Status", "propVal": "OFF", "units": "", "propColor": null, "propIcon": "heat_pump" },        
    // ]
    React.useEffect(() => {
        if (props.agriproduced) {
            setUtilityDetails(props.agriproduced)
        }
    }, [props.agriproduced])
    const navigatetoDetailsPage = () => {
        let { history } = props;
        history.push({
            pathname: '/AgriproducedDetails',
            widgetType: "Agri Process Value Addition"
        });
    }
    const renderGraph = () => {
        var dataValue = {

            labels: [],
            datasets: [
                {
                    label: null,
                    backgroundColor: [],
                    borderColor: [],
                    hoverBackgroundColor: [],
                    hoverBorderColor: [],
                    data: []
                }]
        }

        let options = {
            tooltips: {
                enabled: true
            },
            cutoutPercentage: 70,
            legend: {
                display: false,
            }
        }
        let val = utilityDetails.widgetData[0].propVal ? utilityDetails.widgetData[0].propVal : 0;
        let plugins = [{
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 60).toFixed(2);
                ctx.font = fontSize + "em Nunito";
                ctx.textBaseline = "top";
                var text = val,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2.5;
                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        }];
        if (utilityDetails.widgetDetails[0].graphData.length > 0){
            let check=false
            utilityDetails.widgetDetails[0].graphData.forEach(element => {
                if(element.propValue[0]>0)
                    check=true
            });
            if (utilityDetails.widgetDetails[0].graphData[0].propValue !== null && check) {
                _.map(utilityDetails.widgetDetails[0].graphData, ele => {
                    dataValue.labels.push(ele.propName)
                    dataValue.datasets[0].backgroundColor.push(ele.propColor)
                    dataValue.datasets[0].borderColor.push('#E6E6E6')
                    dataValue.datasets[0].hoverBackgroundColor.push(ele.propColor)
                    dataValue.datasets[0].hoverBorderColor.push(ele.propColor)
                    dataValue.datasets[0].data.push(ele.propValue[0])
                })
            }
            else {
                dataValue.datasets[0].backgroundColor.push('#E6E6E6')
                dataValue.datasets[0].borderColor.push('#ffffff')
                dataValue.datasets[0].hoverBackgroundColor.push('#E6E6E6')
                dataValue.datasets[0].hoverBorderColor.push('#E6E6E6')
                dataValue.datasets[0].data.push(1)
                options.tooltips.enabled = false
            }
        }            
        else {
            dataValue.datasets[0].backgroundColor.push('#E6E6E6')
            dataValue.datasets[0].borderColor.push('#ffffff')
                dataValue.datasets[0].hoverBackgroundColor.push('#E6E6E6')
                dataValue.datasets[0].hoverBorderColor.push('#E6E6E6')
            dataValue.datasets[0].data.push(1)
            options.tooltips.enabled = false
        }

        return <Doughnut data={dataValue} options={options} plugins={plugins}  width="151" height="90" />
    }
    const renderGraph1 = () => {
        var dataValue = {

            labels: [],
            datasets: [
                {
                    label: null,
                    backgroundColor: [],
                    borderColor: [],
                    hoverBackgroundColor: [],
                    hoverBorderColor: [],
                    data: []
                }]
        }

        let options = {
            tooltips: {
                enabled: true,
                displayColors:false,
            },
            cutoutPercentage: 70,
            legend: {
                display: false,
            }
        }
        let val = utilityDetails.widgetData[1].propVal ? utilityDetails.widgetData[1].propVal : 0;
        let plugins = [{
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 60).toFixed(2);
                ctx.font = fontSize + "em Nunito";
                ctx.textBaseline = "top";
                var text = val,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2.5;
                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        }];
        _.map(utilityDetails.widgetData, ele => {
            if (ele.propName === "Water Discharge") {
                if (ele.propVal > 0) {
                    dataValue.labels.push(ele.propName)
                    dataValue.datasets[0].backgroundColor.push('#299BCD')
                    dataValue.datasets[0].borderColor.push('#E6E6E6')
                    dataValue.datasets[0].hoverBackgroundColor.push('#299BCD')
                    dataValue.datasets[0].hoverBorderColor.push('#299BCD')
                    dataValue.datasets[0].data.push(ele.propVal)

                }
                else {
                    dataValue.datasets[0].backgroundColor.push('#E6E6E6')
                    dataValue.datasets[0].borderColor.push('#ffffff')
                    dataValue.datasets[0].hoverBackgroundColor.push('#E6E6E6')
                    dataValue.datasets[0].hoverBorderColor.push('#E6E6E6')
                    dataValue.datasets[0].data.push(1)
                    options.tooltips.enabled = false
                }
            }
        })
        return <Doughnut data={dataValue} options={options} plugins={plugins} width="151" height="90" />
    }
    const renderValue = _.map(utilityDetails.widgetData, (widgetData, index) => {
        if (sessionStorage.getItem('customerType') === "Agro") {
            return <div key={index} class="col-xs-6 col-sm-6 col-md-6 col-lg-6 pt-0">
                <div class="row align-items-center">
                    {/* <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-3">
                        <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{widgetData.propIcon}</se-icon>
                    </div> */}
                    <div class="col LineheightOver" style={{ textAlign: "center" }}>
                        <span class="text-align font-size-12 textColor">{widgetData.propName}</span><br/>
                        <span class="text-align font-size-12 textColor"> ({widgetData.units})</span>
                    </div>
                </div>
                {/* <div class="row align-items-center pt-3 pl-0">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span class="font-size-lg pt-2">{widgetData.propVal}</span>
                    </div>
                </div> */}
                {widgetData.propName === "Water Discharge" ? renderGraph1() : renderGraph()}
            </div>
        } else {
            return <div key={index} class="text-center px-0 swp-widget-tablet">
                <div class="marginTop">
                    {
                        widgetData.propName === "Pump Status" ? (gatewayType.includes('ATV320') === true ? <a href="/configuration"><img src={img_pumpstatus} class="pumpsize-mobile pumpsize-tablet pumpstatus_icon" /></a> : <img src={img_pumpstatus} class="pumpsize-mobile pumpsize-tablet pumpstatus_icon" />) :
                            <se-icon size="large" color="standard" style={{ fontSize: "40px", height: "0px" }}>{widgetData.propIcon}</se-icon>
                    }    </div>
                <div class="pumpstatus-mobile font-size-12 textColor mt-2">{widgetData.propName}</div>
                {/* <div class="font-size-lg fixed-line-height mt-2">{widgetData.propVal}</div> */}
                <div style={{ color: widgetData.propVal === "ON" ? "#3DCD58" : widgetData.propVal === "OFF" ? "red" : "#505257" }} class="font-size-lg fixed-line-height mt-2">{widgetData.propVal}</div>
                <div class="font-size-12 font-weight-600 fixed-height">{widgetData.units}</div>
            </div>
        }

    })
    return <se-block margin="none" class="mt-2 font-icon water_height">
        <div class="border-bottom-overview bordergreenColor">
            <div class="rows align-items-center">
                <div class="col-xs-6 col-sm-8 col-md-8 col-lg-8"><span class="font-size-16" style={{display: 'inline-flex'}}>{utilityDetails.widgetType}<se-icon class="pointer valign-super margin-left-expand hideinDesktop showinMobile-expand float-right" option="" size="nano" color="" onClick={() => navigatetoDetailsPage()}>presentation_mode</se-icon>
                     </span></div>
                <div class="hideinMobile col-xs-4 col-sm-2 col-md-2 col-lg-2 textColor font-size-12 my-auto">Lifetime</div>
                <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 my-auto">
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <se-icon class="pointer valign-super" option="" size="nano" color="" onClick={() => navigatetoDetailsPage()}>presentation_mode</se-icon>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <se-block-content class="border-bottom-overview" style={{overflow: 'hidden'}}>
            <div class={"row align-items-center space_between" + (sessionStorage.getItem('customerType') === "Agro" ? "agriblockheight" : "")}>
                {renderValue}
            </div>
        </se-block-content>
    </se-block>
}
export default withRouter(DashboardAgriProduced);