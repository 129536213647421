import React from "react";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import '../pages/styles.css';

const Partner = (props) => {
    const [utilityDetails, setUtilityDetails] = React.useState({})
    React.useEffect(() => {
      if (props.realTime) {
        setUtilityDetails(props.realTime)
      }
    }, [props.realTime])
    const navigatetoDetails = () => {
        let { history } = props;
        history.push({
            pathname: '/energyConsumptionDetails',
            widgetType: "Energy Consumption"
        });
    }
    const renderList = _.map(utilityDetails.widgetData, (widgetData,index) => {
        return <div key={index} class="energyconsumptionwidget-mobile energyconsumptionwidget-desktop text-center">
            <div class="font-size-12 textColor">{widgetData.propName}</div>
            <div class="font-size-lg mt-4" >{widgetData.propVal}</div> 
            <div class="font-size-12 font-weight-600  mt-2">{widgetData.units}</div> 
        </div>
    })
    return <se-block margin="none" class="mt-2 font-icon" style={{ height:"26vh"}}>
        <div class="border-bottom-overview bordergreenColor">
            <div class="rows align-items-center">
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8"><span class="font-size-16">{utilityDetails.widgetType}<se-icon class="pointer valign-super margin-left-expand-energy hideinDesktop showinMobile-expand" size="nano" color="#00000029" onClick={navigatetoDetails}>presentation_mode</se-icon></span>
                    </div>
                <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 textColor my-auto font-size-12">Lifetime</div>
                <div class="hideinMobile col-xs-2 col-sm-2 col-md-2 col-lg-2 my-auto">
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <se-icon class="pointer valign-super" size="nano" color="#00000029" onClick={navigatetoDetails}>presentation_mode</se-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <se-block-content class="capacityblockheight border-bottom-overview">
            <div class="row align-items-center mt-2 ecblockheight margin_10">
                {renderList}
            </div>
        </se-block-content>
    </se-block>
}
export default withRouter(Partner);