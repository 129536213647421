/* /src/components/auth/logoutCallback.jsx */

import React from "react";
import { AuthConsumer } from "./authProvider";

export const LogoutCallback = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return <se-loading loading="true"></se-loading>;
        }}
    </AuthConsumer>
);