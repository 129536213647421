import React,{useState} from 'react';
import Pagination from 'react-js-pagination';

const TableComponent = (props) => {
    const [activePage, setActivePage] = useState(1)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(7)
    const [tableData, setTableData] = useState([])
    const [tableDataCopy, setTableDataCopy] = useState([])
    const [upArrow, setUpArrow] = useState(true)

    const getPropertyName = (property) => {
        let returnProperty = null
        switch (property) {
            case "Type":
                returnProperty = "ReportName"
                break;
            case "Site":
                returnProperty = "SiteName"
                break;
            case "Created":
                returnProperty = "CreatedDate"
                break;
            case "Command":
                returnProperty = "commandName"
                break;
            case "CommandValue":
                returnProperty = "commandValue"
                break;
            case "Status":
                returnProperty = "commandStatus"
                break;            
            case "Site Name":
                returnProperty = "SiteName"
                break; 
            case "Gateway ID":
                returnProperty = "GatewayId"
                break;
            case "Status ":
                returnProperty = "ActiveStatus"
                break; 
            case "Last Packet Updated":
                returnProperty = "LastPacketUpdated"
                break;     

            case "Ticket ID":
                returnProperty = "TicketId"
                break; 
            case "Site Name":
                returnProperty = "SiteName"
                break;
            case "Gateway ID":
                returnProperty = "GatewayId"
                break;
            case "Customer Message":
                returnProperty = "CustomerMessage"
                break; 
            case " Status ":
                returnProperty = "Status"
                break;
            case "Raised Date":
                returnProperty = "RaisedDate"
                break; 
            case "Closed Date":
                returnProperty = "ClosedDate"
                break;              
        }
        return returnProperty
    }

    const sortTable = (property) => {
        property = getPropertyName(property)
        setUpArrow(false)
        let updatedNotificationTable = tableDataCopy.sort((a, b) => {
            if (typeof a[property] === 'number' && typeof b[property] === 'number') {
                if (a[property] < b[property]) {
                    return -1
                }
                else if (a[property] > b[property]) {
                    return 1
                }
                else {
                    return 0;
                }
            } else {
                var reA = /[^a-zA-Z]/g;
                var reN = /[^0-9]/g;
                if (typeof a[property] === 'number') {
                    a[property] = a[property].toString();
                } else {
                    a[property] = a[property].charAt(0).toUpperCase() + a[property].slice(1);
                }

                if (typeof b[property] === 'number') {
                    b[property] = b[property].toString();
                } else {
                    b[property] = b[property].charAt(0).toUpperCase() + b[property].slice(1);
                }
                var aA = a[property].replace(reA, "");
                var bA = b[property].replace(reA, "");
                if (aA === bA) {
                    var aN = parseInt(a[property].replace(reN, ""), 10);
                    var bN = parseInt(b[property].replace(reN, ""), 10);
                    if (isNaN(bN) || isNaN(bN)) {
                        return a[property] > b[property] ? 1 : -1;
                    }
                    return aN === bN ? 0 : aN > bN ? 1 : -1;
                } else {
                    return aA > bA ? 1 : -1;
                }

            }
        })
        setTableData(updatedNotificationTable)
        let tableData = tableDataCopy.slice(0, 7)
        setTableData(tableData)
        setActivePage(1)
    };

    const sortTableDes = (property) => {
        property = getPropertyName(property)
        setUpArrow(true)
        let updatedNotificationTable = tableDataCopy.sort((b, a) => {
            if (typeof a[property] === 'number' && typeof b[property] === 'number') {
                if (a[property] < b[property]) {
                    return -1
                }
                else if (a[property] > b[property]) {
                    return 1
                }
                else {
                    return 0;
                }
            } else {
                var reA = /[^a-zA-Z]/g;
                var reN = /[^0-9]/g;
                if (typeof a[property] === 'number') {
                    a[property] = a[property].toString();
                } else {                  
                    a[property] = a[property].charAt(0).toUpperCase() + a[property].slice(1);
                }

                if (typeof b[property] === 'number') {
                    b[property] = b[property].toString();
                } else {
                    b[property] = b[property].charAt(0).toUpperCase() + b[property].slice(1);
                }
                var aA = a[property].replace(reA, "");
                var bA = b[property].replace(reA, "");
                if (aA === bA) {
                    var aN = parseInt(a[property].replace(reN, ""), 10);
                    var bN = parseInt(b[property].replace(reN, ""), 10);
                    if (isNaN(bN) || isNaN(bN)) {
                        return a[property] > b[property] ? 1 : -1;
                    }
                    return aN === bN ? 0 : aN > bN ? 1 : -1;
                } else {
                    return aA > bA ? 1 : -1;
                }

            }
        })
        setTableData(updatedNotificationTable)
        let tableData = tableDataCopy.slice(0, 7)
        setTableData(tableData)
        setActivePage(1)
    };

    const paginate = (pageNumber) => {
        setActivePage(pageNumber)
        let from = ((pageNumber - 1) * itemsCountPerPage)
        let to = pageNumber * itemsCountPerPage
        setTableData(tableDataCopy.slice(from, to))
    }
    React.useEffect(() => {
        if (props.tableData.length > 0) {
            let tableDataCopy = JSON.parse(JSON.stringify(props.tableData))
            setTableDataCopy(tableDataCopy)
            let tableData = tableDataCopy.slice(0, 7)
            setTableData(tableData)
            setActivePage(1)
            setUpArrow(true)
        }else{
            setTableData([])
            setUpArrow(true)
        }
    }, [props.tableData])

    const renderArrowIcon = (ele) => {
        if (upArrow && ele !== "Download" && ele !== "" && ele !== "Action" && ele !=="Raised Date" && ele !== "Closed Date") {
            return <se-icon size="nano" class="pointer" onClick={() => sortTable(ele)}>arrow3_up</se-icon>
        } 
        if(!upArrow && ele !== "Download" && ele !== "" && ele !== "Action" && ele !=="Raised Date" && ele !=="Closed Date"){
            return <se-icon size="nano" class="pointer" onClick={() => sortTableDes(ele)}>arrow3_down</se-icon>
        }      
    }

    const renderTableHeader = props.tableHeader.map((ele) => {
        return <th style={{ "borderBottom": "none", "borderTop": "none", border: "none", padding: "0.55rem" }}>{ele}
            {renderArrowIcon(ele)}           
        </th>
    })

    const renderTableData = (tableObject) => {
        return Object.keys(tableObject).map(keys => {
            // if (keys === "DocxDownloadLink") {
            //     return <td style={{ border: "none", padding: "10px 0px 10px 10px", display: "table-cell", width: "15px" }}>
            //         <a href={tableObject[keys]}>
            //             <se-icon class="color-alternative" size="small">file_docx</se-icon>
            //         </a>
            //     </td>
            // }
            if (keys === "PdfDownloadLink") {
                return <td style={{ border: "none", padding: "10px 0px 10px 0px", display: "table-cell", width: "40px" }}>
                    <a style={{position:"relative",right:"40px"}}  target='_blank' href={tableObject[keys].includes("\"https://")? "https://"+tableObject[keys].replace("\"https://",'').replace('"',''):tableObject[keys]}>
                        <se-icon option="button" style={{color: "black"}} size="small">action_pdf_report</se-icon>
                    </a>
                </td>
            }
            
            if (keys === 'commandValue') {
                return <td style={{ maxWidth: "50px", overflowWrap : "anywhere",  border: "none", padding: "0.55rem",verticalAlign:"middle" }}>{tableObject[keys]}</td>
            }

            //Resolve button logic 
            if (keys === "Action" && props.tableName=='OpenTicketsTable' ) { 
                return <td style={{ border: "none", display: "table-cell"}}> 
                        <button class="resolved" onClick={() => props.onResolveClick(tableObject.TicketId)}>Resolve</button> 
                      </td>
            }
            //condition for not including colums
            if (keys !== 'ReportId') {
                return <td style={{ border: "none", padding: "0.55rem",verticalAlign:"middle" }}>{tableObject[keys]}</td>
            }
        })
    }

    const renderTableRow = tableData.map((ele) => {
        return <tr style={{ border: "none" }}>{renderTableData(ele)}</tr>
    })

    return <div className="table-component mobile-padding-table">
            <table id={props.tableName} class="table">
                <thead className='font-size-mobile' style={{ whiteSpace: 'nowrap' }}>
                    <tr style={{ border: "none", backgroundColor: "white" }}>
                        {renderTableHeader}
                    </tr>
                </thead>
                <tbody id="notificationTable" style={{}}>
                    {renderTableRow}
                </tbody>
            </table>
            
        {tableData.length ? <Pagination activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={tableDataCopy.length}
            onChange={(e) => paginate(e)} />
            : null}

    </div>
}

export default TableComponent