import React, { Component } from 'react';
import land from '../images/landImg.png'
import agri from '../images/agri.PNG'
import solarwaterpumping from '../images/solarwaterpumping.PNG'
import { useDispatch } from 'react-redux'
import { addUser } from '../UserSlice'
import { user } from '../services/Crypto'

const LandingPage = (props) => {
  const dispatch = useDispatch()
  dispatch(addUser(user));
  
  const getAgroDetails = () => {
    sessionStorage.setItem("customerType", 'Agro');
    sessionStorage.setItem("customerId", parseInt(0));
    sessionStorage.setItem("siteName", "");
    let { history } = props;
    history.push({
        pathname: '/overview'
    });
  }
  const getSWPDetails = () => {
    sessionStorage.setItem("customerType", 'SWP');
    sessionStorage.setItem("siteName", "");
    sessionStorage.setItem("customerId", parseInt(0));
    let { history } = props;
    history.push({
        pathname: '/overview'
    });
  }
  return <se-container>
    <div className='container-fluid'>
      <div className='row' style={{ padding: '25px 15px' }}>
        <div className='col-6' style={{ borderRight: '2px solid #CBCBCB'}}>
          <div className='row'>
            <div className='col-md-12 col-lg-12 text-center'><img class="agri_landing_img" src={agri} style={{ borderRadius: '10%', overflow: 'hidden' }} /></div>
            <div className='col-md-12 landingTitle'>
              <span className='link_txt' onClick={getAgroDetails}>Villaya Agri</span>
              </div>
          </div>
          <div className='row'>
            <p className='d-flex justify-content-center parafont' style={{ padding: '15px' }}>the agriculture pump will continue to run at
              rated capacity taking power from solar panels and balance power from grid, if
              required, and in case solar power generation is higher than required by pump, the
              additional solar power would be fed to the grid. Farmer would be able to import and
              export power to the grid at the rate specified by the concerned State
              Government/SERC.
            </p>
          </div>
          <div className='row' style={{float:'right'}}>
            <se-button option="flat" color="primary" onClick={getAgroDetails}>Visit</se-button>
          </div>
        </div>
        <div className='col-6'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 text-center'><img class="swp_landing_img" src={solarwaterpumping} style={{ borderRadius: '10%', overflow: 'hidden' }} /></div>
            <div className='col-md-12 landingTitle'>
              <span className='link_txt' onClick={getSWPDetails}>Villaya Solar Water Pumping</span>
              </div>
          </div>
          <div className='row'>
            <p className='d-flex justify-content-center parafont' style={{ padding: '15px' }}>the agriculture pump will continue to run at
              rated capacity taking power from solar panels and balance power from grid, if
              required, and in case solar power generation is higher than required by pump, the
              additional solar power would be fed to the grid. Farmer would be able to import and
              export power to the grid at the rate specified by the concerned State
              Government/SERC.
            </p>
          </div>
          <div className='row' style={{float:'right'}}>
            <se-button option="flat" color="primary" onClick={getSWPDetails}>Visit</se-button>
          </div>
        </div>
      </div>
    </div>

  </se-container>
}
export default LandingPage
