import React, { useState, useEffect } from "react";
import {
  NAVHEADERS,
  NAV2HEADERS,
  NAV3HEADERS,
  CONFIGURATIONS,
} from "./constants";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "../styles.css";
import DeviceSettings from "./deviceSettings";
import StartStop from "./startStop";
import Reset from "./reset";
import Transaction from "./transaction";
import Auth from "../../api/ApiSession";
import FlowControl from "./flowControl";
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js'

const authCall = new Auth();

const NavigationBar = ({ handleClick, menuKey }) => {
  let user = "";
  const encrypted = sessionStorage.getItem("user");    
  if(encrypted!=null){
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    user = JSON.parse(decrypted);
  }
  const userObj = useSelector((state)=> state.user)

  return (
    <nav style={{ height: "41px" }}>
      <p className="" style={{width: '12px'}}>
      </p>
      <div className="sub-nav bg-white">
        {userObj.user_type === "Admin" || userObj.user_type === "Site Manager"
          ? user.gatewayType === "ATV320-V2"
            ? NAVHEADERS.map(({ name, id }) => (
                <a
                  className={menuKey === id ? "active" : ""}
                  title={name}
                  key={id}
                  onClick={() => handleClick(id)}
                >
                  {name}
                </a>
              ))
            : NAV3HEADERS.map(({ name, id }) => (
                <a
                  className={menuKey === id ? "active" : ""}
                  title={name}
                  key={id}
                  onClick={() => handleClick(id)}
                >
                  {name}
                </a>
              ))
          : NAV2HEADERS.map(({ name, id }) => (
              <a
                className={menuKey === id ? "active" : ""}
                title={name}
                key={id}
                onClick={() => handleClick(id)}
              >
                {" "}
                {name}
              </a>
            ))}
      </div>
    </nav>
  );
};


const NavigationBarMobile = ({ handleClick, menuKey }) => {
  let user = "";
  const encrypted = sessionStorage.getItem("user");    
  if(encrypted!=null){
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    user = JSON.parse(decrypted);
  }
  const userObj = useSelector((state)=> state.user)
  const [selectedMenu, setSelectedMenu] = useState(menuKey);
  const handleMenuChange = (e) => {
    setSelectedMenu(parseInt(e.target.value));
    handleClick(parseInt(e.target.value));
  };
  return (
    <nav style={{ height: "41px" }}>
      <p className="col-md-4 mt-0 mb-0 fs-4 text-left lastRefresh hideinMobile">
        {CONFIGURATIONS.Last_Refresh} :{" "}
        {moment().format("DD/MM/YYYY h:mm:ss A")}
      </p>
      <div >
        <select className='custom-dropdown' value={selectedMenu} onChange={handleMenuChange}>
          {userObj.user_type === "Admin" || userObj.user_type === "Site Manager"
            ? user.gatewayType === "ATV320-V2"
              ? NAVHEADERS.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))
              : NAV3HEADERS.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))
            : NAV2HEADERS.map(({ name, id }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
        </select>
      </div>
    </nav>
  );
};

const Configuration = () => {
  const [menuKey, setMenuKey] = useState(1);
  const [deviceData, setDeviceData] = useState({
    motorControlType: "",
    MobileNo: "",
    Frequency: "",
    Status: "",
    RemoteStartStop: "",
    DeviceId: "",
  });

  const [loading, setLoading] = useState(false);
  const [configSubMenuVisible, setConfigSubMenuVisible] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    authCall
      .getCustomerPersonalInfo(sessionStorage.getItem("customerId"))
      .then((res) => {
        if (
          res.detailsInfo[1] &&
          Object.keys(res.detailsInfo[1]).length !== 0
        ) {
          setDeviceData({
            ...deviceData,
            DeviceId: res.detailsInfo[1].infoData[0].PropValue,
          });
          fetchDeviceData(res.detailsInfo[1].infoData[0].PropValue);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const fetchDeviceData = (DeviceId) => {
    setLoading(false);

    authCall
      .getConfigurationData(CONFIGURATIONS.GetData, { DeviceId: DeviceId, customerId: sessionStorage.getItem("customerId") })
      .then((response) => {
        setDeviceData({
          ...response[0],
          DeviceId,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleClick = (id) => {
    if (document.getElementsByClassName("active").length > 2) {
      setConfigSubMenuVisible(false);
    }
    setMenuKey(id);
  };

  const toggleConfigNav = () => {
    setConfigSubMenuVisible(!configSubMenuVisible);
  };

  const menuItem = () => {
    switch (menuKey) {
      case 2:
        return <DeviceSettings DeviceId={deviceData.DeviceId} />;

      case 3:
        return <FlowControl DeviceId={deviceData.DeviceId} />;

      case 4:
        return <Reset deviceData={deviceData} />;

      case 5:
        return <Transaction />;

      default:
        return (
          <StartStop deviceData={deviceData} setDeviceData={setDeviceData} />
        );
    }
  };
  return (
    <div className="h-70 mx-4">
      <se-tabbar color="alternative">
        <NavigationBar handleClick={handleClick} menuKey={menuKey} />
      </se-tabbar>
      <se-divider class="mt-1"></se-divider>

      {loading ? (
        <se-loading loading={loading}></se-loading>
      ) : (
        <>
          <span
            style={{ display: "block" }}
            class="hideinDesktop showinMobile"
            color="alternative"
          >
            <NavigationBarMobile handleClick={handleClick} menuKey={menuKey} />
          </span>
          <span>
          <se-block class="padding-mobile-set" direction="row">
            {menuItem()}
          </se-block>
          </span>
        </>
      )}
    </div>
  );
};
export default Configuration;
