import React, { useRef, useState } from "react";
import _ from "lodash";
import CheckBox from "./checkBox";
import {
  CONFIGURATIONS,
  DIALOGCONTENT,
  RESETLABELS,
  RESETPARAMS,
} from "./constants";
import Dialog from "./dialog";
import PropTypes from "prop-types";
import Auth from "../../api/ApiSession";
import { useSelector } from 'react-redux'
import CryptoJS from 'crypto-js';

const authCall = new Auth();

const Reset = ({ deviceData }) => {
  let user = "";
  const encrypted = sessionStorage.getItem("user");    
  if(encrypted!=null){
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    user = JSON.parse(decrypted);
  }

  const userObj = useSelector((state)=> state.user)
  const [isSubmitValid, setIsSubmitValid] = useState(false);
  const [resetMotor, setResetMotor] = useState([]);
  const [dialogContent, setDialogContent] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarObj, setSnackBarObj] = React.useState({});

  const handleReset = (e) => {
    const name = e.target.name;
    const selected = e.target.selected;
    if (selected) {
      resetMotor.push(name);
    } else {
      _.pull(resetMotor, name);
    }
    setResetMotor(resetMotor);
    validateSubmit(resetMotor);
  };

  const validateSubmit = (resetMotor) => {
    if(resetMotor.length === 0) {
      setIsSubmitValid(false);
    } else {
      setIsSubmitValid(true);
    }
  };

  const handleClose = () => {
    setOpen(!open);
    document.getElementById('dialog-complex').open = false;
  };
  const handleConfirm = () => {
    setOpen(false);
    let data = []; 
    resetMotor &&
      resetMotor.forEach((motor) => {
        if(motor==="DriveSoftRstrt")
        {
          data.push({cust_id:sessionStorage.getItem("customerId"), deviceId: deviceData.DeviceId,isProcessed:0,commandName: "DriveSoftRstrt",commandType: "DriveSoftRstrt",commandStatus:"Wait",commandValue: "True"});          
        }else{
          data.push({cust_id:sessionStorage.getItem("customerId"), deviceId: deviceData.DeviceId,isProcessed:0,commandName: "Reset",commandType: "Reset",commandStatus:"Wait",commandValue: motor});        
        }
      });

      authCall
      .postTransaction(data)
      .then((response) => {
        document.getElementById('dialog-complex').open = false;
        alert();
      })
      .catch((error) => {
        console.log(error);
      });
      resetMotor.length = 0;
      setResetMotor(resetMotor);
  };

  const alert = () => {
    document.getElementById('reset-snackbar').open = true;
    setSnackBarObj({ type: "success", msg: "Command Initiated successfully." });
  };

  return (
    <div
      className="d-flex justify-content-between flex-column h-100 mt-4"
      style={{ gap: "15rem", overflow: 'hidden' }}
    >
      <div>
        <CheckBox
          label={CONFIGURATIONS.Reset_Energy}
          handleReset={handleReset}
          name={RESETPARAMS["ENERGY"]}
          selected={resetMotor.includes(RESETPARAMS["ENERGY"])}
        />
        <CheckBox
          label={CONFIGURATIONS.Reset_Run_Time}
          handleReset={handleReset}
          name={RESETPARAMS["RUNTIME"]}
          selected={resetMotor.includes(RESETPARAMS["RUNTIME"])}
        />
        { user.gatewayType === "ATV320-V2"?
        <CheckBox
          label={CONFIGURATIONS.VC}
          handleReset={handleReset}
          name={RESETPARAMS["VC"]}
          selected={resetMotor.includes(RESETPARAMS["VC"])}
        /> : ''
        }
        <CheckBox
          label={CONFIGURATIONS.DriveSoftRstrt}
          handleReset={handleReset}
          name={RESETPARAMS["DriveSoftRstrt"]}
          selected={resetMotor.includes(RESETPARAMS["DriveSoftRstrt"])}
        />
        <div className="d-flex justify-content-end">
        <se-button
          disabled={isSubmitValid ? false : true}
          onClick={() => {
            let dialogContent = "Do you want to ";
            if (resetMotor.length > 2) {
              dialogContent += "Reset All ?";
            } else {
              dialogContent += `${DIALOGCONTENT[resetMotor[0]]}  ${
                DIALOGCONTENT[resetMotor[1]]
                  ? `& ${DIALOGCONTENT[resetMotor[1]]}`
                  : ""
              } ? `;
            }


            setDialogContent(dialogContent);
            setOpen(!open);
            document.getElementById('dialog-complex').open = true;
          }}
        >
          {CONFIGURATIONS.Submit}
        </se-button>
      </div>
      </div>
      
      <se-dialog id="dialog-complex" color="alternative" can-backdrop="false" size="small">
        <se-dialog-header>{CONFIGURATIONS.Dialog_Confirm}</se-dialog-header>
        <se-dialog-content>
          <div class="font-size-14 pb-4">
            {dialogContent}
          </div>
        </se-dialog-content>
        <se-dialog-footer align="right">
          <se-button onClick={() => handleClose()}>
            Cancel
          </se-button>
          <se-button onClick={() => handleConfirm()}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>

      <div class="paddingLeft300" align="left">
        <se-snackbar id="reset-snackbar" duration="5000" type={snackBarObj.type}
          message={snackBarObj.msg}>            
        </se-snackbar>
      </div>
    </div>
    
  );
};
export default Reset;

Reset.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  handleReset: PropTypes.func,
  selected: PropTypes.bool,
};
