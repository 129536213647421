import React, { useState, useEffect } from 'react';
import Auth from '../api/ApiSession';
import TableComponent from '../components/tableComponent';

const Usertickets = () => {
    const authCall = new Auth();
    const [userticketsData, setTicketsData] = useState([]);
    const [isCreateTicketActive, setCreateTicketActive] = useState(true);
    const [isMyTicketActive, setMyTicketActive] = useState(false);
    const [selectedSite, setSelectedSite] = useState('');
    const [selectedGateway, setSelectedGateway] = useState('');
    const [customerMessage, setCustomerMessage] = useState('');
    const [siteList, setSiteList] = useState([]);
    const [gatewayList, setGatewayList] = useState([]); 
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMsg, setSucessMsg] = useState('');
    const [successType, setSuccessType] = useState('');
    const isMobile = window.innerWidth <= 600;
    const fetchAgroSiteList = () => {
        authCall.getAgroCustomersList(sessionStorage.getItem("customerType"))
            .then(entries => {
                setSiteList(entries);
            })
            .catch(error => {
                console.log(error);
            });
    }
    
    const fetchAgroGatewayList = () => {
        authCall.getDiagnosticsList(sessionStorage.getItem("customerType"))
            .then(entries => {
                setGatewayList(entries);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const onCurrentStatus = () => {
        setCreateTicketActive(true);
        setMyTicketActive(false);
    }

    const onHistoryStatus = () => {
        setCreateTicketActive(false);
        setMyTicketActive(true);
        fetchMyTicketsData();
    }

    const handleSiteChange = (event) => {
      setSelectedSite(event.target.value);
    }
  
    const handleGatewayChange = (event) => {
      setSelectedGateway(event.target.value);
    }
  
    const handleCustomerMessageChange = (event) => {
      setCustomerMessage(event.target.value);
    }
  
    const validateForm = () => {
        if (selectedSite !== '' && selectedGateway !== '' && customerMessage !== '') {
          setIsFormValid(true);
        } else {
          setIsFormValid(false);
        }
      }
  
    const handleSubmit = (event) => {
        event.preventDefault();         
        const ISTOffset = 330;
        const date = new Date(Date.now() + ISTOffset * 60000);
        const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
        const dataToPost = {
            GatewayId : selectedGateway,
            SiteName : selectedSite,
            CustomerMessage: customerMessage,
            CustomerId: parseInt(sessionStorage.getItem("customerId")),
            TicketStatus: "Open",
            InsertedDate: formattedDate
            //InsertedDate:currentDateTime.toLocaleString('en-US', {timeZone: 'Asia/Kolkata'})
            //InsertedDate : `${currentDateTime.getDate().toString().padStart(2, '0')}/${(currentDateTime.getMonth() + 1).toString().padStart(2, '0')}/${currentDateTime.getFullYear()} ${currentDateTime.getHours().toString().padStart(2, '0')}:${currentDateTime.getMinutes().toString().padStart(2, '0')}`
        };
        postTicketsDataList(dataToPost);
    };

    const postTicketsDataList = (dataToPost) => {
        setIsLoading(true);
        authCall.postTicketsList(dataToPost)
            .then(json => {
                setIsLoading(false);
                setSelectedSite('');
                setSelectedGateway('');
                setCustomerMessage('');
                document.getElementById('snackbar_success').open = true;
                setSucessMsg(json);
                setSuccessType("success");
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });
    };

    const fetchMyTicketsData = () => {       
        authCall.getTicketsList(sessionStorage.getItem("customerType"),sessionStorage.getItem("customerId")).then(json => {
            if (json) {
                setTicketsData(json.TicketDataResponse.TicketsList)
            }
        })
    }

    useEffect(() => {
        fetchAgroSiteList();
        fetchAgroGatewayList();
    }, []);

    useEffect(() => {
        validateForm();
    }, [selectedSite,selectedGateway,customerMessage]);
    return (
        <div>
            {isMobile ? 
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintopmobile" align="center">
                <button className={isCreateTicketActive ? "ActiveButton pointer" : "InactiveButton pointer"} onClick={onCurrentStatus}>Create Ticket</button>
                <button className={isMyTicketActive ? "histortActiveButton pointer" : "historyInactiveButton pointer"} onClick={onHistoryStatus}>My Tickets</button>                
            </div>
            :
            <se-block color="standard" align="center" height="70px">
                <div className="rows grey-backgroundCreate margintopticket margintoptablet" style={{ textAlign: "center"}}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" align="center">
                        <div>
                            <button className={isCreateTicketActive ? "ActiveButton pointer" : "InactiveButton pointer"} onClick={onCurrentStatus}>Create Ticket</button>
                            <button className={isMyTicketActive ? "histortActiveButton pointer" : "historyInactiveButton pointer"} onClick={onHistoryStatus}>My Tickets</button>
                        </div>
                    </div>
                </div>
            </se-block>}
            <form onSubmit={handleSubmit}>
            {isCreateTicketActive && (
                <div id="ticketForm" className="margintopticket margintopmobile" style={{ textAlign: "center"}}>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <se-form-field id="select-form" label="Site Name :" required="true" padding="none" type="select" option="inline">
                            <select id="site-dropdown" onChange={handleSiteChange} value={selectedSite}>
                                <option value="">Select a Site</option>
                                {siteList.map(site => <option key={site.customerId} value={site.location}>{site.location}</option>)}
                                </select>
                        </se-form-field>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 margintopticket">
                        <se-form-field id="select-form" label="Gateway ID :" required="true" padding="none" type="select" option="inline">
                            <select id="gateway-dropdown" onChange={handleGatewayChange} value={selectedGateway}>
                                <option value="">Select a Gateway ID</option>
                                {gatewayList.map(gateway => <option key={gateway.customerId} value={gateway.customerId}>{gateway.GatewayId}</option>)}
                                </select>
                        </se-form-field>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 margintopticket">
                        <se-form-field label="Customer Message :" required="true">
                            <textarea id="customer-message" 
                            onChange={handleCustomerMessageChange} 
                            value={customerMessage} 
                            placeholder="Enter text here" 
                            maxLength={500} ></textarea>
                        </se-form-field>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 submit margintopmobile">
                        <button type="submit" className={isFormValid ? "ActiveButton pointer" : "InactiveButton pointer"} disabled={!isFormValid}>Submit</button>                  
                    </div>
                </div>
            )}
            </form>
            {isMyTicketActive && (
                <div id="myticketForm" className="ticket Scroll">
                    <se-block display="block" className="px-0">
                        <TableComponent sortable={true} tableHeader={['Ticket ID', 'Site Name', 'Gateway ID', 'Customer Message', ' Status ', 'Raised Date', 'Closed Date']} tableData={userticketsData} tableName='UserTicketsTable' />
                    </se-block>
                </div>
            )}
            <div class="row justify-content-md-center">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <se-snackbar id="snackbar_success" duration="5000" type={successType} icon="" message={successMsg} ></se-snackbar>
                </div>
            </div>
        </div>
    );
};

export default Usertickets;