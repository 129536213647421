import React from "react";
import { withRouter } from 'react-router-dom';
import { Bar } from 'react-chartjs-2'
import _ from 'lodash';
const DashboardRevenue = (props) => {
    const [utilityDetails, setUtilityDetails] = React.useState({})
    React.useEffect(() => {
        if (props.revenue) {
            setUtilityDetails(props.revenue)
        }
    }, [props.revenue])
    const navigatetoDetailsPage = () => {
        let { history } = props;
        history.push({
            pathname: '/incomeGenerationDetails',
            widgetType: "Income Generation"
        });
    }
    const renderGraph = () => {
        var dataValue = {

            labels: [],
            datasets: [
                {
                    label: null,
                    backgroundColor: [],
                    borderColor: [],
                    hoverBackgroundColor: [],
                    hoverBorderColor: [],
                    data: []
                }]
        }

        let options = {
            tooltips: {
                intersect: false,
                mode: 'label',
                callbacks: {
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + " ";
                    }
                }
            },
            scales: {
                xAxes: [{
                    barPercentage: 0.4,
                    maxBarThickness: 12,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontSize: 8,
                        display: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        drawBorder: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: null,
                        padding: 0,
                        fontSize: 10,
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 10,
                        maxTicksLimit: 7,
                        padding: 3,
                    }
                }],
            },
            legend: {
                display: false,
            }
        }
        _.map(utilityDetails.widgetDetails, widgetDetail => {
            options.scales.yAxes[0].scaleLabel.labelString = ""
            _.map(widgetDetail.graphData, graphData => {
                dataValue.labels.push(graphData.propLable)
                dataValue.datasets[0].label = ""
                dataValue.datasets[0].backgroundColor.push(graphData.propColor)
                dataValue.datasets[0].borderColor.push(graphData.propColor)
                dataValue.datasets[0].hoverBackgroundColor.push(graphData.propColor)
                dataValue.datasets[0].hoverBorderColor.push(graphData.propColor)
                dataValue.datasets[0].data.push(graphData.propValue[0])
            })
        })
        return <Bar data={dataValue} options={options} width="175" height="105" />
    }
    const renderValue = _.map(utilityDetails.widgetData, (widgetData, index) => {
        return <div key={index} class="col-xs-5 col-sm-5 col-md-5 col-lg-5 pt-0" style={{marginTop:'-10px'}}>
            <div class="row align-items-center">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-3">
                    <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{widgetData.propIcon}</se-icon>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 LineheightOver" style={{ paddingLeft: "calc(1rem + 0.8vw)" }}>
                    <span class="text-align font-size-12 textColor">{widgetData.propName}</span>
                    <span class="text-align font-size-12 textColor"> ({widgetData.units})</span>
                </div>
            </div>
            <div class="row align-items-center pt-4 pl-0">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span class="font-size-lg pt-2">{widgetData.propVal}</span>
                </div>
            </div>
        </div>
    })
    return <se-block margin="none" class="mt-2 font-icon overflow-hidden tabHeight">
        <div class="border-bottom-overview bordergreenColor">
            <div class="rows align-items-center" style={{display:'table'}}>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style={{display:'table-cell'}}><span class="font-size-16">{utilityDetails.widgetType}</span></div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 textColor font-size-12 my-auto" style={{display:'table-cell'}}>Lifetime</div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 my-auto" style={{display:'table-cell'}}>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <se-icon class="pointer valign-super" option="" size="nano" color="" onClick={() => navigatetoDetailsPage()}>presentation_mode</se-icon>
                        </div>

                    </div>
                </div>
            </div>
        </div> 
        <se-block-content class="border-bottom-overview overflow-hidden">
            <div class="row align-items-center incomeblockheight">
                {renderValue}
                <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 pl-0 border-left" style={{paddingTop:'7px'}}>
                    {renderGraph()}
                </div>
            </div>
        </se-block-content>
    </se-block>
}
export default withRouter(DashboardRevenue);