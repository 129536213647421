import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import Auth from '../api/ApiSession';
import './styles.css';
import _ from 'lodash';
import ChoosePeriod from '../components/choosePeriod';
import { useSelector } from 'react-redux'
import useImpactMetricsGraphDetails from './useImpactMetricsGraphDetails';
const authCall = new Auth();
const EnvironmentDetails = (props) => {
    const userObj = useSelector((state)=> state.user)
    const [utilityDetails, setUtilityDetails] = React.useState({})
    const [utilityDetailsCopy, setUtilityDetailsCopy] = React.useState({})
    const [selectedCheckBox, setSelectedCheckBox] = React.useState([])
    const [snackBarObj, setSnackBarObj] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [graphType, setGraphType] = React.useState({
        barGraphClass: "iconBtnLeft active",
        lineGraphClass: "iconBtnRight",
        type: 'Bar'
    })
    const [dataToPost, setDataToPost] = React.useState({
        type: "Environment Contribution",
        granularity: null,
        startDate: null,
        endDate: null,
        siteName: sessionStorage.getItem("siteName"),
        customerId: parseInt(sessionStorage.getItem("customerId")),
        customerType: sessionStorage.getItem("customerType"),
        graphType:graphType.type
    })
    const intervalRef = React.useRef()
    let idToPrint = []
    let dataValue = {}
    let options = {}
    const onPeriodChange = (startDate, endDate, type) => {
        let dataTopost = dataToPost
        dataTopost.startDate = startDate
        dataTopost.endDate = endDate
        dataTopost.granularity = type
        setDataToPost(dataTopost)
        fetchUtilityDetails('normalCall')
    }
    // console.log(utilityDetails, graphType, idToPrint, dataValue, options,utilityDetails.widgetType)
    const { renderGraphDetails } = useImpactMetricsGraphDetails(utilityDetails, graphType, idToPrint, dataValue, options,utilityDetails.widgetType)
    const fetchUtilityDetails = (call) => {
        call === "normalCall" ? setIsLoading(true) : setIsLoading(false);
        authCall.postAgroDashboardDetails(dataToPost).then(json => {
            if (json) {
                setIsLoading(false);
                let jsonCopy = JSON.parse(JSON.stringify(json));
                removeDataFromGraph(jsonCopy)
                setUtilityDetailsCopy(json)
            }
        })
    }
    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            fetchUtilityDetails('initialCall')
        }, 900000);
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [])
    const removeDataFromGraph = (jsonCopy) => {
        _.map(selectedCheckBox, ele => {
            _.map(jsonCopy.widgetDetails, widgetDetail => {
                let i = 0;
                while (i < widgetDetail.graphData.length) {
                    if (widgetDetail.graphData[i].propName === ele) {
                        widgetDetail.graphData.splice(i, 1)
                        i = 0;
                    } else {
                        i++;
                    }
                }
            })
            _.map(jsonCopy.widgetData,widgetData=>{
                if(widgetData.propName===ele){
                    widgetData.propVal=""
                    widgetData.units=""
                }
            })
        })
        setUtilityDetails(jsonCopy)
    }
    const changeToBarGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft active",
            lineGraphClass: "iconBtnRight",
            type: "Bar"
        })
        dataToPost.graphType="bar"
    }
    const changeToLineGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft",
            lineGraphClass: "iconBtnRight active",
            type: "Line"
        })
        dataToPost.graphType="Line"
    }
    const navigateToOverview = () => {
        let { history } = props;
        history.push({
            pathname: '/overview'
        });

    }
    const onCheckBoxClicked = (selected, selectedData) => {
        let utilityDetails1 = JSON.parse(JSON.stringify(utilityDetailsCopy));
        if (selected === false) {
            if (selectedCheckBox.indexOf(selectedData.propName) === -1) {
                selectedCheckBox.push(selectedData.propName)
            }
        } else {
            if (selectedCheckBox.indexOf(selectedData.propName) !== -1) {
                selectedCheckBox.splice(selectedCheckBox.indexOf(selectedData.propName), 1)
            }
        }
        removeDataFromGraph(utilityDetails1)
    }
    const generateReport = () => {
        setIsLoading(true)
        // let idData = []
        // _.map(idToPrint, eachId => {
        //     if (eachId.startsWith('Environment ContributionGraph') === true) {
        //         idData.push({ chart: document.getElementById(eachId).toDataURL('image/png') })
        //     } else {
        //         idData.push({ tableData: document.getElementById(eachId).innerHTML })
        //     }
        // })
        // let selectedSite;
        // if (sessionStorage.getItem("siteName") === "") {
        //     selectedSite = "All Sites"
        // } else {
        //     selectedSite = sessionStorage.getItem("siteName")
        // }
        // let jsonToPost = {
        //     "reportAPI": "saveReportData",
        //     "reportParam": ,
        //     "reportSite": selectedSite,
        //     "reportCustomer": "",
        //     "reportUserid": ,
        //     "customerType": sessionStorage.getItem("customerType"),
        //     "reportDataList": [
        //         {
        //             "reportType": dataToPost.granularity,
        //             "reporWidgetName": "Environment Contribution",
        //             "fromDate": dataToPost.startDate,
        //             "toDate": dataToPost.endDate,
        //             "reportTableData": idData
        //         }
        //     ]
        // }
        authCall.postGeneratePdfReport(dataToPost).then(json => {
            if (json) {
                setIsLoading(false)
                let a = document.createElement("a")
                a.href =json.includes("\"https://")? "https://"+json.replace("\"https://",'').replace('"',''):json
                a.target= '_blank'
                a.click()

            }else{
                setIsLoading(false)
                document.getElementById('admin-snackbar').open = true;
                setSnackBarObj({ type: "warning", msg: "Failed to generate PDF" })
            }
        })
    }   

    const isMobile = window.innerWidth <= 600;
    const renderCheckBoxList = _.map(utilityDetails.widgetData, (ele, index) => {
        if(isMobile) {
            return(
                <div key={index} class="row ">
                <div class="col-2 col-md-2 px-0 text-center">
                  <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>
                    {ele.propIcon}
                  </se-icon>
                </div>
                <div class="col-4 col-md-4 px-0 pt-2 text-left">
                  <span class="font-size-12 textColor">{ele.propName}</span>
                </div>
                <div class="col-3 col-md-3 px-0 text-right">
                  <span class="font-size-37">{ele.propVal}</span> <span class="font-size-12 font-weight-600" style={{marginTop:'15px', paddingLeft:'6px'}}>{ele.units}</span>   
                </div>
              </div>
            )
        }
        else{
        return <div key={index} class="row align-items-center detailscheckBlock">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-center">
                <se-icon size="large" color="standard" style={{fontSize:"40px"}}>{ele.propIcon}</se-icon>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-4 col-xl-3 px-0 textLineHight pt-2 text-left">
                <span class="font-size-12 textColor">{ele.propName}</span>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-3 col-xl-5 px-0 text-right">
                <span class="font-size-37" style={{ position: 'relative' }}>{ele.propVal} 
                <div class="font-size-12 font-weight-600" style={{marginTop:"-1vh"}}>{ele.units}</div>
                    {/* <span className={'displayUnits'}>{ele.units}</span> */}
                    </span>
                {/* <span> <img class="paddingBottom30" width="27px"></img></span> */}
            </div>
            <div class="col-xs-2 col-sm-2 col-md-3 col-lg-3 col-xl-2 hideinMobile" style={{padding:"15px"}}>
                <se-checkbox selected="true" onClick={(e) => onCheckBoxClicked(e.target.selected, ele)}></se-checkbox>
            </div>
        </div>
        }
    })
    return <se-container position="absolute" direction="row" >
        {isLoading ? <div class="align-items-center"><se-loading loading="true"></se-loading></div> : null}
        <se-block>
            <se-block-header class="headerColor"><span class="font-size-22">
                {utilityDetails.widgetType}-{sessionStorage.getItem("siteName") !== "" ?
                    sessionStorage.getItem("siteName") : "All Sites"}</span>
                <div slot="end"><se-icon option="" class="pointer" onClick={() => navigateToOverview()}>action_close</se-icon>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </se-block-header>
            <se-block-content class="grey-backgroundCreate" >
                <se-container position="absolute" direction="row" margin="large" padding="large">
                    <div class="col-md-4 col-lg-3 col-xl-3 px-0" option="widget" margin="none" style={{background:"white"}}>
                        <div class="border-bottom divDetailsHeight text-left">
                            <span class="font-normal font-weight-400">Last updated: {utilityDetails.lastUpdated}</span>
                        </div>
                        <div class="border-bottom">
                            <se-block-content>
                                <ChoosePeriod onChange={onPeriodChange} InstaltedDate={utilityDetails.installationDate} />
                            </se-block-content>
                        </div>
                        <se-block-content class="ChooseDivHeight">
                            {renderCheckBoxList}
                        </se-block-content>
                    </div>
                    <se-divider option="vertical"></se-divider>
                    <se-block class="px-0 hideinMobile">
                    <div class="mt-30px margintop60 exportalign">
                        <div class="rows pdf-download">
                            <div className="mt-2 toggleBtn">
                                <span onClick={() => changeToBarGraph()} className={graphType.barGraphClass}>
                                    <se-icon >graph_barchart</se-icon>
                                </span>
                                <span onClick={() => changeToLineGraph()} className={graphType.lineGraphClass}>
                                    <se-icon >energy</se-icon></span>
                            </div>
                            <se-icon option="button" onClick={() => generateReport()} size="large">action_pdf_report</se-icon>
                        </div>
                        {renderGraphDetails()}
                        </div>
                    </se-block>
                </se-container>
            </se-block-content>
        </se-block>
        <div style={{ position: "absolute", left: '15px' }}>
            <se-snackbar id="admin-snackbar" duration="5000" type={snackBarObj.type}
                message={snackBarObj.msg} ></se-snackbar>
        </div>
    </se-container>
}
export default withRouter(EnvironmentDetails);