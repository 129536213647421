import React, { useEffect, useState } from "react";
import { CONFIGURATIONS, FLOWCONTROLPARAMS, PVCSPARMAS } from "./constants";
import Auth from "../../api/ApiSession";
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import './flowControl.scss';

const authCall = new Auth();

const styles = {
    selectStyles: {
        minWidth: "100%",
        borderRadius: "3px",
        maxHeight: "34px",
        lineHeight: "16px",
        fontSize: "12px",
        padding: "6px 32px 6px 8px",
    },
    buttonStyles: {
        minWidth: "35%",
        marginLeft: "2%",
    },
    submitStyles: {
        minWidth: "25%",
        marginRight: "10%",
    },
    redBtnEnable: {
        color: "red"
    },
    redBtnDisable: {
        color: "red",
        visibility: "collapse"
    },
    bold: {
        fontWeight: "bold"
    }
};

const FlowControl = ({ DeviceId }) => {
    const userObj = useSelector((state)=> state.user)
    const [showProgressMsg, setShowProgressMsg] = useState(false);
    const [snackBarObj, setSnackBarObj] = React.useState({});
    const [deviceData, setDeviceData] = useState({ DeviceId: DeviceId, FlowEstimationMethod: 0, PVCS: 0, PumpSpeed: 0, FlowRate1: 0, FlowRate2: 0, FlowRate3: 0, FlowRate4: 0, FlowRate5: 0, Power1: 0, Power2: 0, Power3: 0, Power4: 0, Power5: 0, Multiplier: 0, Divider: 0, DailyPumpedVolume: 0, TotalPumpedVolume: 0, SensorlessFlowEstimation: 0, SlowPulseCounter: 0 });
    const [flowControl, setFlowControl] = useState({ DeviceId: DeviceId, FlowEstimationMethod: 0, PVCS: 0, PumpSpeed: 0, FlowRate1: 0, FlowRate2: 0, FlowRate3: 0, FlowRate4: 0, FlowRate5: 0, Power1: 0, Power2: 0, Power3: 0, Power4: 0, Power5: 0, Multiplier: 0, Divider: 0, DailyPumpedVolume: 0, TotalPumpedVolume: 0, SensorlessFlowEstimation: 0, SlowPulseCounter: 0 });
    const [isDisabled, setIsDisabled] = useState({ FlowEstimationMethod: false, PVCS: false, PumpSpeed: sensorLessFlowToggle, FlowRate1: sensorLessFlowToggle, FlowRate2: sensorLessFlowToggle, FlowRate3: sensorLessFlowToggle, FlowRate4: sensorLessFlowToggle, FlowRate5: sensorLessFlowToggle, Power1: sensorLessFlowToggle, Power2: sensorLessFlowToggle, Power3: sensorLessFlowToggle, Power4: sensorLessFlowToggle, Power5: sensorLessFlowToggle, Multiplier: sensorFlowToggle, Divider: sensorFlowToggle, DailyPumpedVolume: true, TotalPumpedVolume: true, SensorlessFlowEstimation: true, SlowPulseCounter: true });
    const [isValid, setIsValid] = useState({ FlowEstimationMethod: true, PVCS: true, PumpSpeed: true, FlowRate1: true, FlowRate2: true, FlowRate3: true, FlowRate4: true, FlowRate5: true, Power1: true, Power2: true, Power3: true, Power4: true, Power5: true, Multiplier: true, Divider: true, DailyPumpedVolume: true, TotalPumpedVolume: true, SensorlessFlowEstimation: true, SlowPulseCounter: true });    
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const sensorLessFlowToggle = false;
    const sensorFlowToggle = false;

    const handleFlowEstimationChange = (e) => {
        handleDropDownChange(e);

        const pvcs =  e.target.value === "0" ? 2 : 1;
        if(pvcs === 2){
            toggleSensorlessField(true);
            toggleSensorflowField(false);
        }else{
            toggleSensorlessField(false);
            toggleSensorflowField(true);
        }
        setFlowControl(prevState => ({ ...prevState, 'PVCS': parseFloat(pvcs) }));
    };

    const handlePVCSChange = (e) => {
        handleDropDownChange(e);

        var toggle = flowControl.FlowEstimationMethod === 2 && parseInt(e.target.value) === 1 ? false : true;
        toggleSensorlessField(toggle);

        var sensorflowToggle = parseInt(flowControl.FlowEstimationMethod) === 0 && parseInt(e.target.value) === 2 ? false : true;
        toggleSensorflowField(sensorflowToggle);
    };

    // Update specific input field
    const handleChange = (e) => {
        e.persist();
        ValidateForm();
        var pattern = /^(?:\d+(?:\.\d{0,1})?)?$/;
        //PumpSpeed no 0 
        if (e.target.name === 'FlowRate1' || e.target.name === 'FlowRate2' || e.target.name === 'FlowRate3' || e.target.name === 'FlowRate4' || e.target.name === 'FlowRate5') {
            pattern = /^(?:\d+(?:\.\d{0,1})?)?$/;
        } else if (e.target.name === 'Power1' || e.target.name === 'Power2' || e.target.name === 'Power3' || e.target.name === 'Power4' || e.target.name === 'Power5') {
            pattern = /^(?:\d+(?:\.\d{0,2})?)?$/;
        } else {
            pattern = /^\d+$/
        }
        let regExp = new RegExp(pattern);
        let result = regExp.test(parseFloat(e.target.value));

        if (result || isNaN(parseFloat(e.target.value))) {
            setFlowControl(prevState => ({ ...prevState, [e.target.name]: parseFloat(e.target.value) }));
        }
    }

    const handleDropDownChange = (e) => {
        e.persist();
        setFlowControl(prevState => ({ ...prevState, [e.target.name]: parseFloat(e.target.value) }));
    }

    function toggleSensorlessField(toggle) {
        setIsDisabled(prevState => ({
            ...prevState, PumpSpeed: toggle, FlowRate1: toggle, FlowRate2: toggle, FlowRate3: toggle,
            FlowRate4: toggle, FlowRate5: toggle, Power1: toggle, Power2: toggle, Power3: toggle, Power4: toggle,
            Power5: toggle
        }));
    }

    function toggleSensorflowField(toggle) {
        setIsDisabled(prevState => ({
            ...prevState, Multiplier: toggle, Divider: toggle
        }));
    }

    const getLatestPacket = () => {
        //set state to show in progress loader
        setShowProgressMsg(true);
        var transactionID = 0;
        //Push command to gateway 
        authCall
            .putUpdateConfigurationsReset(CONFIGURATIONS.Reset, {
                DeviceId: DeviceId,
                TopicName: "Get",
                ResetCommand: "Get",
                ResetType: "True",
                customerId: sessionStorage.getItem("customerId")
            })
            .then((response) => {
                transactionID = response;
            })
            .catch((error) => {
                console.log(error);
            });

        let job = setInterval(function () {
            authCall
                .getLatestPacket(CONFIGURATIONS.GetLatestPacket, { id: transactionID, DeviceId: DeviceId, customerId: sessionStorage.getItem("customerId") })
                .then((response) => {
                    if (response[0].response === undefined) {
                        setFlowControl(prevState => ({ ...prevState, FlowEstimationMethod: parseFloat(response[0].FlowEstimation), PVCS: parseFloat(response[0].PVCS), PumpSpeed: parseFloat(response[0].PumpSpeed), FlowRate1: (response[0].FlowRate1 * 0.1).toFixed(1), FlowRate2: (response[0].FlowRate2 * 0.1).toFixed(1), FlowRate3: (response[0].FlowRate3 * 0.1).toFixed(1), FlowRate4: (response[0].FlowRate4 * 0.1).toFixed(1), FlowRate5: (response[0].FlowRate5 * 0.1).toFixed(1), Power1: (response[0].Power1 * 0.01).toFixed(2), Power2: (response[0].Power2 * 0.01).toFixed(2), Power3: (response[0].Power3 * 0.01).toFixed(2), Power4: (response[0].Power4 * 0.01).toFixed(2), Power5: (response[0].Power5 * 0.01).toFixed(2), Multiplier: response[0].Multiplier, Divider: response[0].Divider, DailyPumpedVolume: (response[0].DailyPumpedVolume * 0.1).toFixed(1), TotalPumpedVolume: (response[0].TotalPumpedVolume * 0.1).toFixed(1), SensorlessFlowEstimation: (response[0].SensorlessFlowEstimation * 0.1).toFixed(1), SlowPulseCounter: response[0].SlowPulseCounter }));
                        clearInterval(job);
                        clearInterval(job1);
                        //clear state to hide in progress loader
                        setShowProgressMsg(false);
                        alert('Get Data was Successfull!', "success");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 2000);

        let job1 = setTimeout(function () {
            alert('Device Not Responded. Request Failed!', "error");
            setShowProgressMsg(false);
            clearInterval(job);
        }, 30000);

    }

    const ValidateForm = () => {
        const flow = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true];

        //if motor is ON disable fields
        if (deviceData.RemoteStartStop === 1) {
            setIsDisabled(prevState => ({
                ...prevState, FlowEstimationMethod: true, PVCS: true, PumpSpeed: true, FlowRate1: true, FlowRate2: true, FlowRate3: true,
                FlowRate4: true, FlowRate5: true, Power1: true, Power2: true, Power3: true, Power4: true,
                Power5: true
            }));
        }
        //check field values are within range
        if (!(flowControl.FlowEstimationMethod === 0 || flowControl.FlowEstimationMethod === 2)) {
            flow[0] = false;
        }
        if (!(flowControl.PVCS >= 0 && flowControl.PVCS <= 2)) {
            flow[1] = false;
        }
        if (!(flowControl.PumpSpeed >= 0 && flowControl.PumpSpeed <= 65535)) {
            flow[2] = false;
        }
        if (!(flowControl.FlowRate1 >= 0 && flowControl.FlowRate1 <= 3276.7)) {
            flow[3] = false;
        }
        if (!(flowControl.FlowRate2 >= 0 && flowControl.FlowRate2 <= 3276.7)) {
            flow[4] = false;
        }
        if (!(flowControl.FlowRate3 >= 0 && flowControl.FlowRate3 <= 3276.7)) {
            flow[5] = false;
        }
        if (!(flowControl.FlowRate4 >= 0 && flowControl.FlowRate4 <= 3276.7)) {
            flow[6] = false;
        }
        if (!(flowControl.FlowRate5 >= 0 && flowControl.FlowRate5 <= 3276.7)) {
            flow[7] = false;
        }
        if (!(flowControl.Power1 >= 0 && flowControl.Power1 <= 327.67)) {
            flow[8] = false;
        }
        if (!(flowControl.Power2 >= 0 && flowControl.Power2 <= 327.67)) {
            flow[9] = false;
        }
        if (!(flowControl.Power3 >= 0 && flowControl.Power3 <= 327.67)) {
            flow[10] = false;
        }
        if (!(flowControl.Power4 >= 0 && flowControl.Power4 <= 327.67)) {
            flow[11] = false;
        }
        if (!(flowControl.Power5 >= 0 && flowControl.Power5 <= 327.67)) {
            flow[12] = false;
        }
        if (!(flowControl.Multiplier > 0 && flowControl.Multiplier <= 65535)) {
            flow[13] = false;
        }
        if (!(flowControl.Divider > 0 && flowControl.Divider <= 65535)) {
            flow[14] = false;
        }
        setIsValid({ FlowEstimationMethod: flow[0], PVCS: flow[1], PumpSpeed: flow[2], FlowRate1: flow[3], FlowRate2: flow[4], FlowRate3: flow[5], FlowRate4: flow[6], FlowRate5: flow[7], Power1: flow[8], Power2: flow[9], Power3: flow[10], Power4: flow[11], Power5: flow[12], Multiplier: flow[13], Divider: flow[14] });

        getTransaction();
    }

    const FormUpdated = () => {
        let formChanged = true;
        if (flowControl.FlowEstimationMethod !== deviceData.FlowEstimation || flowControl.PVCS !== deviceData.PVCS ||
            flowControl.PumpSpeed !== deviceData.PumpSpeed || flowControl.FlowRate1 !== (deviceData.FlowRate1 * 0.1).toFixed(1) ||
            flowControl.FlowRate2 !== (deviceData.FlowRate2 * 0.1).toFixed(1) || flowControl.FlowRate3 !== (deviceData.FlowRate3 * 0.1).toFixed(1) ||
            flowControl.FlowRate4 !== (deviceData.FlowRate4 * 0.1).toFixed(1) || flowControl.FlowRate5 !== (deviceData.FlowRate5 * 0.1).toFixed(1) ||
            flowControl.Power1 !== (deviceData.Power1 * 0.01).toFixed(2) || flowControl.Power2 !== (deviceData.Power2 * 0.01).toFixed(2) ||
            flowControl.Power3 !== (deviceData.Power3 * 0.01).toFixed(2) || flowControl.Power4 !== (deviceData.Power4 * 0.01).toFixed(2) ||
            flowControl.Power5 !== (deviceData.Power5 * 0.01).toFixed(2) || flowControl.Divider !== deviceData.Divider ||
            flowControl.Multiplier !== deviceData.Multiplier) {
            formChanged = false;
        }

        if (!(flowControl.FlowEstimationMethod === 0 || flowControl.FlowEstimationMethod === 2) ||
            !(flowControl.PVCS >= 0 && flowControl.PVCS <= 2) || !(flowControl.PumpSpeed >= 0 && flowControl.PumpSpeed <= 65535) ||
            !(flowControl.FlowRate1 >= 0 && flowControl.FlowRate1 <= 3276.7) || !(flowControl.FlowRate2 >= 0 && flowControl.FlowRate2 <= 3276.7) ||
            !(flowControl.FlowRate3 >= 0 && flowControl.FlowRate3 <= 3276.7) || !(flowControl.FlowRate4 >= 0 && flowControl.FlowRate4 <= 3276.7) ||
            !(flowControl.FlowRate5 >= 0 && flowControl.FlowRate5 <= 3276.7) || !(flowControl.Power1 >= 0 && flowControl.Power1 <= 327.67) ||
            !(flowControl.Power2 >= 0 && flowControl.Power2 <= 327.67) || !(flowControl.Power3 >= 0 && flowControl.Power3 <= 327.67) ||
            !(flowControl.Power4 >= 0 && flowControl.Power4 <= 327.67) || !(flowControl.Power5 >= 0 && flowControl.Power5 <= 327.67) ||
            !(flowControl.Multiplier >= 0 && flowControl.Multiplier <= 65535) || !(flowControl.Divider >= 0 && flowControl.Divider <= 65535)) {
            formChanged = true;
        }

        return formChanged;
    }

    const getTransaction = () => {
        authCall.getTransactionData(CONFIGURATIONS.GetTransaction, sessionStorage.getItem("customerId"), 'Wait,InProgress').then(json => {
            if (json.length > 0) {
                var flowEstimationArray = json.filter(function (cmd) { return cmd.commandName === 'FlowEstimation'; });
                
                if (flowEstimationArray.length > 0) {
                    setIsFormSubmitted(true);
                }else{
                    setIsFormSubmitted(false);
                }

            }
        })
    };

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        var jsonData = { FEM: flowControl.FlowEstimationMethod, PVCS: flowControl.PVCS, PCSP: flowControl.PumpSpeed, PCQ1: (flowControl.FlowRate1 * 10).toFixed(1), PCQ2: (flowControl.FlowRate2 * 10).toFixed(1), PCQ3: (flowControl.FlowRate3 * 10).toFixed(1), PCQ4: (flowControl.FlowRate4 * 10).toFixed(1), PCQ5: (flowControl.FlowRate5 * 10).toFixed(1), PCP1: (flowControl.Power1 * 100).toFixed(2), PCP2: (flowControl.Power2 * 100).toFixed(2), PCP3: (flowControl.Power3 * 100).toFixed(2), PCP4: (flowControl.Power4 * 100).toFixed(2), PCP5: (flowControl.Power5 * 100).toFixed(2), SPML: flowControl.Multiplier, SPDV: flowControl.Divider };
        let data = ([{ cust_id: sessionStorage.getItem("customerId"), deviceId: deviceData.DeviceId, isProcessed: 0, commandName: "FlowEstimation", commandType: "FlowEstimation", commandStatus: "Wait", commandValue: JSON.stringify(jsonData) }]);

        authCall
            .postTransaction(data)
            .then((response) => {
                alert("Command Initiated successfully.", "success");
                getTransaction();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchDeviceData(DeviceId);
    }, []);

    useEffect(() => {
        ValidateForm();
    }, [flowControl]);

    const fetchDeviceData = (DeviceId) => {
        authCall
            .getConfigurationData(CONFIGURATIONS.GetData, { DeviceId: DeviceId, customerId: sessionStorage.getItem("customerId") })
            .then((response) => {
                if (response.length > 0) {
                    setDeviceData({
                        ...response[0],
                        DeviceId,
                    });

                    setFlowControl({
                        DeviceId: response[0].DeviceId, FlowEstimationMethod: response[0].FlowEstimation, PVCS: response[0].PVCS,
                        PumpSpeed: response[0].PumpSpeed, FlowRate1: (response[0].FlowRate1 * 0.1).toFixed(1), FlowRate2: (response[0].FlowRate2 * 0.1).toFixed(1),
                        FlowRate3: (response[0].FlowRate3 * 0.1).toFixed(1), FlowRate4: (response[0].FlowRate4 * 0.1).toFixed(1), FlowRate5: (response[0].FlowRate5 * 0.1).toFixed(1),
                        Power1: (response[0].Power1 * 0.01).toFixed(2), Power2: (response[0].Power2 * 0.01).toFixed(2), Power3: (response[0].Power3 * 0.01).toFixed(2),
                        Power4: (response[0].Power4 * 0.01).toFixed(2), Power5: (response[0].Power5 * 0.01).toFixed(2), Multiplier: response[0].Multiplier, Divider: response[0].Divider,
                        DailyPumpedVolume: (response[0].DailyPumpedVolume * 0.1).toFixed(1), TotalPumpedVolume: (response[0].TotalPumpedVolume * 0.1).toFixed(1), SensorlessFlowEstimation: (response[0].SensorlessFlowEstimation * 0.1).toFixed(1), SlowPulseCounter: response[0].SlowPulseCounter
                    })

                    var dropdownToggle = true;
                    var sensorLessFlowToggle_ = true;
                    var sensorFlowToggle_ = true;
                    if (response[0].RemoteStartStop === 0) {
                        dropdownToggle = false;
                        sensorLessFlowToggle_ = response[0].FlowEstimation === 2 && response[0].PVCS === 1 ? false : true;
                        sensorFlowToggle_ = response[0].PVCS === 2 ? false : true;
                    }

                    setIsDisabled(prevState => ({
                        ...prevState, FlowEstimationMethod: dropdownToggle, PVCS: dropdownToggle, PumpSpeed: sensorLessFlowToggle_, FlowRate1: sensorLessFlowToggle_, FlowRate2: sensorLessFlowToggle_, FlowRate3: sensorLessFlowToggle_,
                        FlowRate4: sensorLessFlowToggle_, FlowRate5: sensorLessFlowToggle_, Power1: sensorLessFlowToggle_, Power2: sensorLessFlowToggle_, Power3: sensorLessFlowToggle_, Power4: sensorLessFlowToggle_,
                        Power5: sensorLessFlowToggle_, Multiplier: sensorFlowToggle_, Divider: sensorFlowToggle_
                    }));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const alert = (msgTxt, type) => {
        document.getElementById('FlowControl-snackbar').open = true;
        setSnackBarObj({ type: type, msg: msgTxt });
    };

    let options = {
        tooltips: {
          intersect: false,
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']] + " ";
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Pump Characteristic Curve',
              padding: 3,
              fontSize: 12,
            },
            ticks: {
              fontSize: 10,
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Power (kW)',
              padding: 3,
              fontSize: 10,
            },
            ticks: {
              beginAtZero: true,
              fontSize: 10, maxTicksLimit: 10,
              padding: 3,
              labelString: 'widgetDetail.YUnits',
            }
          }],
        },
        legend: {
          display: false,
        }
      };

    const GraphData = {
        labels: [flowControl.FlowRate1, flowControl.FlowRate2, flowControl.FlowRate3, flowControl.FlowRate4, flowControl.FlowRate5],
        datasets: [
            {
                label: "First dataset",
                data: [flowControl.Power1,flowControl.Power2,flowControl.Power3,flowControl.Power4,flowControl.Power5],
                fill: true,
                backgroundColor: "rgba(65,105,225,0.2)",
                borderColor: "rgba(65,105,225,1)"
            }
        ]
    };

    return (
        <>
            <div>
                <se-container className="d-flex flex-column flex-wrap justify-content-start" class="containerStyles">
                    {showProgressMsg ? <div id="wait" className='wait'><h5> Your request is in progress. Please Wait<div class="loading">...</div></h5></div> : null}
                    <div className="row" style={{width:"100%"}}>
                        <div className="col-md-9 " style={{ marginBottom: '40px' }}>
                            <div className="createBlockSize text-right">
                                <div className="col-md-5 col-lg-5 col-md-6 col-sm-12  text-left"><div style={styles.bold}>Flow Control Details</div></div>
                                <div className="col-md-6  text-left">
                                    <se-button size="small" option="flat" color="primary" style={styles.submitStyles} onClick={() => getLatestPacket()}>{CONFIGURATIONS.Get}</se-button>
                                    <se-button size="small" option="flat" color="primary" style={styles.submitStyles} onClick={() => handleSubmit()} disabled={FormUpdated() || isFormSubmitted}>{CONFIGURATIONS.Set}</se-button>
                                </div>
                            </div> <hr />

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.Flow_Estimation_Method} disabled={isDisabled.FlowEstimationMethod || isFormSubmitted}>
                                        <select  class="padding-flowControl" style={styles.selectStyles} value={flowControl.FlowEstimationMethod} onChange={handleFlowEstimationChange} name="FlowEstimationMethod">
                                            <option value={FLOWCONTROLPARAMS.NO}>{CONFIGURATIONS.NO}</option>
                                            <option value={FLOWCONTROLPARAMS.PQ}>{CONFIGURATIONS.PQ}</option>
                                        </select>
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.FlowEstimationMethod === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0.2 to 99')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.PVCS} disabled={true}>
                                        <select style={styles.selectStyles} value={flowControl.PVCS} name="PVCS">                                            
                                            <option value={PVCSPARMAS.SLFV}>{CONFIGURATIONS.SLFV}</option>
                                            <option value={PVCSPARMAS.SPUC}>{CONFIGURATIONS.SPUC}</option>
                                        </select>
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.PVCS === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{ (isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0.2 to 99')}</p>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                            <div style={styles.bold}><span>Sensorless Flow Estimation</span></div><hr />
                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.PumpSpeed} disabled={isFormSubmitted || isDisabled.PumpSpeed} >
                                        <input type="number" value={flowControl.PumpSpeed} onChange={handleChange} name="PumpSpeed" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 65535" min="0" max="65535" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.PumpSpeed === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 65535')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.FlowRate1} disabled={isFormSubmitted || isDisabled.FlowRate1} >
                                        <input type="number" value={flowControl.FlowRate1} onChange={handleChange} name="FlowRate1" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 3276.7" min="0" max="3276.7" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.FlowRate1 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 3276.7')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.FlowRate2} disabled={isFormSubmitted || isDisabled.FlowRate2} >
                                        <input type="number" value={flowControl.FlowRate2} onChange={handleChange} name="FlowRate2" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 3276.7" min="0" max="3276.7" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.FlowRate2 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 3276.7')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.FlowRate3} disabled={isFormSubmitted || isDisabled.FlowRate3} >
                                        <input type="number" value={flowControl.FlowRate3} onChange={handleChange} name="FlowRate3" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 3276.7" min="0" max="3276.7" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.FlowRate3 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 3276.7')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.FlowRate4} disabled={isFormSubmitted || isDisabled.FlowRate4} >
                                        <input type="number" value={flowControl.FlowRate4} onChange={handleChange} name="FlowRate4" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 3276.7" min="0" max="3276.7" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.FlowRate4 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 3276.7')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.FlowRate5} disabled={isFormSubmitted || isDisabled.FlowRate5} >
                                        <input type="number" value={flowControl.FlowRate5} onChange={handleChange} name="FlowRate5" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 3276.7" min="0" max="3276.7" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.FlowRate5 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 3276.7')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.Power1} disabled={isFormSubmitted || isDisabled.Power1} >
                                        <input type="number" value={flowControl.Power1} onChange={handleChange} name="Power1" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 327.67" min="0" max="327.67" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.Power1 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 327.67')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.Power2} disabled={isFormSubmitted || isDisabled.Power2} >
                                        <input type="number" value={flowControl.Power2} onChange={handleChange} name="Power2" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 327.67" min="0" max="327.67" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.Power2 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 327.67')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.Power3} disabled={isFormSubmitted || isDisabled.Power3} >
                                        <input type="number" value={flowControl.Power3} onChange={handleChange} name="Power3" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 327.67" min="0" max="327.67" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.Power3 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 327.67')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.Power4} disabled={isFormSubmitted || isDisabled.Power4} >
                                        <input type="number" value={flowControl.Power4} onChange={handleChange} name="Power4" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 327.67" min="0" max="327.67" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.Power4 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 327.67')}</p>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.Power5} disabled={isFormSubmitted || isDisabled.Power5} >
                                        <input type="number" value={flowControl.Power5} onChange={handleChange} name="Power5" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="0 to 327.67" min="0" max="327.67" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.Power5 === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 0 to 327.67')}</p>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                            <div style={styles.bold}>Sensor Flow Estimation</div><hr />
                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.SPML} disabled={isFormSubmitted || isDisabled.Multiplier} >
                                        <input type="number" value={flowControl.Multiplier} onChange={handleChange} name="Multiplier" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="1 to 65535" min="1" max="65535" title="" />
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow">
                                    <p style={isValid.Multiplier === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 1 to 65535')}</p>                                    
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.SPDV} disabled={isFormSubmitted || isDisabled.Divider} >
                                        <input type="number" value={flowControl.Divider} onChange={handleChange} name="Divider" style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="1 to 65535" min="1" max="65535" title="" />
                                        <p className="" style={styles.bold}>{isValid.Multiplier === true && isValid.Divider === true && !isFormSubmitted ? (flowControl.Multiplier / (flowControl.Divider * 10)).toFixed(2) + ' lts/pulse' : ''}</p>
                                    </se-form-field>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow margintop25">
                                    <p style={isValid.Divider === false || isFormSubmitted === true ? styles.redBtnEnable : styles.redBtnDisable}>{(isFormSubmitted === true ? 'Transaction InProgress' : 'Range is between 1 to 65535')}</p>                                    
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.PVLD} disabled={isDisabled.DailyPumpedVolume} >
                                        <input type="number" value={flowControl.DailyPumpedVolume} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="" min="0" max="100" title="" />
                                    </se-form-field>
                                </div>
                            </div>
                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.PVLT} disabled={isDisabled.TotalPumpedVolume} >
                                        <input type="number" value={flowControl.TotalPumpedVolume} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="" min="0" max="100" title="" />
                                    </se-form-field>
                                </div>
                            </div>
                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.SLFV_Text} disabled={isDisabled.SensorlessFlowEstimation} >
                                        <input type="number" value={flowControl.SensorlessFlowEstimation * 0.1} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="" min="0" max="100" title="" />
                                    </se-form-field>
                                </div>
                            </div>
                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12 text-left">
                                    <se-form-field label={CONFIGURATIONS.SPUC_Text} disabled={isDisabled.SlowPulseCounter} >
                                        <input type="number" value={flowControl.SlowPulseCounter} style={styles.selectStyles} onPaste={(e) => { e.preventDefault(); return false; }} placeholder="" min="0" max="100" title="" />
                                    </se-form-field>
                                </div>
                            </div>

                            <div className="createBlockSize text-right trFlowStyles">
                                <div className="col-md-12 col-lg-12 col-md-6 col-sm-12">                                    
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 text-left validationMsg-flow"></div>
                            </div>
                        </div>

                        <div className="col-md-3  hideinMobile">
                            <div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12">&nbsp;</div><div className="col-md-12">&nbsp;</div><div className="col-md-12">&nbsp;</div><div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12">&nbsp;</div>
                            <div className="col-md-12 createBlockSize text-right trFlowStyles">
                                <Line data={GraphData} options={options} height={200} />
                            </div>
                        </div>
                    </div>
                </se-container>
                <div className="paddingLeft300" align="left">
                    <se-snackbar id="FlowControl-snackbar" duration="5000" type={snackBarObj.type}
                        message={snackBarObj.msg} ></se-snackbar>
                </div>
            </div>
        </>
    );

};
export default FlowControl;