import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import Auth from '../api/ApiSession';
import './styles.css';
import _ from 'lodash';
import domtoimage from 'dom-to-image';
import ChoosePeriod from '../components/choosePeriod';
import useImpactMetricsGraphDetails from './useImpactMetricsGraphDetails';
import { useSelector } from 'react-redux'
const authCall = new Auth();
const InstallationDetails = (props) => {
    const userObj = useSelector((state)=> state.user)
    const [utilityDetails, setUtilityDetails] = React.useState({})
    const [utilityDetailsCopy, setUtilityDetailsCopy] = React.useState({})
    const [selectedCheckBox, setSelectedCheckBox] = React.useState([])
    const [snackBarObj, setSnackBarObj] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [graphType, setGraphType] = React.useState({
        barGraphClass: "iconBtnLeft active",
        lineGraphClass: "iconBtnRight",
        type: 'Bar'
    })
    const [dataToPost, setDataToPost] = React.useState({
        type: "Installation",
        granularity: null,
        startDate: null,
        endDate: null,
        siteName: sessionStorage.getItem("siteName"),
        customerId: parseInt(sessionStorage.getItem("customerId")),
        customerType: sessionStorage.getItem("customerType"),
        graphType:graphType.type
    })
    const intervalRef = React.useRef()
    let idToPrint = []
    let dataValue = {}
    let options = {}
    const fetchUtilityDetails = (call) => {
        call === "normalCall" ? setIsLoading(true) : setIsLoading(false);
        authCall.postAgroDashboardDetails(dataToPost).then(json => {
            if (json) {
                // json = {
                //     "widgetType": "Installation", "lastUpdated": "1 minutes ago-22:17 20-01-2021", "performance": "Above Target",
                //     "installationDate": "2020-07-13", "type": null,
                //     "widgetData": [{ "propName": "PV Capacity", "propVal": "10.00", "units": "kW", "propColor": null, "propIcon": "solar_panel2" },
                //     { "propName": "Total No. of Assets", "propVal": "6.00", "units": null, "propColor": null, "propIcon": "motor" },
                //     { "propName": "Pump status", "propVal": "ON", "units": null, "propColor": null, "propIcon": "electricity_current_alternating" }],
                //     "summaryData": null, "periods": ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
                //     "tableData": [{
                //         "tableName": "Motor Info", "tableValue": [
                //             { "propName": "xyx", "propValue": "50Hz" },
                //             { "propName": "sss", "propValue": "415V" },
                //             { "propName": "dd", "propValue": "50Hz" },
                //             { "propName": "ee", "propValue": "12A" },
                //             { "propName": "ww", "propValue": "1000rpm" },
                //             { "propName": "qq", "propValue": "0.5" },
                //         ]
                //     }],
                //     "motorTableData": [{
                //         "tableName": "Motor Info", "tableValue": [
                //             { "propName": "bFr", "propValue": "50Hz" },
                //             { "propName": "Un5", "propValue": "415V" },
                //             { "propName": "Fr5", "propValue": "50Hz" },
                //             { "propName": "nCr", "propValue": "12A" },
                //             { "propName": "nSP", "propValue": "1000rpm" },
                //             { "propName": "COS", "propValue": "0.5" },
                //         ]
                //     }],
                //     "widgetDetails": [{ "XUnits": "Period (Hours)", "YUnits": "kW", "graphData": [{ "propName": "PV Capacity", "propValue": ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "4", "0", "5", "0", "1", "0", "0", "0", "0", "0", "0", "0", "0", "0"], "propLable": "PV Capacity (kW)", "propColor": "#219BFD", "propTitle": "PV Capacity", "propSubTitle": null }] }, { "XUnits": "Period (Hours)", "YUnits": "Total No. of Assets", "graphData": [{ "propName": "Total No. of Assets", "propValue": ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "4", "0", "1", "0", "1", "0", "0", "0", "0", "0", "0", "0", "0", "0"], "propLable": "Total No. of Assets", "propColor": "#E47F00", "propTitle": "Total No. of Assets", "propSubTitle": null }] }]
                // }
                setIsLoading(false);
                let jsonCopy = JSON.parse(JSON.stringify(json));
                removeDataFromGraph(jsonCopy)
                setUtilityDetailsCopy(json)
            }
        })
    }
    const removeDataFromGraph = (jsonCopy) => {
        _.map(selectedCheckBox, ele => {
            _.map(jsonCopy.widgetDetails, widgetDetail => {
                let i = 0;
                while (i < widgetDetail.graphData.length) {
                    if (widgetDetail.graphData[i].propName === ele) {
                        widgetDetail.graphData.splice(i, 1)
                        i = 0;
                    } else {
                        i++;
                    }
                }
            })
            _.map(jsonCopy.widgetData, widgetData => {
                if (widgetData.propName === ele) {
                    widgetData.propVal = ""
                    widgetData.units = ""
                }
            })
        })
        setUtilityDetails(jsonCopy)
    }
    const onPeriodChange = (startDate, endDate, type) => {
        let dataTopost = dataToPost
        dataTopost.startDate = startDate
        dataTopost.endDate = endDate
        dataTopost.granularity = type
        setDataToPost(dataTopost)
        fetchUtilityDetails('normalCall')
    }
    const onCheckBoxClicked = (selected, selectedData) => {
        let utilityDetails1 = JSON.parse(JSON.stringify(utilityDetailsCopy));
        if (selected === false) {
            if (selectedCheckBox.indexOf(selectedData.propName) === -1) {
                selectedCheckBox.push(selectedData.propName)
            }
        } else {
            if (selectedCheckBox.indexOf(selectedData.propName) !== -1) {
                selectedCheckBox.splice(selectedCheckBox.indexOf(selectedData.propName), 1)
            }
        }
        removeDataFromGraph(utilityDetails1)
    }
    const navigateToOverview = () => {
        let { history } = props;
        history.push({
            pathname: '/overview'
        });

    }
    const changeToBarGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft active",
            lineGraphClass: "iconBtnRight",
            type: "Bar"
        })
        dataToPost.graphType="bar"
    }
    const changeToLineGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft",
            lineGraphClass: "iconBtnRight active",
            type: "Line"
        })
        dataToPost.graphType="Line"
    }
    const postgenerateReport = (dataURL,dataURL2) => {
        // let idData = []
        // let tableImage = new Image();
        // _.map(idToPrint, eachId => {
        //     if (eachId.startsWith('InstallationGraph') === true) {
        //         idData.push({ chart: document.getElementById(eachId).toDataURL('image/png') })
        //     }
        //     else if (eachId.startsWith('InstallationTable') === true) {
        //         idData.push({ tableData: document.getElementById(eachId).innerHTML })
        //     }
        // })
        // if (dataURL) {
        //     dataURL === "data:," ? tableImage = "" : tableImage = dataURL
        //     idData.push({ chart: tableImage })
        // }
        // if (dataURL2) {
        //     dataURL2 === "data:," ? tableImage = "" : tableImage = dataURL2
        //     idData.push({ chart: tableImage })
        // }
        // let selectedSite;
        // if (sessionStorage.getItem("siteName") === "") {
        //     selectedSite = "All Sites"
        // } else {
        //     selectedSite = sessionStorage.getItem("siteName")
        // }
        // let jsonToPost = {
        //     "reportAPI": "saveReportData",
        //     "reportParam": ,
        //     "reportSite": selectedSite,
        //     "reportCustomer": sessionStorage.getItem("customerId"),
        //     "reportUserid": ,
        //     "customerType": sessionStorage.getItem("customerType"),
        //     "reportDataList": [
        //         {
        //             "reportType": dataToPost.granularity,
        //             "reporWidgetName": "Installation",
        //             "fromDate": dataToPost.startDate,
        //             "toDate": dataToPost.endDate,
        //             "reportTableData": idData
        //         }
        //     ]
        // }
        authCall.postGeneratePdfReport(dataToPost).then(json => {
            if (json) {
                setIsLoading(false)
                let a = document.createElement("a")
                a.href =json.includes("\"https://")? "https://"+json.replace("\"https://",'').replace('"',''):json
                a.target= '_blank'
                a.click()

            }else{
                setIsLoading(false)
                document.getElementById('admin-snackbar').open = true;
                setSnackBarObj({ type: "warning", msg: "Failed to generate PDF" })
            }
        })
    }
    const generateReport = () => {
        setIsLoading(true)
        let serachString = "InstallationInfoTablePng1234"
        let motorTableString = "InstallationMotorInfoTablePng1234"
        let domtoImageID = _.filter(idToPrint, function (o) { return o.toLowerCase().indexOf(serachString.toLowerCase()) !== -1 })
        let domtoImageID2 = _.filter(idToPrint, function (o) { return o.toLowerCase().indexOf(motorTableString.toLowerCase()) !== -1 })
      
        if (document.getElementById(domtoImageID[0]) && document.getElementById(domtoImageID2[0])) {
            domtoimage.toPng(document.getElementById(domtoImageID[0])).then(function (dataURL) {
                domtoimage.toPng(document.getElementById(domtoImageID2[0])).then(function (dataURL2) {
                    postgenerateReport(dataURL,dataURL2)
                })
            })
        }else if (document.getElementById(domtoImageID[0]) && document.getElementById(domtoImageID2[0])===null) {
            domtoimage.toPng(document.getElementById(domtoImageID[0])).then(function (dataURL) {              
                    postgenerateReport(dataURL,null)
            })
        }else if (document.getElementById(domtoImageID[0])===null && document.getElementById(domtoImageID2[0])) {
            domtoimage.toPng(document.getElementById(domtoImageID2[0])).then(function (dataURL2) {              
                    postgenerateReport(null,dataURL2)
            })
        } else {
            postgenerateReport(null,null)
        }
    }
    const { renderInfoTable, renderMotorInfoTable, renderGraphDetails1, renderGraphDetails2 } = useImpactMetricsGraphDetails(utilityDetails, graphType, idToPrint, dataValue, options, utilityDetails.widgetType)
    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            fetchUtilityDetails('initialCall')
        }, 900000);
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [])
    const isMobile = window.innerWidth <= 600;
    const renderCheckBoxList = _.map(utilityDetails.widgetData, (ele, index) => {
        if(isMobile) {
            return(
                <div key={index} class="row ">
                <div class="col-2 col-md-2 px-0 text-center">
                  <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>
                    {ele.propIcon}
                  </se-icon>
                </div>
                <div class="col-4 col-md-4 px-0 pt-2 text-left">
                  <span class="font-size-12 textColor">{ele.propName}</span>
                </div>
                <div class="col-3 col-md-3 px-0 text-right">
                  <span class="font-size-37">{ele.propVal}</span> <span class="font-size-12 font-weight-600" style={{marginTop:'15px', paddingLeft:'6px'}}>{ele.units}</span>   
                </div>
              </div>
            )
        }
        else {
        return <div key={index} class="row align-items-center detailscheckBlock">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-center">
                <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{ele.propIcon}</se-icon>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-4 col-xl-3 px-0 textLineHight pt-2 text-left">
                <span class="font-size-12 textColor">{ele.propName}</span>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-3 col-xl-5 px-0 text-right">
                <span class="font-size-37" style={{ position: 'relative' }}>{ele.propVal} 
                <div class="font-size-12 font-weight-600" style={{marginTop:"-1vh"}}>{ele.units}</div>
                    {/* <span className={'displayUnits'}>{ele.units}</span> */}
                    </span>
                {/* <span> <img class="paddingBottom30" width="27px"></img></span> */}
            </div>
            {ele.propName === "Pump status" ? null : <div class="col-xs-2 col-sm-2 col-md-3 col-lg-3 col-xl-2" style={{padding:"15px"}}>
                <se-checkbox selected="true" onClick={(e) => onCheckBoxClicked(e.target.selected, ele)}></se-checkbox>
            </div>
            }
        </div>
        }
    })

    return <se-container position="absolute" direction="row" >
        {isLoading ? <div class="align-items-center"><se-loading loading="true"></se-loading></div> : null}
        <se-block>
            <se-block-header class="headerColor"><span class="font-size-22"> {utilityDetails.widgetType}-{sessionStorage.getItem("siteName") !== "" ?
                sessionStorage.getItem("siteName") : "All Sites"}</span>
                <div slot="end"><se-icon option="" class="pointer" onClick={() => navigateToOverview()}>action_close</se-icon>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </se-block-header>
            <se-block-content class="grey-backgroundCreate" >
                <se-container position="absolute" direction="row" margin="large" padding="large">
                    <div class="col-md-4 col-lg-3 col-xl-3 px-0" style={{background:"white"}}>
                        <div class="border-bottom divDetailsHeight text-left">
                            <span class="font-normal font-weight-400">Last updated: {utilityDetails.lastUpdated}</span>
                        </div>
                        <div class="border-bottom">
                            <se-block-content>
                                <ChoosePeriod onChange={onPeriodChange} InstaltedDate={utilityDetails.installationDate} />
                            </se-block-content>
                        </div>
                        <se-block-content class="ChooseDivHeight">
                            {renderCheckBoxList}
                             </se-block-content>
                             <span class='hideinDesktop showinMobile installation-table-margin' style={{backgroundColor:'white', display:'block', overflowX:'auto'}}>{renderInfoTable()}</span>
                      
                    </div>
                    <se-divider option="vertical"></se-divider>
                    <se-block class="px-0 hideinMobile">
                        <div class="mt-30px exportalign">
                            <div class="rows pdf-download">
                                <div className="mt-2 toggleBtn">
                                    <span onClick={() => changeToBarGraph()} className={graphType.barGraphClass}>
                                        <se-icon >graph_barchart</se-icon>
                                    </span>
                                    <span onClick={() => changeToLineGraph()} className={graphType.lineGraphClass}>
                                        <se-icon >energy</se-icon></span>
                                </div>
                                <se-icon option="button"
                                    size="large" onClick={() => generateReport()}>action_pdf_report</se-icon>
                            </div>
                            {renderGraphDetails1()}
                            {renderGraphDetails2()}
                        </div>
                        <se-block-content style={{ overflow: "hidden"}}>
                            { sessionStorage.getItem("siteName") !== "" ? renderInfoTable() : "" }
                        </se-block-content>
                        <se-block-content style={{ overflow: "hidden" , display:'none' }}>
                            {renderMotorInfoTable()}
                        </se-block-content>

                    </se-block>
                </se-container>
            </se-block-content>
        </se-block>
        <div style={{ position: "absolute", left: '15px' }}>
            <se-snackbar id="admin-snackbar" duration="5000" type={snackBarObj.type}
                message={snackBarObj.msg} ></se-snackbar>
        </div>
    </se-container>
}
export default withRouter(InstallationDetails);