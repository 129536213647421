import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './UserSlice';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const ENABLE_REDUX_DEV_TOOLS = false;

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_SECRET_KEY,
  onError: function (error) {
    // Handle the error.
  },
})

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: null,
  transforms: [encryptor] 
};
  
const rootReducer = combineReducers({user: userReducer})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store =  configureStore({
  reducer: persistedReducer,  
  devTools: ENABLE_REDUX_DEV_TOOLS,
})

export const persistor = persistStore(store)