import React, { useEffect, useRef, useState } from "react";
import { CONFIGURATIONS } from "./constants";
import Dialog from "./dialog";
import PropTypes from "prop-types";
import TableComponent from '../../components/tableComponent';
import Auth from "../../api/ApiSession";
import { useSelector } from 'react-redux';

const Transaction = () => {
    const userObj = useSelector((state)=> state.user)
    const [tableData, setTableData] = React.useState([])
    const [tableDataCopy, setTableDataCopy] = React.useState([])
    const [tableHeader, setTableHeader] = React.useState([])
    const authCall = new Auth();
    let counter = 0;

    const fetchData = () => {       
        authCall.getTransactionData(CONFIGURATIONS.GetTransaction,sessionStorage.getItem("customerId"),'All').then(json => {
            if (json) {
                setTableData(json)
                setTableDataCopy(JSON.parse(JSON.stringify(json)))
            }
        });
    }

    React.useEffect(() => {
        setTableHeader(["Command", "CommandValue", "Role", "Created", "Status"]);
        fetchData()
        // let intervalId = setInterval(() => {
        //     if (counter <= 2) {
        //         fetchData()
        //         counter++
        //     }
        // }, 30000)
        // // return unmount handler
        // return () => {
        //     clearInterval(intervalId)
        // }
    }, [])

    const search = (e) => {
        let searchValue = e.target.value;
        searchValue = searchValue.toLowerCase();
        let searchResult = tableDataCopy.filter(ele => {
            return (ele.commandName).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.commandValue).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.CreatedDate).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.commandStatus).toLowerCase().indexOf(searchValue) !== -1
        })
        setTableData(searchResult);
    }

    return (
        <se-container display="block" padding="large" class="transaction">
            <div className="col-md-12 px-0">
                <div className="row justify-content-center">
                <div className="col-md-12 col-lg-12 col-xl-12 px-0">

                <se-block display="block" color="standard">
                    <div className="col-md-12 pb-3 px-0 my-auto text-right">
                        <input className="mr-2" type="search1" placeholder="Search" onChange={(e) => search(e)}/>
                    </div>
                </se-block>

                <se-block display="block" class="px-0">
                    <TableComponent sortable={true} tableHeader={tableHeader} tableData={tableData} tableName='TransactionTable' />
                </se-block>

                </div>
                </div>
            </div>
        </se-container>
    );
    
};
export default Transaction;